import React, { Component } from "react";
import ReactPlayer from 'react-player'
import "./css/video.css";



class Video extends Component {
    render() {
        return (
            <div className="video-wrapper" style={{ maxHeight: this.props.maxHeight }}>
                <ReactPlayer
                    url={this.props.url}
                    controls
                    width="100%"
                    height="100%" />
            </div>
        );
    }
}
export default Video;
