import React, { Component } from 'react';
import Text from "../../main/Text";
import BackButton from '../../main/BackButton';


class ProductPrice extends Component {
    render() {
        return (
            <>
                <BackButton />
                <Text>
                    <p>
                        This is product price page
                    </p>
                </Text>

            </>
        );
    }
}


export default ProductPrice;

