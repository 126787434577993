import React, { Component } from 'react';
import Text from "../../main/Text";
import BackButton from '../../main/BackButton';



class Tracking extends Component {
  render() {
    return (
      <>

        <BackButton />

        <Text>Tracking </Text>

      </>
    );
  }
}
export default Tracking;

