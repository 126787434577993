import React, { useEffect, useRef, useState } from "react";
import Line from "../../main/Line";
import BackButton from "../../main/BackButton";
import axios from "../../../axios-backend";

const AnswerDetails = (props) => {
  const [answer, setAnswer] = useState({});
  const paramsString = props.location.search.substring(1);
  const searchParams = new URLSearchParams(paramsString);
  const idRef = useRef(searchParams.get("id"));

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get("/answer-details", {
        params: { id: idRef.current },
      })
      .then((data) => {
        if (data.data.status === 200 && data.data.answer) {
          setAnswer(data.data.answer);
        }
      });
  };
  return (
    <>
      <BackButton />
      <Line type="bottom-line">Type Name:&nbsp; {answer.name} gender:&nbsp; {answer.gender == 2 ? "unisex" : (answer.gender == 1 ? "male" : "female")} </Line>
      <Line type="bottom-line">
        Created Time: &nbsp;{" "}
        {new Date(answer.createdTime).toLocaleString("it-IT")}
      </Line>
      {answer.result
        ? Object.values(answer.result).map((e, index) => {
          return (
            <Line type="bottom-line" key={index}>
              {e.shortName}: {e.val}
            </Line>
          );
        })
        : null}
    </>
  );
};

export default AnswerDetails;
