import React from "react";
import Line from "../../main/Line";
import Text from "../../main/Text";
import { GrClose } from "react-icons/gr";

export default function TransferFinish(props) {
  return (
    <>
      <Line type="bottom-line" style={{ justifyContent: "flex-end" }}>
        <GrClose
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.history.push("/home");
          }}
        />
      </Line>
      <Text>
        you have successfully transferred the ownership of this product. you are
        no longer the owner. to complete the transaction, give the new owner the
        physical product and the physical authenticity card.
      </Text>
    </>
  );
}
