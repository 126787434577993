import React, { useState } from 'react';
import axios from '../../axios-backend';
import BackButton from '../main/BackButton';
import Button from '../main/Button';
import Countries from '../main/Countries';
import Line from '../main/Line';


export default function Shippings(props) {
    const [country, setCountry] = useState('-');
    const [price, setPrice] = useState()
    const [priceAll, setPriceAll] = useState();
    const [shippingId, setShippingId] = useState();

    const typeId = props.match.params.tid;

    const handleChangeAll = () => {
        if (!priceAll) return;
        axios.post("/add-shipping-all", { price: priceAll, typeId }).then((data) => {
            if (data.data.status === 200) {
                alert('added shipping price for all countries')
            } else {
                alert('something wrong');
            }
        });
    }
    const handleSubmit = () => {
        if (shippingId && price && country != '-') {
            axios.patch("/shippings", { price, typeId, id: shippingId, countryId: country.split('-')[0] }).then((data) => {
                if (data.data.status === 200) {
                    alert('changed!');
                } else {
                    alert('something wrong');
                }
            });
        }
    }
    const handleChangeCountry = (countryId) => {
        if (countryId) {
            axios.get("/shipping-price-one",
                { params: { typeId: typeId, countryId: countryId } })
                .then((shippingPrice) => {
                    setShippingId(shippingPrice.data.id)
                    // setPrice(parseFloat(shippingPrice.data.price).toFixed(2));
                })
        }
    }
    return (
        <div style={{ height: "100%" }}>
            <BackButton />
            <Line type="bottom-line">
                <h4 className="labelFor">This is the panel for shipping price</h4>
            </Line>
            <Line type="bottom-line">
                Adding for all countries together(do this step first then change for individual country)
            </Line>
            <Line type="bottom-line">
                Note: Do this step for the first time only or when you want to override the existing shipping cost.
            </Line>
            <Line type="bottom-line">
                <input
                    type="text"
                    value={priceAll}
                    onChange={(e) => {
                        setPriceAll(e.target.value);
                    }}
                    style={{ width: "200px" }}
                />
                <Button className="labelFor" onClick={handleChangeAll}>
                    submit
                </Button>
            </Line>
            <Line type="bottom-line">

            </Line>
            <Line type="bottom-line">

            </Line>
            <Line type="bottom-line">
                Changing shipping price for individual country
            </Line>
            <Line type="bottom-line" >
                <label htmlFor="countryId" className="labelFor">
                    country :
                </label>
                <select
                    value={country}
                    onChange={(e) => {
                        const cid = e.target.value.split('-')[0];
                        setCountry(e.target.value);
                        handleChangeCountry(cid);
                    }}
                >
                    <Countries />
                </select>
            </Line>
            <Line type="bottom-line">
                <label htmlFor="price" className="labelFor">
                    shipping price:
                </label>
                <input
                    type="text"
                    value={price}
                    onChange={(e) => {
                        setPrice(e.target.value);
                    }}
                    style={{ width: "200px" }}
                />
            </Line>
            <Line type="bottom-line">
                <Button className="labelFor" onClick={handleSubmit}>
                    submit
                </Button>
            </Line>
        </div>
    )
}
