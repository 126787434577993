import React from 'react';
import videos from '../../videos/testvideo.mp4';
import Video from "../main/Video";
import Text from "../main/Text";

export default function ProductCare(props) {
    console.log(props.match.params.id)
    return (
        <>
            <Text>
                <p>
                    This is product video care page
                </p>
            </Text>
            <Video url={videos} maxHeight="80%" />
        </>
    )
}
