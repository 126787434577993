import React, { Component } from 'react';
import classes from "../../../components/home/css/Home.module.css";
import Line from "../../main/Line";
import BackButton from '../../main/BackButton';

class FollowUs extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Line type="bottom-line">
          <a href="https://www.instagram.com/wonwonleywon/" target="_blank" style={{ color: "black", textDecoration: "none" }}>
            Instagram
          </a>
        </Line>
        <Line type="bottom-line">
          {/* <a href="https://twitter.com/wonwonleywon" target="_blank" style={{ color: "black", textDecoration: "none" }}>
            Twitter
          </a> */}
        </Line>
        <Line type="bottom-line">
          {/* <a href="https://www.tiktok.com/@wonwonleywon" target="_blank" style={{ color: "black", textDecoration: "none" }}>
            tiktok
          </a> */}
        </Line>
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
      </>
    );
  }
}



export default FollowUs;



