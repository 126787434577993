import React, { useEffect, useRef, useState } from "react";
import axios from "../../axios-backend";
import BackButton from "../main/BackButton";
import Button from "../main/Button";
import Line from "../main/Line";

const AdType = (props) => {
  const [name, setName] = useState("");
  const [proPrice, setProPrice] = useState("");
  const [gender, setGender] = useState();
  const [areaAmount, setAreaAmount] = useState(1);
  const paramsString = props.location.search.substring(1);
  const searchParams = new URLSearchParams(paramsString);
  const idRef = useRef(searchParams.get("id"));
  const [malePrice, setMalePrice] = useState("");
  const [femalePrice, setFemalePrice] = useState("");
  const [unisexPrice, setUnisexPrice] = useState("");

  const [nameOf, setNameOf] = useState("");
  const [material, setMaterial] = useState("");
  const [madeIn, setMadeIn] = useState("");
  const [changeCountry, setChangeCountry] = useState("");
  const [shippingMethod, setShippingMethod] = useState("");
  const [shippingCost, setShippingCost] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [priceAndCurrency, setPriceAndCurrency] = useState("");
  const [duties, setDuties] = useState("");
  const [international, setInternational] = useState("");
  const [returns, setReturns] = useState("");
  const [refund, setRefund] = useState("");
  const [contact, setContact] = useState("");
  const [philanthropy, setPhilanthropy] = useState("");
  const [care, setCare] = useState("");
  const [tracking, setTracking] = useState("");
  const [proImage, setProImage] = useState("");
  const [taxes, setTaxes] = useState("");
  const [dimensionDetails, setDimensionDetails] = useState("");
  const [dimensionBefore, setDimensionBefore] = useState("");
  const [label, setLabel] = useState("");
  const [womenproductimage, setWomenproductimage] = useState("");
  const [menproductimage, setMenproductimage] = useState("");
  const [singlecollection, setSinglecollection] = useState(false);
  const [issoldout, setIssoldout] = useState(false);
  const [preSale, setPreSale] = useState(false);
  const [preSaleText, setPreSaleText] = useState("");
  const [authPortalText, setAuthPortalText] = useState("");

  useEffect(() => {
    if (idRef.current) {
      axios
        .get("/type", {
          params: {
            id: idRef.current,
          },
        })
        .then((data) => {
          if (data.data.type) {
            setName(data.data.type.name);
            setGender(data.data.type.gender);
            setProPrice(data.data.type.proPrice);
            setAreaAmount(data.data.type.areaAmount);
            setFemalePrice(data.data.type.femalePrice);
            setMalePrice(data.data.type.malePrice);
            setUnisexPrice(data.data.type.unisexPrice);
            setNameOf(data.data.type.nameOf);
            setMaterial(data.data.type.material);
            setMadeIn(data.data.type.madeIn);
            setChangeCountry(data.data.type.changeCountry);
            setShippingMethod(data.data.type.shippingMethod);
            setShippingCost(data.data.type.shippingCost);
            setDeliveryTime(data.data.type.deliveryTime);
            setPaymentMethod(data.data.type.paymentMethod);
            setPriceAndCurrency(data.data.type.priceAndCurrency);
            setDuties(data.data.type.duties);
            setInternational(data.data.type.international);
            setReturns(data.data.type.returns);
            setContact(data.data.type.contact);
            setPhilanthropy(data.data.type.philanthropy);
            setCare(data.data.type.care);
            setRefund(data.data.type.refund);
            setTracking(data.data.type.tracking);
            setProImage(data.data.type.proImage);
            setTaxes(data.data.type.taxes);
            setDimensionDetails(data.data.type.dimensionDetails);
            setDimensionBefore(data.data.type.dimensionBefore);
            setLabel(data.data.type.label);
            setWomenproductimage(data.data.type.womenproductimage);
            setMenproductimage(data.data.type.menproductimage);
            setSinglecollection(data.data.type.singlecollection);
            setIssoldout(data.data.type.issoldout);
            setPreSale(data.data.type.preSale);
            setPreSaleText(data.data.type.preSaleText);
            setAuthPortalText(data.data.type.authPortalText);
          }
        });
    }
  }, []);

  const handleSubmit = async () => {
    const params = {
      name,
      areaAmount,
      gender,
      proPrice,
      femalePrice,
      malePrice,
      unisexPrice,
      nameOf,
      material,
      madeIn,
      changeCountry,
      shippingMethod,
      shippingCost,
      deliveryTime,
      paymentMethod,
      priceAndCurrency,
      duties,
      international,
      returns,
      contact,
      philanthropy,
      care,
      refund,
      tracking,
      proImage,
      taxes,
      dimensionBefore,
      dimensionDetails,
      label,
      singlecollection,
      issoldout,
      preSale,
      preSaleText,
      authPortalText,
    };
    const productimagemen = document.getElementById("productimagemen");
    const productimagewomen = document.getElementById("productimagewomen");
    let womenproductimagefile = productimagewomen?.files;
    let menproductimagefile = productimagemen?.files;
    if (womenproductimagefile || menproductimagefile || menproductimage || womenproductimage) {
      if (idRef.current) {
        params.id = idRef.current;
        await axios.patch("/types", params);
      } else {
        const res = await axios.post("/types", params);
        idRef.current = res?.data[0];
      }
      if ((womenproductimagefile?.length > 0 || menproductimagefile?.length > 0) && idRef.current) {
        const formData = new FormData();
        if (womenproductimagefile?.length > 0) {
          for (let i = 0; i < womenproductimagefile?.length && i < 25; i++) {
            formData.append("womenproductimage", womenproductimagefile[i]);
          }
        }
        if (menproductimagefile?.length > 0) {
          for (let i = 0; i < menproductimagefile?.length && i < 25; i++) {
            formData.append("menproductimage", menproductimagefile[i]);
          }
        }

        formData.append("id", idRef.current);
        await axios.patch("/types/productimage", formData);
      }
      props.history.goBack();
    } else {
      alert("please add product image for men or women");
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <BackButton />
      <Line type="bottom-line">
        <h4 className="labelFor">This is a panel for adding the types</h4>
      </Line>
      <Line type="bottom-line">
        <label htmlFor="singlecollection" className="labelFor">
          is single collection:
        </label>
        <input
          type="checkbox"
          id="singlecollection"
          style={{
            appearance: "auto",
            "-moz-appearance": "auto",
            "-webkit-appearance": "auto",
            marginTop: "8px",
            width: "15px",
            height: "15px",
          }}
          name="singlecollection"
          value={singlecollection}
          checked={singlecollection}
          onChange={(e) => {
            setSinglecollection(e.target.checked);
          }}
        />
      </Line>

      <Line type="bottom-line">
        <label htmlFor="singlecollection" className="labelFor">
          Is Sold Out:
        </label>
        <input
          type="checkbox"
          id="issoldout"
          style={{
            appearance: "auto",
            "-moz-appearance": "auto",
            "-webkit-appearance": "auto",
            marginTop: "8px",
            width: "15px",
            height: "15px",
          }}
          name="issoldout"
          value={issoldout}
          checked={issoldout}
          onChange={(e) => {
            setIssoldout(e.target.checked);
          }}
        />
      </Line>

      {/* selling soon or preSale */}
      <Line type="bottom-line">
        <label htmlFor="preSale" className="labelFor">
          Selling soon or preSale:
        </label>
        <input
          type="checkbox"
          id="preSale"
          style={{
            appearance: "auto",
            "-moz-appearance": "auto",
            "-webkit-appearance": "auto",
            marginTop: "8px",
            width: "15px",
            height: "15px",
          }}
          name="preSale"
          value={preSale}
          checked={preSale}
          onChange={(e) => {
            setPreSale(e.target.checked);
          }}
        />
      </Line>

      {/* selling soon or preSale text area -> only show if preSale is true */}
      {preSale && (
        <Line type="bottom-line">
          <label htmlFor="preSaleText" className="labelFor">
            Selling soon or preSale Text:
          </label>
          <textarea
            style={{ width: "700px", height: "80%" }}
            id="preSaleText"
            name="preSaleText"
            value={preSaleText}
            onChange={(e) => {
              setPreSaleText(e.target.value);
            }}
          />
        </Line>
      )}
      <Line type="bottom-line">
        <label htmlFor="name" className="labelFor">
          collection Name:
        </label>
        <input
          type="text"
          id="typeName"
          name="typeName"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label htmlFor="priceCanada" className="labelFor">
          Area Amount:
        </label>
        <input
          type="number"
          id="areaAmount"
          name="areaAmount"
          value={areaAmount}
          onChange={(e) => {
            setAreaAmount(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label htmlFor="gender" className="labelFor">
          Gender:
        </label>
        <select
          id="gender"
          name="gender"
          value={gender}
          onChange={(e) => {
            setGender(e.target.value);
          }}
        >
          <option value={null}> Select gender </option>
          <option value="2">unisex</option>
          <option value="0">female</option>
          <option value="1">male</option>
        </select>
      </Line>
      <Line type="bottom-line">
        <label htmlFor="name" className="labelFor">
          price:
        </label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={unisexPrice}
          onChange={(e) => {
            setUnisexPrice(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label htmlFor="name" className="labelFor">
          price for product details page:
        </label>
        <input
          type="text"
          value={proPrice}
          onChange={(e) => {
            setProPrice(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">philanthropy:</label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={philanthropy}
          onChange={(e) => {
            setPhilanthropy(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">name of product:</label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={nameOf}
          onChange={(e) => {
            setNameOf(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">material:</label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={material}
          onChange={(e) => {
            setMaterial(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">care:</label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={care}
          onChange={(e) => {
            setCare(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">made in:</label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={madeIn}
          onChange={(e) => {
            setMadeIn(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">duties:</label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={duties}
          onChange={(e) => {
            setDuties(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">shipping methods:</label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={shippingMethod}
          onChange={(e) => {
            setShippingMethod(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">shipping costs:</label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={shippingCost}
          onChange={(e) => {
            setShippingCost(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">delivery time:</label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={deliveryTime}
          onChange={(e) => {
            setDeliveryTime(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">returns:</label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={returns}
          onChange={(e) => {
            setReturns(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">prices and currencies:</label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={priceAndCurrency}
          onChange={(e) => {
            setPriceAndCurrency(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">payment methods:</label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={paymentMethod}
          onChange={(e) => {
            setPaymentMethod(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">international transaction fees:</label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={international}
          onChange={(e) => {
            setInternational(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">Product Image</label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={proImage}
          onChange={(e) => {
            setProImage(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">taxes:</label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={taxes}
          onChange={(e) => {
            setTaxes(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">dimension Before product page:</label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={dimensionBefore}
          onChange={(e) => {
            setDimensionBefore(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">dimension for product Details:</label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={dimensionDetails}
          onChange={(e) => {
            setDimensionDetails(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">collection label :</label>
        <textarea
          style={{ width: "700px", height: "80%" }}
          value={label}
          onChange={(e) => {
            setLabel(e.target.value);
          }}
        />
      </Line>

      <Line type="bottom-line">
          <label htmlFor="authPortalText" className="labelFor">
            Authenticity Portal Text (shift + enter for next line in text field):
          </label>
          <textarea
            style={{ width: "700px", height: "80%" }}
            id="authPortalText"
            name="authPortalText"
            value={authPortalText}
            onChange={(e) => {
              setAuthPortalText(e.target.value);
            }}
          />
        </Line>
      {gender != "0" && (
        <Line type="bottom-line">
          <label htmlFor="productimagemen" className="labelFor">
            product image for men :
          </label>
          <input type="file" id="productimagemen" multiple />
          {/* {menproductimage !== "" && (
            <div style={{ paddingLeft: "10px" }}>
              {menproductimage}
              <button style={{cursor:"pointer"}} onClick={()=>setMenproductimage("")}>x</button>
            </div>
          )} */}
        </Line>
      )}
      {gender != "1" && (
        <Line type="bottom-line">
          <label htmlFor="productimagewomen" className="labelFor">
            product image for women :
          </label>
          <input type="file" id="productimagewomen" multiple />
          {/* {womenproductimage !== "" && (
            <div style={{ paddingLeft: "10px" }}>
              {womenproductimage}
              <button style={{cursor:"pointer"}} onClick={()=>setMenproductimage("")}>x</button>
            </div>
          )} */}
        </Line>
      )}
      <Line type="bottom-line">
        <Button type="submit" className="labelFor" onClick={handleSubmit}>
          Submit
        </Button>
      </Line>
    </div>
  );
};

export default AdType;
