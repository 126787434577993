import React from 'react';
import BackButton from '../../main/BackButton';
import Text from '../../main/Text';

export default function Gifts() {
    return (
        <>
            <BackButton />
            <Text>
            for every product sold, we provide a gift to support the purchaser or other people to be the best version of themselves. the gift will be associated with one of the four pillars that we believe are vital to enable people to become the best version of themselves: the mind, body, knowledge, or entertainment. or, the gift may be a financial contribution that goes towards wonwonleywon support. to learn about wonwonleywon support, click on the support button, on the previous page. to know what the gift is, you must be on a specific product's product page and click on the philanthropy tab. when within the philanthropy tab, the gift will be described.
            </Text>

        </>
    )
}
