import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import axios from "../../axios-backend";
import BackButton from "../main/BackButton";
import Button from "../main/Button";
import Columns from "../main/Columns";
import Line from "../main/Line";
import "./css/ViewProducts.css";

const ViewProductsAuth = (props) => {
  const [productsAuth, setProductsAuth] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    getData(0);
  }, []);

  const getData = (offset) => {
    axios
      .get("/products-auth", {
        params: { offset, pageSize },
      })
      .then((p) => {
        setPageCount(p.data.pageCount);
        setProductsAuth(p.data.list);
      });
  };

  const handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * pageSize);
    getData(offset);
  };

  const renderProductsAuth = () => {
    if (productsAuth.length > 0) {
      return productsAuth.map((p, index) => {
        // converting utc to Vancouver time zone and in readable format
        let utcTimestamp = p.transferDateTime;
        let vancouverTime = new Date(utcTimestamp).toLocaleString("en-US", { timeZone: "America/Vancouver" });
        return (
          <Line type="bottom-line" key={index}>
            <Columns width="5%">{index + 1}</Columns>
            <Columns width="5%">{p.pid}</Columns>
            <Columns width="20%">{p.name}</Columns>
            <Columns width="10%">{p.cardNumber}</Columns>
            <Columns width="10%">{p.password}</Columns>
            <Columns width="20%">{p.email}</Columns>
            <Columns width="20%">{vancouverTime}</Columns>
          </Line>
        );
      });
    } else
      return (
        <Line type="bottom-line" style={{ justifyContent: "center" }}>
          loading...
        </Line>
      );
  };

  return (
    <div style={{ height: "100%" }}>
      <BackButton />
      <Line type="bottom-line">
        <Columns width="5%">Index</Columns>
        <Columns width="5%">ProductId</Columns>
        <Columns width="20%">Name</Columns>
        <Columns width="10%">card number</Columns>
        <Columns width="10%">Password</Columns>
        <Columns width="20%">Email</Columns>
        <Columns width="20%">transferDateTime</Columns>
      </Line>
      {renderProductsAuth()}
      <Line type="bottom-line">
        <div className="paginate-container">
          <ReactPaginate
            previousLabel={"←"}
            nextLabel={"→"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={pageSize}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"bolder-a"}
            disabledClass={"disable-a"}
          />
        </div>
      </Line>
    </div>
  );
};

export default ViewProductsAuth;
