import React, { useRef, useState } from "react";
import axios from "../../axios-backend";
import BackButton from "../main/BackButton";
import Button from "../main/Button";
import Line from "../main/Line";

const AdTypeVideo = (props) => {
  const paramsString = props.location.search.substring(1);
  const searchParams = new URLSearchParams(paramsString);
  const idRef = useRef(searchParams.get("id"));

  const [currentVideo, setCurrentVideo] = useState(null);
  const [gender, setGender] = useState("");
  const [video, setVideo] = useState('');

  const handleChangeVideo = (e) => {
    const file = e.target.files[0];
    if (file) {
      setVideo(file);
    } else {
      alert("please select a file");
      return;
    }
  };

  const handleSave = () => {
    let formData = new FormData();
    formData.append("id", idRef.current);
    // formData.append("price", price);
    formData.append("gender", gender);
    formData.append("video", video);
    axios({
      method: "post",
      url: "/upload-type-video",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((data) => {
        console.log(data);
        if (data.data.status === 200) {
          props.history.goBack();
        } else {
          alert(data.data.msg);
        }
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const handleSaveNew = () => {
    const params = { pId: idRef.current, url: video, gender };
    axios.post("/upload-type-video-new", params).then(data => {
      if (data.data.status === 200) {
        alert('done')
      }
    });
  }

  const handleGenderChange = (e) => {
    setGender(e.target.value);
    axios
      .get("/get-type-video", {
        params: {
          id: idRef.current,
          gender: e.target.value,
        },
      })
      .then((data) => {
        if (!data) {
          return;
        } else {
          setVideo(data.data.video.videoUrl);
          // setCurrentVideo(data.data.video.videoUrl);
        }
      });
  };

  const handleDelete = () => {
    const result = window.confirm(
      "Are you sure you want to delete this video?"
    );
    if (result) {
      axios
        .delete("/types-video", { data: { id: idRef.current, gender } })
        .then((data) => {
          props.history.goBack();
        });
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <BackButton />
      <Line type="bottom-line">
        This is a page for adding videos. if you add a unisex video, it will be
        displayed on both women and men sides
      </Line>
      <Line type="bottom-line">
        <select
          value={gender}
          onChange={(e) => {
            handleGenderChange(e);
          }}
        >
          <option>Please Select Gender</option>
          <option value="1">Collection video - Men</option>
          <option value="0">Collection video - Women</option>
          <option value="2">Collection video - Unisex</option>
          <option value="11">Men's video - How to measure Men</option>
          <option value="00">Women's video - How to measure Women</option>
          <option value="22">Unisex's video - How to Measure Unisex</option>
          <option value="111">Mens' Meaning Video</option>
          <option value="000">Womens' Meaning Video</option>
        </select>
      </Line>

      {/* //#region
      {!currentVideo ? (
        <>
          <Line type="bottom-line">
            <input
              type="file"
              accept="video/*"
              onChange={handleChangeVideo}
              name="file"
              multiple
            />
          </Line>
          <Line type="bottom-line">
            <Button
              onClick={(e) => {
                gender === ""
                  ? window.alert("please select gender")
                  : handleSave();
              }}
            >
              Upload{" "}
            </Button>
          </Line>
        </>
      ) : (
        <Line type="bottom-line">
          <Button onClick={(e) => handleDelete()}>Delete Video</Button>
        </Line>
      )}
      //#endregion */}
      <Line type="bottom-line">
        Note: Copy and paste the url of the collection video that you uploaded to Wistia
      </Line>
      <Line type="bottom-line">
        <label>Wistia Link: </label>
        <input
          type="text"
          value={video}
          style={{ width: "20rem" }}
          onChange={(e) => {
            setVideo(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <Button
          onClick={(e) => {
            gender === ""
              ? window.alert("please select gender")
              : handleSaveNew();
          }}
        >
          Upload
        </Button>
      </Line>
    </div>
  );
};

export default AdTypeVideo;
