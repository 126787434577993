import React from 'react';
import classes from './css/ContentDiv.module.css';

export default function ContentDiv(props) {
    const { children, height, className, ...rest } = props;
    return (
        <div style={{ minHeight: height }} className={classes.contentDiv + " " + (className ? className : "")} {...rest}>
            {children}
        </div>
    )
}
