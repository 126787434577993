import React, { useEffect, useState } from 'react';
import Text from "../../../main/Text";
import BackButton from '../../../main/BackButton';
import axios from '../../../../axios-backend';

const Material = (props) => {
    const [content, setContent] = useState('');
    const title = props.location.state.title;
    const tid = props.location.state.tid;
    useEffect(() => {
        if (title && tid && title != "changeCountry") {
            axios.get('/get-product-info', {
                params: { title, tid }
            }).then(data => {
                if (data.data.status === 200 && data.data.content) {
                    setContent(data.data.content)
                }
            });
        }
    }, []);
    return (
        <>
            <BackButton />
            <Text>
                {
                    title == "changeCountry" ?
                        <>you cannot change the country to see prices in different currencies, and all prices are only displayed and billed in usd. if you want to know the price of this product in a different currency,
                            email us at <a href="mailto:support@wonwonleywon.com" style={{ color: "black" }}>support@wonwonleywon.com</a>
                        </>
                        :
                        content.split('\n').map(val => {
                            return (
                                <>{val}  <br /></>
                            )
                        })
                }
            </Text>

        </>
    );
}


export default Material;

