import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Line from "../main/Line";
import classes from "./css/Home.module.css";

class Home extends Component {
  render() {
    return (
      <>
        <Line type="bottom-line">
          <NavLink to="/world/" className={classes.navLink}>
            World of Wonwonleywon
          </NavLink>
        </Line>

        {/* shop for women */}
        <Line type="bottom-line">
          <NavLink to="/women/" className={classes.navLink}>
            shop products - women
          </NavLink>
        </Line>

        {/* shop for men */}
        <Line type="bottom-line">
          <NavLink to="/men/" className={classes.navLink}>
            shop products - men
          </NavLink>
        </Line>

        {/* <Line type="bottom-line">
          <NavLink to="/products/" className={classes.navLink}>
            shop Products
          </NavLink>
        </Line> */}
        

        <Line type="bottom-line">
          <NavLink to="/boutique/" className={classes.navLink}>
            Boutique
          </NavLink>
        </Line>


        <Line type="bottom-line">
          <NavLink to="/world/mission/music" className={classes.navLink}>
            {" "}
            music video{" "}
          </NavLink>
        </Line>

        <Line type="bottom-line">
          <NavLink to="/allvideos" className={classes.navLink}>
            {" "}
            all videos{" "}
          </NavLink>
        </Line>
 

        <Line type="bottom-line">
          <NavLink to="/authenticity/" className={classes.navLink}>
            authenticity portal
          </NavLink>
        </Line>

        <Line type="bottom-line">
          <NavLink to="/more/" className={classes.navLink}>
            More ...
          </NavLink>
        </Line>
        <Line type="bottom-line"/>
        <Line type="bottom-line"/>
        <Line type="bottom-line"/>
        <Line type="bottom-line"/>
      </>
    );
  }
}

export default Home;
