import React from 'react';
import PrivacyPolicy from '../../main/PrivacyPolicy'
import BackButton from '../../main/BackButton';

export default function PrivacyPolicyPage(props) {
    return (
        <>
            <BackButton />
            <PrivacyPolicy type="page" show={true} />
        </>
    )
}
