import React, { useState, useEffect } from "react";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import BackButton from "../../../main/BackButton";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const appearance = {
  base: {
    color: "#32325d",
  },
};

const elFonts = [
  {
    cssSrc:
      "https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@100&display=swap",
    family: "Encode Sans Condensed, sans-serif",
    style: "normal",
  },
];
const Purchase = (props) => {
  const productId = props.location.state.product;
  const typeId = props.location.state.type;
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    if (success) {
      props.history.push("/products/success");
    }
  }, [success]);

  return (
    <div style={{ height: "100%" }}>
      <BackButton />
      <Elements
        style={appearance}
        stripe={stripePromise}
        options={{ fonts: elFonts }}
      >
        <CheckoutForm
          productId={productId}
          typeId={typeId}
          setSuccess={setSuccess}
        />
      </Elements>
    </div>
  );
};

export default Purchase;
