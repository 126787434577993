import React, { useEffect, useState, useRef } from "react";
import Line from "../../main/Line";
import BackButton from "../../main/BackButton";
import axios from "../../../axios-backend";
import Button from "../../main/Button";
import { Link } from "react-router-dom";

const ViewOptions = (props) => {
  const paramsString = props.location.search.substring(1);
  const searchParams = new URLSearchParams(paramsString);
  const idRef = useRef(searchParams.get("id"));
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get("/all-options", {
        params: { stepsId: idRef.current },
      })
      .then((data) => {
        setOptions(data.data.data);
      });
  };

  const mapAndRenderOptions = () => {
    return options.map((item, index) => {
      return (
        <Line type="bottom-line" key={index}>
          {item.index}: {item.content}
          <Link
            style={{ marginLeft: "2rem" }}
            to={`/edit-option?id=${item.id}`}
          >
            <Button>Edit</Button>
          </Link>
          <Link
            style={{ marginLeft: "2rem" }}
            to={`/add-option-img?id=${item.id}`}
          >
            <Button>Upload Option Image</Button>
          </Link>
        </Line>
      );
    });
  };

  return (
    <>
      <BackButton />
      <Line type="bottom-line">
        <Link to={`/edit-option?sid=${idRef.current}`}>
          <Button> Add New Option </Button>
        </Link>
      </Line>
      {options == null ? null : mapAndRenderOptions()}
    </>
  );
};

export default ViewOptions;
