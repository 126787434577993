import React from "react";
import Text from "../../main/Text";
import BackButton from "../../main/BackButton";

export default function Taxes() {
  return (
    <>
      <BackButton />
      <Text>
        taxes will be calculated at checkout if we ship our products to canada
        or the usa. if we ship our products outside of canada or the usa, you
        may be charged taxes. the shipping provider will collect these taxes at
        the time of delivery. we do not know the tax amount, but you will know
        the specific amount at the time of delivery. you may contact your local
        customs office to ask what these additional costs could be prior to
        purchase. any taxes payable will be your responsibility.
      </Text>
    </>
  );
}
