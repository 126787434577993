import React from 'react';
import Line from '../../../main/Line';
import styles from './css/ButtonsLine.module.css';

export default function ButtonsLine(props) {
    return (
        <Line type="empty-line" className={styles.buttonsContainer}>
            <button className={styles.button} onClick={() => { props.preStep() }}>back</button>
            <button style={{ display: props.hideSkip ? "none" : "block" }} className={styles.button} onClick={() => { props.skipStep() }}>skip/don't know</button>
            <button className={styles.button} onClick={() => { props.nextStep() }}>continue</button>
        </Line>
    )
}
