import React from "react";
import BackButton from "../../../main/BackButton";
// import videos from '../../../../videos/testvideo.mp4';
import Video from "../../../main/Video";

export default function Music() {
  return (
    <>
      <BackButton />
      <Video url={"https://yuriy-dmytrash19.wistia.com/medias/ptmiaqqryz"} maxHeight="80%" />
    </>
  );
}
