import React, { useEffect, useState } from 'react';
import Text from "../../../main/Text";
import BackButton from '../../../main/BackButton';
import axios from '../../../../axios-backend';

const ProductInfo = (props) => {
    const [content, setContent] = useState('');
    const title = props.location.state.title;
    const tid = props.location.state.tid;
    useEffect(() => {
        if (title && tid) {
            axios.get('/get-product-details-info', {
                params: { title, tid }
            }).then(data => {
                if (data.data.status === 200 && data.data.content) {
                    setContent(data.data.content)
                }
            });
        }
    }, []);
    return (
        <>
            <BackButton />
            <Text>
                {content.split('\n').map(val => {
                    return (
                        <>{val}  <br /></>
                    )
                })}
            </Text>
        </>
    );
}


export default ProductInfo;

