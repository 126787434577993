import React from 'react';
import Line from '../../../main/Line';
// import styles from './css/SelectionLine.module.css';
import RadioButton from '../../../main/RadioButton'

export default function SelectionLine(props) {
    return (
        <Line type="bottom-line"  >
            <RadioButton onClick={props.setSelected} checked={props.item.index === props.selected} >
                {props.item.text}
            </RadioButton>
            {/* <label className={styles.radLabel} onClick={() => { props.setSelected() }}>
                <input type="radio" className={styles.radInput} name="rad" checked={props.item.index === props.selected} />
                <div className={styles.radDesign}></div>
                <div className={styles.radText}>{props.item.text}</div>
            </label> */}
        </Line>
    )
}
