import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../../axios-backend";
import ReactPaginate from "react-paginate";
import Line from "../main/Line";
import Button from "../main/Button";
import BackButton from "../main/BackButton";
import "./css/ViewProducts.css";
import Columns from "../main/Columns";

const ViewProducts = (props) => {
  const [products, setProducts] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [searchInfo, setSearchInfo] = useState();

  useEffect(() => {
    getData(0);
  }, []);

  const getData = (offset) => {
    console.log(1)
    axios
      .get("/products", {
        params: { offset, pageSize, searchInfo },
      })
      .then((p) => {
        setPageCount(p.data.pageCount);
        setProducts(p.data.list);
      });
  };

  const handleRedirect = () => {
    props.history.push("/adproduct");
  };
  const handleDelete = (id) => {
    const result = window.confirm(
      "Are you sure you want to delete this product?"
    );
    if (result) {
      axios.delete("/products", { data: { id } }).then(() => {
        getData(0);
      });
    }
  };
  const handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * pageSize);
    getData(offset);
  };

  const renderProducts = () => {
    if (products.length > 0) {
      return products.map((p) => {
        return (
          <Line type="bottom-line" key={p.id}>
            <Columns width="10%">{p.name}</Columns>
            <Columns width="10%">{p.cardNumber}</Columns>
            <Columns width="10%">${p.priceUs}</Columns>
            <Columns width="10%">{p.typeName}</Columns>
            <Columns width="10%">{p.size}</Columns>
            <Columns width="15%">{p.color.join(" ")}</Columns>
            <Columns width="10%">{p.status === 1 ? "Normal" : "Sold"}</Columns>
            <Columns width="25%">
              <Link
                to={`/adproduct?id=${p.id}`}
                style={{ marginRight: "1rem" }}
              >
                <Button>Update</Button>
              </Link>
              <Link to={`/viewdetails/${p.id}`} style={{ marginRight: "1rem" }}>
                <Button>View</Button>
              </Link>
              <Button onClick={() => handleDelete(p.id)}>Delete</Button>
            </Columns>
          </Line>
        );
      });
    } else
      return (
        <Line type="bottom-line" style={{ justifyContent: "center" }}>
          loading...
        </Line>
      );
  };

  return (
    <div style={{ height: "100%" }}>
      <BackButton className="between">
        <div>
          search by card or name:&nbsp;
          <input
            type="text"
            value={searchInfo}
            onChange={(e) => {
              setSearchInfo(e.target.value);
            }}
          />
          <Button onClick={() => getData(0)}>Search</Button>
        </div>
      </BackButton>
      <Line type="bottom-line">
        <Columns width="10%">Name</Columns>
        <Columns width="10%">cardnumber</Columns>
        <Columns width="10%">Price(US)</Columns>
        <Columns width="10%">Collections</Columns>
        <Columns width="10%">Size</Columns>
        <Columns width="15%">Color</Columns>
        <Columns width="10%">Status</Columns>
        <Columns width="25%">
          <Button onClick={handleRedirect}>Add new product</Button>
        </Columns>
      </Line>
      {renderProducts()}
      <Line type="bottom-line">
        <div className="paginate-container">
          <ReactPaginate
            previousLabel={"←"}
            nextLabel={"→"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={pageSize}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"bolder-a"}
            disabledClass={"disable-a"}
          />
        </div>
      </Line>
    </div>
  );
};

export default ViewProducts;
