import React, { useEffect, useState } from 'react';
import axios from '../../axios-backend';
import BackButton from '../main/BackButton';
import Button from '../main/Button';
import Line from '../main/Line';

// terminologies
// gender (1|2) 1-> male | 2-> female
// type (1|2|3|4|5) 1-> product details + authenticity | 2-> only authenticity | 3-> product dimension | 4-> male | 5-> female
const AdPicture = (props) => {
  const paramsString = props.location.search.substring(1);
  const searchParams = new URLSearchParams(paramsString);
  const fromPage = searchParams.get("type")
  const [genderType, setGenderType] = useState(0)

  useEffect(() => {
    const id = props.match.params.id
    axios.get(`/products/${id}`).then(p => {
        if (p.data[0]) {
          setGenderType(p.data[0].gender)
        }
    });
}, []);

  const [imgs, setImgs] = useState([]);
  const [type, setType] = useState(0);
  const [index, setIndex] = useState(0);

  const handleUpload = () => {
    if (type) {
      let formData = new FormData();
      imgs.forEach(item => {
        formData.append('imgs', item);
      })
      formData.append('id', props.match.params.id);
      formData.append('type', type);
      formData.append('index', index);
      axios({
        method: 'post',
        url: '/uploadimgs',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(data => {
        if (data.data.status === 200) {
          props.history.goBack();
        } else {
          alert(data.data.msg)
        }
      }).catch(r => {
        console.log(r);
      })
    } else {
      alert('select a type');
    }
  }

  const handleChangeImg = (e) => {
    const files = e.target.files;
    if (files.length > 0 && files.length <= 10) {
      const length = files.length;
      for (let i = 0; i < 10; i++) {
        const preview = document.querySelector(`#img-files${i}`);
        preview.src = '';
        preview.style.display = 'none';
      }
      const arr = [];
      for (let i = 0; i < length; i++) {
        const file = files[i];
        arr.push(file);
        const preview = document.querySelector(`#img-files${i}`);
        const reader = new FileReader();
        reader.onloadend = function () {
          preview.src = reader.result;
          preview.style.display = 'block';
        }
        reader.readAsDataURL(file);
      }
      setImgs(arr);
    } else {
      alert('please select no more than 10 pictures');
      return;
    }
  }
  return (
    <div style={{ height: "100%" }}>
      <BackButton />
      <Line type="bottom-line">
        Page for adding picture(s) to product.  Please note that maximum 10 files per uploading
      </Line>
      {genderType === 2 ? <Line type="bottom-line">
        picture type:
        <select onChange={e => { setType(e.target.value) }} value={type}>
          <option value="0" disabled>Select a type</option>
          {
            fromPage === "product" ?
              <>
                <option value="2">authenticity image only</option>
                <option value="4">product details - men</option>
                <option value="5">product details - women</option>
              </>
              : <option value="3">product dimensions</option>
          }


        </select>
        <span style={{ marginLeft: "1rem", color: "red" }}>
          check the type before uploading images
        </span>
      </Line> : <Line type="bottom-line">
        picture type:
        <select onChange={e => { setType(e.target.value) }} value={type}>
          <option value="0" disabled>select a type</option>
          {
            fromPage === "product" ?
              <>
                <option value="1">product details page</option>
                <option value="2">authenticity image only</option>
              </>
              : <option value="3">product dimensions</option>
          }


        </select>
        <span style={{ marginLeft: "1rem", color: "red" }}>
          check the type before uploading images
        </span>
      </Line>}
      <Line type="bottom-line">
        picture index: <input
          type="number"
          id="index"
          name="index"
          value={index}
          onChange={(e) => {
            setIndex(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <input type="file" accept="image/*" name="file" multiple onChange={handleChangeImg} />
      </Line>
      <div style={{ height: "38.5%", display: "flex", flexWrap: "wrap", padding: "2rem" }}>
        <img src="" width="150px" alt='img-product' height="150px" style={{ display: 'none' }} id="img-files0" />
        <img src="" width="150px" alt='img-product' height="150px" style={{ display: 'none' }} id="img-files1" />
        <img src="" width="150px" alt='img-product' height="150px" style={{ display: 'none' }} id="img-files2" />
        <img src="" width="150px" alt='img-product' height="150px" style={{ display: 'none' }} id="img-files3" />
        <img src="" width="150px" alt='img-product' height="150px" style={{ display: 'none' }} id="img-files4" />
        <img src="" width="150px" alt='img-product' height="150px" style={{ display: 'none' }} id="img-files5" />
        <img src="" width="150px" alt='img-product' height="150px" style={{ display: 'none' }} id="img-files6" />
        <img src="" width="150px" alt='img-product' height="150px" style={{ display: 'none' }} id="img-files7" />
        <img src="" width="150px" alt='img-product' height="150px" style={{ display: 'none' }} id="img-files8" />
        <img src="" width="150px" alt='img-product' height="150px" style={{ display: 'none' }} id="img-files9" />
      </div>
      <Line type="top-line">
        <Button onClick={handleUpload}>Upload</Button>
      </Line>
      <Line type="top-line"></Line>
      <Line type="top-line"></Line>
      <Line type="top-line"></Line>
    </div>
  )
}



export default AdPicture;
