import React from 'react';
import Text from '../../../main/Text';
import Line from '../../../main/Line';
import { GrClose } from "react-icons/gr";

const SuccessPaid = (props) => {

    return (
        <>
            <Line type="bottom-line" style={{ flexDirection: "row-reverse" }} >
                <GrClose style={{ cursor: "pointer" }} onClick={() => {
                    props.history.push('/home')
                }} />
            </Line>
            <Text >
                this is confirmation that we have received your order. congratulations on
                being the only person in the world to own the product you just ordered.
                we will email you with additional details within 7 business days. if you
                have any questions you can email us at support@wonwonleywon.com
            </Text>
        </>
    )
}
export default SuccessPaid;