import React, { useEffect, useState, useRef } from "react";
import Line from "../../main/Line";
import BackButton from "../../main/BackButton";
import axios from "../../../axios-backend";
import Button from "../../main/Button";
import { Link } from "react-router-dom";

const AdStep = (props) => {
  const paramsString = props.location.search.substring(1);
  const searchParams = new URLSearchParams(paramsString);
  const idRef = useRef(searchParams.get("id"));
  const sidRef = useRef(searchParams.get("sid"));

  const [imgs, setImgs] = useState([]);
  const [type, setType] = useState(0);
  const [description, setDescription] = useState("");
  const [index, setIndex] = useState("");
  const [shortName, setShortName] = useState("");
  const [currentImage, setCurrentImage] = useState("");
  const [video, setVideo] = useState();
  const [currentVideo, setCurrentVideo] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (!idRef.current) {
      return;
    } else {
      axios
        .get("/step", {
          params: { id: idRef.current },
        })
        .then((data) => {
          setDescription(data.data.data[0].description);
          setType(data.data.data[0].type);
          setIndex(data.data.data[0].index);
          setShortName(data.data.data[0].shortName);
          setCurrentImage(data.data.data[0].imageUrl);
          setCurrentVideo(data.data.data[0].videoUrl);
        });
    }
  };

  const handleUpload = () => {
    let formData = new FormData();
    imgs.forEach((item) => {
      formData.append("imgs", item);
    });
    formData.append("id", idRef.current);
    axios({
      method: "post",
      url: "/upload-step-img",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((data) => {
        if (data.data.status === 200) {
          props.history.goBack();
        } else {
          alert(data.data.msg);
        }
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const handleChangeImg = (e) => {
    const files = e.target.files;
    if (files.length > 0 && files.length <= 10) {
      const length = files.length;
      for (let i = 0; i < 1; i++) {
        const preview = document.querySelector(`#img-files${i}`);
        preview.src = "";
        preview.style.display = "none";
      }
      const arr = [];
      for (let i = 0; i < length; i++) {
        const file = files[i];
        arr.push(file);
        const preview = document.querySelector(`#img-files${i}`);
        const reader = new FileReader();
        reader.onloadend = function () {
          preview.src = reader.result;
          preview.style.display = "block";
        };
        reader.readAsDataURL(file);
      }
      setImgs(arr);
    } else {
      alert("please select no more than 10 pictures");
      return;
    }
  };

  const handleImgDelete = () => {
    axios
      .patch("/step", {
        id: idRef.current,
        imageUrl: "",
      })
      .then((data) => {
        if (data.data.status === 200) {
          getData();
        }
      });
  };

  const handleSubmit = () => {
    if (idRef.current === null) {
      axios
        .post("/step", {
          sizeFinderId: sidRef.current,
          description,
          index,
          type,
          shortName,
        })
        .then((data) => {
          if (data.status === 200) {
            props.history.goBack();
          }
        });
    } else {
      axios
        .patch("/step", {
          id: idRef.current,
          description,
          index,
          type,
          shortName,
        })
        .then((data) => {
          if (data.status === 200) {
            props.history.goBack();
          }
        });
    }
  };

  const handleDelete = () => {
    if (window.confirm("Do you really want to delete this step?")) {
      axios
        .delete("/step", {
          data: {
            id: idRef.current,
          },
        })
        .then((data) => {
          if (data.status === 200) {
            props.history.goBack();
          }
        });
    } else {
      return;
    }
  };
  const handleSaveVideo = () => {
    let formData = new FormData();
    formData.append("id", idRef.current);
    formData.append("video", video);

    axios({
      method: "post",
      url: "/upload-step-video",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((data) => {
        if (data.data.status === 200) {
          props.history.goBack();
        } else {
          alert(data.data.msg);
        }
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const handleChangeVideo = (e) => {
    const file = e.target.files[0];
    if (file) {
      setVideo(file);
    } else {
      alert("please select a file");
      return;
    }
  };

  const handleVideoDelete = () => {
    axios
      .patch("/step", {
        id: idRef.current,
        videoUrl: "",
      })
      .then((data) => {
        if (data.data.status === 200) {
          getData();
        }
      });
  };
  return (
    <>
      <BackButton />
      <Line type="bottom-line" className="text-area-admin">
        <label htmlFor="name">Description:</label>
        <textarea
          id="name"
          style={{ width: "300px", marginLeft: "2rem" }}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        Short Name
        <input
          type="text"
          value={shortName}
          style={{ width: "300px", marginLeft: "2rem" }}
          onChange={(e) => {
            setShortName(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        Index
        <input
          type="text"
          value={index}
          style={{ width: "300px", marginLeft: "2rem" }}
          onChange={(e) => {
            setIndex(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        Type:
        <select
          style={{ marginLeft: "2rem" }}
          onChange={(e) => {
            setType(e.target.value);
          }}
          value={type}
        >
          <option value={0}>Please Select</option>
          <option value={1}>Single Choice</option>
          <option value={2}>Input A Value</option>
          <option value={3}>Three Options</option>
          <option value={4}>Email Box</option>
          <option value={5}>Insert Inches or cm</option>
          <option value={6}>Multiple Options</option>
        </select>
      </Line>

      <Line
        type="bottom-line"
      // style={{ display: currentImage ? "none" : "flex" }}
      >
        <Button style={{ marginRight: "2rem" }} onClick={() => handleSubmit()}>
          Submit
        </Button>
        {idRef.current === null ? null : (
          <Button onClick={() => handleDelete()}>Delete</Button>
        )}
      </Line>
      {idRef.current ? (
        <>
          <Line type="bottom-line">Add / Edit Image</Line>
          <Line
            type="bottom-line"
            style={{ display: currentImage ? "none" : "flex" }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleChangeImg}
              name="file"
            />
            <Button
              style={{ marginRight: "2rem" }}
              onClick={() => handleUpload()}
            >
              Submit Image
            </Button>
          </Line>
          <Line
            type="bottom-line"
            style={{ height: "auto", maxHeight: "unset", minHeight: "unset" }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                padding: "2rem",
              }}
            >
              <img
                src=""
                width="150px"
                alt="img-product"
                height="150px"
                style={{ display: "none" }}
                id="img-files0"
              />
            </div>
            <div
              style={{ display: currentImage ? "block" : "none" }}
              className="img-of-admin"
            >
              <img
                src={process.env.REACT_APP_Url + currentImage}
                width="150px"
                alt="img-product"
                height="150px"
              />
              <div
                className="img-delete-button"
                onClick={() => {
                  handleImgDelete();
                }}
              >
                <i className="fas fa-window-close"></i>
              </div>
            </div>
          </Line>
          <Line type="bottom-line">Add / Edit Video</Line>
          <Line
            type="bottom-line"
            style={{ display: currentVideo ? "none" : "flex" }}
          >
            <input
              type="file"
              accept="video/*"
              onChange={handleChangeVideo}
              name="file"
              multiple
            />
            <Button onClick={handleSaveVideo}>Upload</Button>
          </Line>
          <Line type="bottom-line">
            <span style={{ marginLeft: "1rem" }}>{currentVideo}</span>
            <i
              className="fas fa-window-close"
              style={{
                marginLeft: "1rem",
                display: !currentVideo ? "none" : "block",
              }}
              onClick={handleVideoDelete}
            ></i>
          </Line>
        </>
      ) : null}
    </>
  );
};

export default AdStep;
