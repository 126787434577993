import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import styles from "./css/GuideWithDescription.module.css";

export default function GuideWithDescription(props) {
  const { name, largeImgUrl, fromCm, fromInches, description, type } = props.guide;
  const text = `Measure from the high point of your shoulder to ${
    props.unit === "IN" ? fromInches + " inches" : fromCm + " cm"
  }. This measurement should be approximately 
    where the t-shirt will end on your body if you 
    purchase this size.`;
  return (
    <div className={styles.container}>
      <div className={styles.title}>{name}</div>

      <div className={styles.description}>{type !== 4 ? description : text}</div>

      <LazyLoadImage
        src={process.env.REACT_APP_Url + largeImgUrl}
        alt="size"
        effect="blur"
        className={styles.imgBox}
        style={{
          minHeight: "300px",
          maxHeight: "500px",
          height: "100%",
          minWidth: "300px",
          maxWidth: "500px",
          width: "100%",
          padding: "10px 0",
          objectFit: "contain",
        }}
      />
    </div>
  );
}
