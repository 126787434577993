import React from "react";
import Video from "../../main/Video";
import BackButton from "../../main/BackButton";

const meaningOfWonwonleywonName = () => {
  return (
    <>
      <BackButton url="/allvideos" />
      <Video
        url={`https://yuriy-dmytrash19.wistia.com/medias/hqd4nn1gjb`}
        maxHeight="80%"
      />
    </>
  );
};

export default meaningOfWonwonleywonName;
