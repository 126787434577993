import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import classes from "./css/Home.module.css";
import Line from "../main/Line";
import BackButton from '../main/BackButton';

class WorldOfWonley extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Line type="bottom-line">
          <NavLink
            to="/world/oneofoneluxury"
            className={classes.navLink}
          >
            One of One Luxury
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/world/mission" className={classes.navLink}>
            Mission
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/world/meaning" className={classes.navLink}>
            Meaning of wonwonleywon Name
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/world/gifts" className={classes.navLink}>
            gifts
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/world/episodes" className={classes.navLink}>
            content
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/world/fundraiser" className={classes.navLink}>
            Fundraisers
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/world/support" className={classes.navLink}>
            Support
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/world/products" className={classes.navLink}>
            Products
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink
            to="/world/purchasingproduct"
            className={classes.navLink}
          >
            Purchasing a Product
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink
            to="/world/authentification"
            className={classes.navLink}
          >
            authenticity
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/world/membership" className={classes.navLink}>
            Membership
          </NavLink>
        </Line>
        <Line>
          <NavLink to="/world/followus" className={classes.navLink}>
            Follow us
          </NavLink>
        </Line>
      </>
    );
  }
}
export default WorldOfWonley;