import React, { useState } from 'react';
import styles from './css/Selector.module.css';
import { BsChevronDown } from 'react-icons/bs';
import Line from './Line';

export default function Selector(props) {
    const [isShow, setIsShow] = useState(false);
    const [selectedSize, setSelectedSize] = useState('');
    return (
        <>
            <Line type="empty-line" className={styles.title} onClick={() => { setIsShow(!isShow) }}>
                <div>
                    {props.title}
                    <label style={{ marginLeft: "2rem" }}>

                        {selectedSize}
                    </label>
                </div>
                <div>&nbsp;</div>
                <BsChevronDown className={isShow ? styles.turnArrow : ""} />
            </Line>
            <div className={styles.selectorContainer} style={{ maxHeight: isShow ? "300vh" : "0" }}>
                {
                    props.list.map(
                        item => {
                            // if (props.soldOut && item.soldout) {
                            //     return <Line type="bottom-line" className={styles.line + ' ' + styles.soldOut} key={item.id}>
                            //         {item.text}
                            //     </Line>
                            // } else {
                            return <Line type="bottom-line" className={styles.line} key={item.sizeId}
                                onClick={() => {
                                    setSelectedSize(item.size);
                                    props.selectFunction(item);
                                    setIsShow(false)
                                }}>
                                {item.size}
                            </Line>
                            // }
                        })
                }
            </div>

        </>
    )
}
