import React from 'react'
import styles from './css/RadioButton.module.css';

export default function RadioButton(props) {
    return (
        <div className={styles.radLabel} onClick={props.onClick}>
            <input type="radio" className={styles.radInput} name={props.name ? props.name : "rad"} checked={props.checked} />
            <div className={styles.radDesign}></div>
            <div className={styles.radText}>{props.children}</div>
        </div>
    )
}
