import React, { useEffect, useState } from "react";
import axios from "../../../axios-backend";
import BackButton from "../../main/BackButton";
import ClickHere from "../../main/ClickHere";
import Line from "../../main/Line";
import classes from "../css/Authentication.module.css";

export default function Transfer(props) {
  const md5 = require("md5");
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const clickHandle = () => {
    const times = sessionStorage["transferTry"];
    if (times > 3) {
      alert(`sorry, you have tried too many times today. please try tomorrow.`);
      return;
    }
    const inputValidation = email && pwd;

    const validate = (email) => {
      const expression =
        /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

      return expression.test(String(email).toLowerCase());
    };

    if (!validate(email) || !inputValidation) {
      window.alert("please input correct information");
      return;
    } else {
      const result = window.confirm(
        `are you sure you want to transfer ownership of this product to ${email}? if you transfer ownership, you will no longer be the owner of this product.`
      );
      if (result) {
        axios
          .post("/transfer-auth", {
            cardNumber: sessionStorage["cardNumber"],
            pid: sessionStorage["transfer"],
            email: email.trim(),
            pwd: md5(pwd.trim()),
          })
          .then((data) => {
            if (data.data.status === 200) {
              sessionStorage.removeItem("cardNumber");
              sessionStorage.removeItem("transfer");
              sessionStorage.removeItem("password");
              sessionStorage.removeItem("isAuth");
              props.history.push("/authenticity/finish");
            } else if (data.data.status === 400) {
              alert("Transfer declined. Check the password");
            } else {
              sessionStorage["transferTry"] = times ? parseInt(times) + 1 : 1;
            }
          });
      }
    }
  };
  useEffect(() => {
    if (!sessionStorage["isAuth"]) {
      props.history.push("/home");
    }
  }, []);
  return (
    <>
      <BackButton />
      <Line type="empty-line">
        <span className={classes.span}>Enter your password here</span>
      </Line>
      <Line type="bottom-line">
        <input
          type="text"
          maxLength="16"
          className={classes.textbox}
          value={pwd}
          onChange={(e) => {
            setPwd(e.target.value);
          }}
        />
      </Line>
      <Line type="empty-line">
        <span className={classes.span}>Enter email of new owner here</span>
      </Line>
      <Line type="bottom-line">
        <input
          type="text"
          className={classes.textbox}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line" />
      <Line type="bottom-line">
        confirm <ClickHere onClick={clickHandle} />
      </Line>
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
    </>
  );
}
