import React, { useState, useEffect } from 'react';
import BackButton from '../../main/BackButton';
import { NavLink } from "react-router-dom";
import classes from "../css/Home.module.css";
import Line from "../../main/Line";
import SizeGuide from './SizeGuide/SizeGuide';
import axios from '../../../axios-backend';
import SizeFinder from './SizeFinder/SizeFinder'

export default function ProductSizeGuide(props) {
    const { type, gender } = props.location.state;

    const [showSizeFinder, setShowSizeFinder] = useState(false);
    const [showSizeGuide, setShowSizeGuide] = useState(false);
    const [sizeList, setSizeList] = useState([]);

    useEffect(() => {
        axios.get(`/sizes-by-type`, { params: { 'tid': type, gender } }).then(data => {
            if (data.data.status === 200) {
                if (data.data.list.length === 0) {
                    alert('sorry, no product');
                    props.history.goBack();
                }
                else {
                    console.log(data.data.list)
                    setSizeList(data.data.list)
                }
            }
        });
    }, [])

    return (
        <>
            <BackButton />
            <Line type="bottom-line">
                <label className={classes.navLink} style={{ cursor: "pointer" }} onClick={() => { setShowSizeFinder(true); }}>
                    size finder
                </label>
            </Line>
            <Line type="bottom-line">
                <label className={classes.navLink} style={{ cursor: "pointer" }} onClick={() => { setShowSizeGuide(true); }}>
                    size guide
                </label>
            </Line>
            <Line type="bottom-line">
                <NavLink to={{ pathname: "/products/measure", state: { type, gender } }}
                    className={classes.navLink}>
                    how to measure
                </NavLink>
            </Line>
            <Line type="bottom-line">
                <NavLink to={"/products/questions"} className={classes.navLink}>
                    questions? contact us
                </NavLink>
            </Line>
            <Line type="bottom-line"></Line>
            <Line type="bottom-line"></Line>
            <Line type="bottom-line"></Line>
            <Line type="bottom-line"></Line>
            <Line type="bottom-line"></Line>
            <Line type="bottom-line"></Line>
            <Line type="bottom-line"></Line>
            <SizeFinder show={showSizeFinder} gender={gender} tid={type} closeFun={() => {
                setShowSizeFinder(false);
            }} />
            <SizeGuide show={showSizeGuide} gender={gender} sizeList={sizeList} closeFun={() => {
                setShowSizeGuide(false)
            }} />
        </>
    )
}


