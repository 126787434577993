import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../../axios-backend";
import Line from "../main/Line";
import Button from "../main/Button";
import BackButton from "../main/BackButton";
import Columns from "../main/Columns";

const ViewTypes = (props) => {
  const [types, setTypes] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios.get("/types").then((t) => {
      setTypes(t.data.list);
    });
  };

  const handleRedirect = () => {
    props.history.push("/adtype");
  };

  const handleDelete = (id) => {
    const result = window.confirm("Are you sure you want to delete this type?");
    if (result) {
      axios.delete("/types", { data: { id } }).then((data) => {
        if (data.data.status === 200) getData();
        else alert("Error");
      });
    }
  };

  const renderTypes = () => {
    if (types.length > 0) {
      return types.map((t) => {
        return (
          <Line type="bottom-line" key={t.id}>
            <Columns width="15%">{t.name}</Columns>
            <Columns width="5%">{t.areaAmount}</Columns>
            {/* <Columns width="25%">
                            {t.gender === 0 ? "female" : t.gender === 1 ? "male" : "unisex"}
                        </Columns> */}
            <Columns width="80%">
              <Link to={`/adtype?id=${t.id}`} style={{ marginRight: "1rem" }}>
                <Button>Update</Button>
              </Link>
              <Button onClick={() => handleDelete(t.id)}>Delete</Button>
              <Link
                to={`/view-size-finders/?tid=${t.id}`}
                style={{ marginLeft: "1rem" }}
              >
                <Button>Size Finder</Button>
              </Link>
              <Link
                to={`/ad-type-video?id=${t.id}`}
                style={{ marginLeft: "1rem" }}
              >
                <Button> Upload Videos</Button>
              </Link>
              <Link
                to={`/view-dimensions/${t.id}`}
                style={{ marginLeft: "1rem" }}
              >
                <Button> Product Dimensions</Button>
              </Link>
              <Link
                to={`/ad-shippings/${t.id}`}
                style={{ marginLeft: "1rem" }}
              >
                <Button> Shipping Price</Button>
              </Link>
            </Columns>
          </Line>
        );
      });
    } else
      return (
        <Line type="bottom-line" style={{ justifyContent: "center" }}>
          loading...
        </Line>
      );
  };

  return (
    <div style={{ height: "100%" }}>
      <BackButton />
      <Line type="bottom-line">
        <Columns width="15%">collection name</Columns>
        <Columns width="5%">area number</Columns>
        {/* <Columns width="25%">gender</Columns> */}
        <Columns width="80%">
          <Button onClick={handleRedirect}>Add new collection</Button>
        </Columns>
      </Line>
      {renderTypes()}
    </div>
  );
};

export default ViewTypes;
