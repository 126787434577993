import React, { useState } from 'react';
import SelectionLine from './SelectionLine';
import ButtonsLine from './ButtonsLine';


export default function SingleSelection(props) {
    const [selected, setSelected] = useState();
    const { shortName, sIndex, options } = props.step;
    const changeAnswer = (val) => {
        const temp = JSON.parse(JSON.stringify(props.answer));
        temp[sIndex] = { shortName, val };
        props.setAnswer(temp);
    }
    return (
        <>
            {options.map((item, index) => {
                return <SelectionLine
                    key={index} item={item} selected={selected}
                    setSelected={() => {
                        setSelected(item.index);
                        changeAnswer(item.text)
                    }} />
            })}
            <ButtonsLine nextStep={props.nextStep} preStep={props.preStep}
                skipStep={() => {
                    changeAnswer('skip');
                    props.nextStep();
                }}
            />
        </>
    )
}
