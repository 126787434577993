import React, { Component } from 'react';
import BackButton from '../main/BackButton';
import { NavLink } from 'react-router-dom';
import classes from "./css/Home.module.css";
import Line from "../main/Line";

class Boutique extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Line type="bottom-line">
          <NavLink to={{ pathname: "/boutique/twoboutiques", state: { type: "digital" } }} className={classes.navLink}>
            digital boutique
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to={{ pathname: "/boutique/twoboutiques", state: { type: "physical" } }} className={classes.navLink}>
            physical boutique
          </NavLink>
        </Line>
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
      </>
    );
  }
}
export default Boutique;