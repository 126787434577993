import React from 'react';
import Text from './Text'

const NotFound = () => {
    return (
        <div style={{ height: "100%" }}>
            <Text>
                Sorry your page is not found.
            </Text>
        </div>
    )
}

export default NotFound;