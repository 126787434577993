import React, { useEffect, useState } from 'react';
import Line from '../../../main/Line';
import styles from './css/QuestionContainer.module.css';
import SingleSelection from './SingleSelection';
import InsertValue from './InsertValue';
import ThreeOptions from './ThreeOptions';
import EmailBox from './EmailBox';
import InsertInchCM from './InsertInchCM';

export default function QuestionContainer(props) {
    const [answer, setAnswer] = useState({});
    const [step, setStep] = useState({});
    useEffect(() => {
        if (props.list) {
            setStep(props.list[props.currentStep - 1]);
        }
    }, [props.currentStep])
    return (
        <>
            <Line type="bottom-line" className={styles.questionBar}>
                {step.description}
            </Line>
            {
                step.type === 1 ? <SingleSelection step={step} answer={answer} setAnswer={setAnswer} nextStep={props.nextStep} preStep={props.preStep} /> : null
            }
            {
                step.type === 2 ? <InsertValue step={step} answer={answer} setAnswer={setAnswer} nextStep={props.nextStep} preStep={props.preStep} /> : null
            }
            {
                step.type === 3 || step.type === 6 ? <ThreeOptions step={step} answer={answer} setAnswer={setAnswer} nextStep={props.nextStep} preStep={props.preStep} /> : null
            }
            {
                step.type === 4 ? <EmailBox tid={props.tid} step={step} answer={answer} setAnswer={setAnswer} nextStep={props.nextStep} preStep={props.preStep} /> : null
            }
            {
                step.type === 5 ? <InsertInchCM step={step} answer={answer} setAnswer={setAnswer} nextStep={props.nextStep} preStep={props.preStep} /> : null
            }
        </>
    )
}
