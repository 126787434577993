import React, { Component } from "react";
import BackButton from "../../main/BackButton";
import Text from "../../main/Text";

class DeliveryTime extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Text>
        delivery time for all products will take approximately 7-11 business days if shipping to canada or the usa. approximately 10-14 business days if shipping outside of canada or the usa. if you need products to arrive to you faster, email us at support@wonwonleywon.com, and we can potentially get them to you faster than the above stated times.
        </Text>
      </>
    );
  }
}
export default DeliveryTime;
