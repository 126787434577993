import React from 'react';
import Video from "../../main/Video";
import BackButton from '../../main/BackButton';


const MeaningOfDesign = (props) => {
    const { videoUrl } = props.location.state;
    return (
        <>
            <BackButton />
            <Video url={`${videoUrl}`} maxHeight="80%" />
        </>
    );
}
export default MeaningOfDesign;

