import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "../../../axios-backend";
import BackButton from "../../main/BackButton";
import Line from "../../main/Line";
import Text from "../../main/Text";
import classes from "../css/Home.module.css";

const ProductRelated = (props) => {
  const { type, areaAmount, gender } = props.location.state;
  const [hasSize, setHasSize] = useState(false);
  const [loading, setLoading] = useState(true);
  const [meaningVideo, setMeaningVideo] = useState();
  const [typeDetails, setTypeDetails] = useState();
  useEffect(() => {
    setLoading(true);
    axios
      .get(`/has-sizes`, {
        params: {
          id: type,
        },
      })
      .then((data) => {
        if (data.data.status === 200 && data.data.sizes.length) {
          setHasSize(true);
          if (data.data.sizes[0].menMeaning) {
            if (gender == "0") {
              setMeaningVideo(data.data.sizes[0].womenMeaning);
            } else {
              setMeaningVideo(data.data.sizes[0].menMeaning);
            }
          }
        }
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, []);
  useEffect(() => {
    setLoading(true);
    if (type !== "") {
      axios
        .get(`/type?id=${type}`)
        .then((data) => {
          if (data?.data?.type) setTypeDetails(data?.data?.type);
          setLoading(false);
        })
        .catch((err) => setLoading(false));
    }
  }, [type]);

  // if it is preSale dont render anything else
  if (typeDetails?.preSale) {
    return (
      <>
        <BackButton />
        <Text>{typeDetails?.preSaleText}</Text>
      </>
    );
  }

  return !loading ? (
    <>
      <BackButton />

      {typeDetails?.issoldout && <Line type="bottom-line">all products in this collection are sold.</Line>}

      <Line type="bottom-line">
        <NavLink to={{ pathname: "/products/productvideo", state: { type, gender } }} className={classes.navLink}>
          Collection Video
        </NavLink>
      </Line>
      {meaningVideo ? (
        <Line type="bottom-line">
          <NavLink
            to={{
              pathname: "/products/meaning-of-design",
              state: { videoUrl: meaningVideo },
            }}
            className={classes.navLink}
          >
            meaning of collection design
          </NavLink>
        </Line>
      ) : null}

      {!typeDetails?.issoldout && (
        <Line type="bottom-line">
          {hasSize ? (
            <NavLink
              to={{
                pathname: "/products/productsizeguide",
                state: { type, gender },
              }}
              className={classes.navLink}
            >
              Find your size
            </NavLink>
          ) : (
            <NavLink
              to={{
                pathname: "/products/material",
                state: { tid: type, title: "dimensionBefore" },
              }}
              className={classes.navLink}
            >
              product dimensions
            </NavLink>
          )}
        </Line>
      )}
      <Line type="bottom-line">
        <NavLink
          to={{
            pathname: "/products/material",
            state: { tid: type, title: "unisexPrice" },
          }}
          className={classes.navLink}
        >
          product price
        </NavLink>
      </Line>
      {!typeDetails?.issoldout && (
        <Line type="bottom-line">
          {hasSize ? (
            <NavLink
              to={{
                pathname: "/products/sizepicker",
                state: { areaAmount, type, gender },
              }}
              className={classes.navLink}
            >
              Shop Products - pick size
            </NavLink>
          ) : (
            <NavLink
              to={{
                pathname: "/products/shopproduct",
                state: { areaAmount, type, gender },
              }}
              className={classes.navLink}
            >
              Shop Products - pick colors
            </NavLink>
          )}
        </Line>
      )}

      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      {!meaningVideo ? <Line type="bottom-line" /> : null}
      {typeDetails?.issoldout ? <Line type="bottom-line" /> : null}
      <Line />
    </>
  ) : (
    <>
      <BackButton />
    </>
  );
};
export default ProductRelated;
