import React, { Component } from "react";
import classes from "./css/Line.module.css";

class Line extends Component {
  returnClassNames = (className) => {
    if (this.props.className) {
      return className + " " + this.props.className;
    } else {
      return className;
    }
  }
  render() {
    if (this.props.type === "bottom-line") {
      return (
        <div className={this.returnClassNames(classes.bottomLine)} style={{ ...this.props.style }} onClick={this.props.onClick}>
          {this.props.children}
        </div>)
    } else if (this.props.type === "top-line") {
      return (
        <div className={this.returnClassNames(classes.topLine)} style={{ ...this.props.style }} onClick={this.props.onClick}>
          {this.props.children}
        </div>
      )
    }
    else return (
      <div className={this.returnClassNames(classes.emptyLine)} style={{ ...this.props.style }} onClick={this.props.onClick}>
        {this.props.children}
      </div>)
  }
}


export default Line;
