import React, { useState, useEffect } from 'react'
import Text from "../../main/Text";
import BackButton from "../../main/BackButton";

export default function TwoBoutique(props) {
    const [content, setContent] = useState('');
    const { type } = props.location.state;
    useEffect(() => {
        if (type === 'digital') {
            setContent('this website is our digital boutique.')
        } else {
            setContent('currently we have no physical boutique.')
        }
    }, [])

    return (
        <>
            <BackButton />
            <Text>
                {content}
            </Text>
        </>
    )
}
