import React, { useEffect, useState } from 'react';
import classes from './css/ColorPicker.module.css';
import Line from '../../main/Line'
import axios from '../../../axios-backend';

const ColorPicker = (props) => {
    const { colorCombination, gender, type, size,areaAmount } = props;
    const [colors, setColors] = useState({});
    const colorLines = ["black", "blue", "brown", "green", "grey", "orange", "pink", "purple", "red", "white", "yellow"];
    useEffect(() => {
        let params = {};
        if (colorCombination.length === 0) {
            params = { gender, index: 0, typeId: type, size };
        } else {
            params = { gender, index: colorCombination.length, typeId: type, colorComb: colorCombination.join(','), size }
        }
        axios.get('/getNextColor/', {
            params
        }).then(data => {
            if (data.data.status === 200) {
                const tempColors = {};
                data.data.colors.map(e => {
                    tempColors[e.color] = true;
                });
                setColors({ ...tempColors });
            }
        });
    }, [colorCombination]);

    return (
        <>
            <Line className={classes.centerText}>
                Pick Color {`(${colorCombination.length + 1} of ${areaAmount})`}
            </Line>
            {colorLines.map(
                val => {
                    return (
                        <Line key={val}
                            className={[classes[val], classes.basicLine].join(' ')}
                            onClick={() => {
                                if (colors[val]) {
                                    props.onChoseColor([...colorCombination, val])
                                }
                            }}>
                            <span
                                style={{ textDecoration: colors[val] ? "" : "line-through" }}
                            >{val}</span>
                        </Line>)
                }
            )}
        </>
    );
}

export default ColorPicker;