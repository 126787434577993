import React, { Component } from 'react';
import classes from "../home/css/Head.module.css";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';



class Head extends Component {

  render() {
    return (
      <div className={classes.head}>
        <Link to="/home">
          <img src={logo} alt="Logo" className={classes.logo} />
        </Link>
        {this.props.location.pathname === '/' ? null :
          <span className={classes.description}>
            the most exclusive luxury brand in the world, with a philanthropic
            mission
          </span>}
      </div>
    )
  }
}
export default withRouter(Head);

