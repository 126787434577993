import React, { Component } from 'react';
import Text from "../../main/Text";
import BackButton from '../../main/BackButton';


class PaymentMethods extends Component {
  render() {
    return (
      <>

        <BackButton />

        <Text>you can purchase our products using any of the following debit or credit cards: visa, mastercard, american express, china unionpay, japan credit bureau, discover, diners club, maestro. you can also purchase our products using paypal.

        </Text>

      </>
    );
  }
}
export default PaymentMethods;

