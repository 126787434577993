import * as actionTypes from './actions';

const initialState = {
    user: ''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USERLOGIN:
            return {
                ...state,
                user: action.username
            };
        default:

            break;
    }
    return state;
}

export default reducer;