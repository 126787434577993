import React from 'react'

export default function Countries() {
    return (
        <>
            <option value="-"></option>
            <option value="1-Afghanistan">Afghanistan</option>,
            <option value="2-Albania">Albania</option>,
            <option value="3-Algeria">Algeria</option>,
            <option value="4-American Samoa">American Samoa</option>,
            <option value="5-Andorra">Andorra</option>,
            <option value="6-Angola">Angola</option>,
            <option value="7-Anguilla">Anguilla</option>,
            <option value="8-Antigua and Barbuda">Antigua and Barbuda</option>,
            <option value="9-Argentina">Argentina</option>,
            <option value="10-Armenia">Armenia</option>,
            <option value="11-Aruba">Aruba</option>,
            <option value="12-Australia">Australia</option>,
            <option value="13-Austria">Austria</option>,
            <option value="14-Azerbaijan">Azerbaijan</option>,
            <option value="15-Bahamas">Bahamas</option>,
            <option value="16-Bahrain">Bahrain</option>,
            <option value="17-Bangladesh">Bangladesh</option>,
            <option value="18-Barbados">Barbados</option>,
            <option value="19-Belarus">Belarus</option>,
            <option value="20-Belgium">Belgium</option>,
            <option value="21-Belize">Belize</option>,
            <option value="22-Benin">Benin</option>,
            <option value="23-Bermuda">Bermuda</option>,
            <option value="24-Bhutan">Bhutan</option>,
            <option value="25-Bolivia">Bolivia</option>,
            <option value="26-Bosnia and Herzegovina">Bosnia and Herzegovina</option>,
            <option value="27-Botswana">Botswana</option>,
            <option value="28-Brazil">Brazil</option>,
            <option value="29-Bulgaria">Bulgaria</option>,
            <option value="30-Burkina Faso">Burkina Faso</option>,
            <option value="31-Burundi">Burundi</option>,
            <option value="32-Cambodia">Cambodia</option>,
            <option value="33-Cameroon">Cameroon</option>,
            <option value="34-Canada">Canada</option>,
            <option value="35-Cape Verde">Cape Verde</option>,
            <option value="36-Cayman Islands">Cayman Islands</option>,
            <option value="37-Central African Republic">Central African Republic</option>,
            <option value="38-Chad">Chad</option>,
            <option value="39-Chile">Chile</option>,
            <option value="40-China">China</option>,
            <option value="41-Christmas Island (Australia)">Christmas Island (Australia)</option>,
            <option value="42-Colombia">Colombia</option>,
            <option value="43-Comoros">Comoros</option>,
            <option value="44-Congo">Congo</option>,
            <option value="45-Congo, the Democratic Republic of the">Congo, the Democratic Republic of the</option>,
            <option value="46-Cook Islands">Cook Islands</option>,
            <option value="47-Costa Rica">Costa Rica</option>,
            <option value="48-Cote D'Ivoire">Cote D'Ivoire</option>,
            <option value="49-Croatia">Croatia</option>,
            <option value="50-Cuba">Cuba</option>,
            <option value="51-Cyprus">Cyprus</option>,
            <option value="52-Czech Republic">Czech Republic</option>,
            <option value="53-Denmark">Denmark</option>,
            <option value="54-Djibouti">Djibouti</option>,
            <option value="55-Dominica">Dominica</option>,
            <option value="56-Dominican Republic">Dominican Republic</option>,
            <option value="57-Ecuador">Ecuador</option>,
            <option value="58-Egypt">Egypt</option>,
            <option value="59-El Salvador">El Salvador</option>,
            <option value="60-Eritrea">Eritrea</option>,
            <option value="61-Estonia">Estonia</option>,
            <option value="62-Ethiopia">Ethiopia</option>,
            <option value="63-Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>,
            <option value="64-Faroe Islands">Faroe Islands</option>,
            <option value="65-Fiji">Fiji</option>,
            <option value="66-Finland">Finland</option>,
            <option value="67-France">France</option>,
            <option value="68-Gabon">Gabon</option>,
            <option value="69-Gambia">Gambia</option>,
            <option value="70-Georgia">Georgia</option>,
            <option value="71-Germany">Germany</option>,
            <option value="72-Ghana">Ghana</option>,
            <option value="73-Gibraltar">Gibraltar</option>,
            <option value="74-Greece">Greece</option>,
            <option value="75-Greenland">Greenland</option>,
            <option value="76-Grenada">Grenada</option>,
            <option value="77-Guadeloupe">Guadeloupe</option>,
            <option value="78-Guam">Guam</option>,
            <option value="79-Guatemala">Guatemala</option>,
            <option value="80-Guinea">Guinea</option>,
            <option value="81-Guinea-Bissau">Guinea-Bissau</option>,
            <option value="82-Guyana (British)">Guyana (British)</option>,
            <option value="83-Guyana (French)">Guyana (French)</option>,
            <option value="84-Haiti">Haiti</option>,
            <option value="85-Holy See (Vatican City State)">Holy See (Vatican City State)</option>,
            <option value="86-Honduras">Honduras</option>,
            <option value="87-Hong Kong">Hong Kong</option>,
            <option value="88-Hungary">Hungary</option>,
            <option value="89-Iceland">Iceland</option>,
            <option value="90-India">India</option>,
            <option value="91-Indonesia">Indonesia</option>,
            <option value="92-Iran, Islamic Republic of">Iran, Islamic Republic of</option>,
            <option value="93-Iraq">Iraq</option>,
            <option value="94-Ireland, Republic Of">Ireland, Republic Of</option>,
            <option value="95-Israel">Israel</option>,
            <option value="96-Italy">Italy</option>,
            <option value="97-Jamaica">Jamaica</option>,
            <option value="98-Japan">Japan</option>,
            <option value="99-Jordan">Jordan</option>,
            <option value="100-Kazakhstan">Kazakhstan</option>,
            <option value="101-Kenya">Kenya</option>,
            <option value="102-Kiribati">Kiribati</option>,
            <option value="103-Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>,
            <option value="104-Korea, Republic of">Korea, Republic of</option>,
            <option value="105-Kuwait">Kuwait</option>,
            <option value="106-Kyrgyzstan">Kyrgyzstan</option>,
            <option value="107-Lao People's Democratic Republic">Lao People's Democratic Republic</option>,
            <option value="108-Latvia">Latvia</option>,
            <option value="109-Lebanon">Lebanon</option>,
            <option value="110-Lesotho">Lesotho</option>,
            <option value="111-Liberia">Liberia</option>,
            <option value="112-Libya">Libya</option>,
            <option value="113-Liechtenstein">Liechtenstein</option>,
            <option value="114-Lithuania">Lithuania</option>,
            <option value="115-Luxembourg">Luxembourg</option>,
            <option value="116-Macao">Macao</option>,
            <option value="117-Macedonia, Republic of">Macedonia, Republic of</option>,
            <option value="118-Madagascar">Madagascar</option>,
            <option value="119-Malawi">Malawi</option>,
            <option value="120-Malaysia">Malaysia</option>,
            <option value="121-Maldives">Maldives</option>,
            <option value="122-Mali">Mali</option>,
            <option value="123-Malta">Malta</option>,
            <option value="124-Marshall Islands">Marshall Islands</option>,
            <option value="125-Martinique">Martinique</option>,
            <option value="126-Mauritania">Mauritania</option>,
            <option value="127-Mauritius">Mauritius</option>,
            <option value="128-Mayotte">Mayotte</option>,
            <option value="129-Mexico">Mexico</option>,
            <option value="130-Micronesia, Federated States of">Micronesia, Federated States of</option>,
            <option value="131-Moldova, Republic of">Moldova, Republic of</option>,
            <option value="132-Monaco">Monaco</option>,
            <option value="133-Mongolia">Mongolia</option>,
            <option value="134-Montserrat">Montserrat</option>,
            <option value="135-Morocco">Morocco</option>,
            <option value="136-Mozambique">Mozambique</option>,
            <option value="137-Myanmar">Myanmar</option>,
            <option value="138-Namibia">Namibia</option>,
            <option value="139-Nauru">Nauru</option>,
            <option value="140-Nepal">Nepal</option>,
            <option value="141-Netherlands">Netherlands</option>,
            <option value="142-Netherlands Antilles">Netherlands Antilles</option>,
            <option value="143-New Caledonia">New Caledonia</option>,
            <option value="144-New Zealand">New Zealand</option>,
            <option value="145-Nicaragua">Nicaragua</option>,
            <option value="146-Niger">Niger</option>,
            <option value="147-Nigeria">Nigeria</option>,
            <option value="148-Niue">Niue</option>,
            <option value="149-Norfolk Island">Norfolk Island</option>,
            <option value="150-Norway">Norway</option>,
            <option value="151-Oman">Oman</option>,
            <option value="152-Pakistan">Pakistan</option>,
            <option value="153-Palau">Palau</option>,
            <option value="154-Panama">Panama</option>,
            <option value="155-Papua New Guinea">Papua New Guinea</option>,
            <option value="156-Paraguay">Paraguay</option>,
            <option value="157-Peru">Peru</option>,
            <option value="158-Philippines">Philippines</option>,
            <option value="159-Poland">Poland</option>,
            <option value="160-Portugal">Portugal</option>,
            <option value="161-Puerto Rico">Puerto Rico</option>,
            <option value="162-Qatar">Qatar</option>,
            <option value="163-Reunion, Island Of">Reunion, Island Of</option>,
            <option value="164-Romania">Romania</option>,
            <option value="165-Russian Federation">Russian Federation</option>,
            <option value="166-Rwanda">Rwanda</option>,
            <option value="167-Saint Helena">Saint Helena</option>,
            <option value="168-Saint Kitts and Nevis">Saint Kitts and Nevis</option>,
            <option value="169-Saint Lucia">Saint Lucia</option>,
            <option value="170-Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>,
            <option value="171-Samoa">Samoa</option>,
            <option value="172-San Marino">San Marino</option>,
            <option value="173-Sao Tome and Principe">Sao Tome and Principe</option>,
            <option value="174-Saudi Arabia">Saudi Arabia</option>,
            <option value="175-Senegal">Senegal</option>,
            <option value="176-Serbia">Serbia</option>,
            <option value="177-Montenegro">Montenegro</option>,
            <option value="178-Seychelles">Seychelles</option>,
            <option value="179-Sierra Leone">Sierra Leone</option>,
            <option value="180-Singapore">Singapore</option>,
            <option value="181-Slovakia">Slovakia</option>,
            <option value="182-Slovenia">Slovenia</option>,
            <option value="183-Solomon Islands">Solomon Islands</option>,
            <option value="184-Somalia">Somalia</option>,
            <option value="185-South Africa">South Africa</option>,
            <option value="186-Spain">Spain</option>,
            <option value="187-Sri Lanka">Sri Lanka</option>,
            <option value="188-Sudan">Sudan</option>,
            <option value="189-Suriname">Suriname</option>,
            <option value="190-Swaziland">Swaziland</option>,
            <option value="191-Sweden">Sweden</option>,
            <option value="192-Switzerland">Switzerland</option>,
            <option value="193-Syria">Syria</option>,
            <option value="194-Taiwan">Taiwan</option>,
            <option value="195-Tajikistan">Tajikistan</option>,
            <option value="196-Tanzania">Tanzania</option>,
            <option value="197-Thailand">Thailand</option>,
            <option value="198-Timor-Leste">Timor-Leste</option>,
            <option value="199-Togo">Togo</option>,
            <option value="200-Tokelau">Tokelau</option>,
            <option value="201-Tonga">Tonga</option>,
            <option value="202-Trinidad and Tobago">Trinidad and Tobago</option>,
            <option value="203-Tunisia">Tunisia</option>,
            <option value="204-Turkey">Turkey</option>,
            <option value="205-Turkmenistan">Turkmenistan</option>,
            <option value="206-Turks and Caicos Islands">Turks and Caicos Islands</option>,
            <option value="207-Tuvalu">Tuvalu</option>,
            <option value="208-Uganda">Uganda</option>,
            <option value="209-Ukraine">Ukraine</option>,
            <option value="210-United Arab Emirates">United Arab Emirates</option>,
            <option value="211-United Kingdom">United Kingdom</option>,
            <option value="212-United States">United States</option>,
            <option value="213-Uruguay">Uruguay</option>,
            <option value="214-Uzbekistan">Uzbekistan</option>,
            <option value="215-Vanuatu">Vanuatu</option>,
            <option value="216-Venezuela">Venezuela</option>,
            <option value="217-Vietnam">Vietnam</option>,
            <option value="218-Virgin Islands, British">Virgin Islands, British</option>,
            <option value="219-Virgin Islands, U.S.">Virgin Islands, U.S.</option>,
            <option value="220-Yemen">Yemen</option>,
            <option value="221-Zambia">Zambia</option>,
            <option value="222-Zimbabwe">Zimbabwe</option>
        </>
    )
}
