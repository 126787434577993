import React from 'react';
import styles from './css/Columns.module.css';

export default function Columns(props) {
    return (
        <div className={styles.Columns} style={{ width: props.width }}>
            {props.children}
        </div>
    )
}
