import React from "react";
import BackButton from "../../../main/BackButton";
import Text from "../../../main/Text";

export default function Lyrics() {
  return (
    <>
      <BackButton />
      <Text>
        song name: bethebestyou <br />
        artist: wonwonleywon
        <br />
        <br />
        we are all one of one
        <br />
        no one is you
        <br />
        that is your power
        <br />
        so <br />
        be the best you
        <br />
        <br /> wonwonleywon wonwonleywon won won wonwonleywon wonwonleywon won
        won wonwonleywon wonwonleywon won won wonwonleywon wonwonleywon. be the
        best you be the be the be the best you be the best you be the be the be
        the best you be the best you be the be the be the best you be the best
        you be the best you
        <br />
        <br />i am just a human
        <br />
        but that makes me unique
        <br />
        being one of one
        <br />
        sometimes i feel weak
        <br />
        <br />
        i know where i came from
        <br />
        but where will i go
        <br />
        invest in myself
        <br />
        that’s what I know
        <br />
        <br />
        i will start each day
        <br />
        with a grateful heart
        <br /> always knowing tomorrow
        <br />
        can be a new start
        <br />
        <br />
        the past can’t be changed
        <br />
        the futures in my power
        <br />
        this is my journey
        <br />
        i’ll bloom like a flower
        <br />
        <br />
        if i don’t enjoy my path
        <br /> i’ll take another one
        <br />
        i’ll flow along that route
        <br /> until i feel done
        <br />
        <br />
        i'm not afraid to fail
        <br />
        i'm afraid not to try
        <br />
        so i’ll live life with passion
        <br />
        until i say goodbye
        <br />
        <br />
        so to be the best me
        <br />i may have to be patient
        <br />
        work on the mind body knowledge <br />
        and get entertainment
        <br />
        <br />
        the sky’s not the limit
        <br />
        when there’s footprints on the moon
        <br />
        as i work on my goals
        <br />
        this is my tune
        <br />
        <br />
        wonwonleywon wonwonleywon won won wonwonleywon wonwonleywon won won
        wonwonleywon wonwonleywon won won wonwonleywon wonwonleywon. be the best
        you be the be the be the best you be the best you be the be the be the
        best you be the best you be the be the be the best you be the best you
        be the best you
        <br />
        <br />
        i may only be the best me
        <br />
        with the help of others so…
        <br />
        if i help him
        <br />
        and he helps her
        <br />
        and she helps them
        <br />
        and they help me
        <br />
        we can work together like family
        <br />
        we can help inspire a community
        <br /> so together we can be the best that we can be
        <br />
        love and light around the world is what I want to see
        <br />
        <br />
        be who
        <br />
        be you
        <br />
        the treasure is in you
        <br />
        <br />
        but if you’re not the best you
        <br />
        <br />
        stop
        <br />
        think
        <br />
        change
        <br />
        <br />
        wonwonleywon wonwonleywon won won wonwonleywon wonwonleywon won won
        wonwonleywon wonwonleywon won won wonwonleywon wonwonleywon. be the best
        you be the be the be the best you be the best you be the be the be the
        best you be the best you be the be the be the best you be the best you
        be the best you
      </Text>
    </>
  );
}
