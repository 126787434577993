import React, { Component } from 'react';
import BackButton from '../../main/BackButton';
import Text from "../../main/Text";

class ChangeCountryCurrency extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Text>
          you cannot change the country to see prices in different currencies, and all prices are only displayed and billed in usd. if you want to know the price of our products in a different currency, email us at <a href="mailto:support@wonwonleywon.com" style={{ color: "black" }}>support@wonwonleywon.com</a>

        </Text>
      </>
    );
  }
}
export default ChangeCountryCurrency;

