export const canadianTaxes = {
    "Alberta": {
      gst: 5,
      pst: 0,
      qst: 0,
      hst: 0,
      total: 5,
    },
    "British Columbia": {
      gst: 5,
      pst: 7,
      qst: 0,
      hst: 0,
      total: 12,
    },
    "Manitoba": {
      gst: 5,
      pst: 7,
      qst: 0,
      hst: 0,
      total: 12,
    },
    "New Brunswick": {
      gst: 0,
      pst: 0,
      qst: 0,
      hst: 15,
      total: 15,
    },
    "Newfoundland and Labrador": {
      gst: 0,
      pst: 0,
      qst: 0,
      hst: 15,
      total: 15,
    },
    "Northwest Territories": {
      gst: 5,
      pst: 0,
      qst: 0,
      hst: 0,
      total: 5,
    },
    "Nunavut": {
      gst: 5,
      pst: 0,
      qst: 0,
      hst: 0,
      total: 5,
    },
    "Nova Scotia": {
      gst: 0,
      pst: 0,
      qst: 0,
      hst: 15,
      total: 15,
    },
    "Ontario": {
      gst: 0,
      pst: 0,
      qst: 0,
      hst: 13,
      total: 13,
    },
    "Prince Edward Island": {
      gst: 0,
      pst: 0,
      qst: 0,
      hst: 15,
      total: 15,
    },
    "Quebec": {
      gst: 5,
      pst: 0,
      qst: 9.975,
      hst: 0,
      total: 14.975,
    },
    "Saskatchewan": {
      gst: 5,
      pst: 6,
      qst: 0,
      hst: 0,
      total: 11,
    },
    "Yukon": {
      gst: 5,
      pst: 0,
      qst: 0,
      hst: 0,
      total: 5,
    },
  };