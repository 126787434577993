import React, { useEffect, useState, useRef } from "react";
import Line from "../../main/Line";
import BackButton from "../../main/BackButton";
import axios from "../../../axios-backend";
import Button from "../../main/Button";
import { Link } from "react-router-dom";

const AdOptionImage = (props) => {
  const paramsString = props.location.search.substring(1);
  const searchParams = new URLSearchParams(paramsString);
  const idRef = useRef(searchParams.get("id"));
  const [currentImage, setCurrentImage] = useState([]);
  const [imgs, setImgs] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get("/option", {
        params: { id: idRef.current },
      })
      .then((data) => {
        setCurrentImage(data.data.data[0].imageUrl);
      });
  };

  const handleImgDelete = () => {
    axios
      .delete("/options-img", {
        data: {
          id: idRef.current,
        },
      })
      .then((data) => {
        if (data.status === 200) {
          getData();
        }
      });
  };

  const handleChangeImg = (e) => {
    const files = e.target.files;
    if (files.length > 0 && files.length <= 10) {
      const length = files.length;
      for (let i = 0; i < 1; i++) {
        const preview = document.querySelector(`#img-files${i}`);
        preview.src = "";
        preview.style.display = "none";
      }
      const arr = [];
      for (let i = 0; i < length; i++) {
        const file = files[i];
        arr.push(file);
        const preview = document.querySelector(`#img-files${i}`);
        const reader = new FileReader();
        reader.onloadend = function () {
          preview.src = reader.result;
          preview.style.display = "block";
        };
        reader.readAsDataURL(file);
      }
      setImgs(arr);
    } else {
      alert("please select no more than 10 pictures");
      return;
    }
  };

  const handleUpload = () => {
    let formData = new FormData();
    imgs.forEach((item) => {
      formData.append("imgs", item);
    });
    formData.append("id", idRef.current);
    axios({
      method: "post",
      url: "/upload-option-img",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((data) => {
        if (data.data.status === 200) {
          props.history.goBack();
        } else {
          alert(data.data.msg);
        }
      })
      .catch((r) => {
        console.log(r);
      });
  };

  return (
    <>
      <BackButton />
      <Line type="bottom-line">Add / Edit Option image</Line>

      <Line
        type="bottom-line"
        style={{ display: currentImage ? "none" : "flex" }}
      >
        <input
          type="file"
          accept="image/*"
          onChange={handleChangeImg}
          name="file"
          multiple
        />
        <Button style={{ marginRight: "2rem" }} onClick={() => handleUpload()}>
          Submit Image
        </Button>
      </Line>
      <Line style={{ height: "auto", maxHeight: "unset" }}>
        <div
          style={{
            height: "38.5%",
            display: "flex",
            flexWrap: "wrap",
            padding: "2rem",
          }}
        >
          <img
            src=""
            width="150px"
            alt="img-product"
            height="150px"
            style={{ display: "none" }}
            id="img-files0"
          />
        </div>
        {currentImage ? (
          <div className="img-of-admin">
            <img
              src={process.env.REACT_APP_Url + currentImage}
              width="90px"
              alt="img-product"
              height="90px"
            />
            <div
              className="img-delete-button"
              onClick={() => {
                handleImgDelete();
              }}
            >
              <i className="fas fa-window-close"></i>
            </div>
          </div>
        ) : null}
      </Line>
    </>
  );
};

export default AdOptionImage;
