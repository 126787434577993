import React from 'react';
import TermsAndConditions from '../../main/TermsAndConditions'
import BackButton from '../../main/BackButton';

export default function TermsAndConditionsPage(props) {
    return (
        <>
            <BackButton />
            <TermsAndConditions type="page" show={true} />
        </>
    )
}

