import React, { Component } from 'react';
import { IoMdArrowRoundBack } from "react-icons/io";
import { withRouter } from "react-router-dom";
import classes from "../home/css/Backbutton.module.css";
import Line from "./Line";

class BackButton extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    if (this.props.url) {
      this.props.history.push(this.props.url);
    } else {
      this.props.history.goBack();
    }
  }

  render() {
    return (
      <Line type="bottom-line" className={this.props.className}>
        <button onClick={this.goBack} className={classes.button}>
          <IoMdArrowRoundBack />Back
        </button>
        {this.props.children}
      </Line>
    );
  }
}

export default withRouter(BackButton);
