import React, { Component } from 'react';
import Video from "../../main/Video";
import BackButton from '../../main/BackButton';

class OneOfOneLuxury extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Video url="https://yuriy-dmytrash19.wistia.com/medias/oj6tslcxka" maxHeight="80%" />
      </>
    );
  }
}
export default OneOfOneLuxury;

