import React, { useState, useEffect } from 'react';
import styles from './css/SizeGuide.module.css';
import Selector from '../../../main/Selector';
import Line from '../../../main/Line';
import { GrClose } from "react-icons/gr";
import Text from '../../../main/Text';
import SizeLine from './SizeLine';
import GuideWithDescription from './GuideWithDescription'
import axios from '../../../../axios-backend';


export default function SizeGuide(props) {
    const [sizeId, setSizeId] = useState(0);
    const [sizeDescription, setSizeDescription] = useState('');
    const [guides, setGuides] = useState([]);
    const [unit, setUnit] = useState("IN");
    const switcherFnc = (type) => {
        if (type === 'IN') {
            setUnit("IN");
        } else {
            setUnit("CM");
        }
    }
    useEffect(() => {
        if (sizeId) {
            axios.get('/size-guides-for-product', {
                params: {
                    sizeId, gender: props.gender
                }
            }).then(data => {
                if (data.data.status === 200 && data.data.list.length) {
                    setGuides(data.data.list)
                } else {
                    setGuides([])
                }
            }).catch(r => {
                setGuides([]);
                console.log(r)
            })
        }
    }, [sizeId])
    return (
        <div className={styles.container} style={{ display: props.show ? "block" : "none" }}>
            <Line type="bottom-line" className={styles.titleContainer}>
                <GrClose style={{ cursor: "pointer" }} onClick={() => {
                    props.closeFun();
                }} />
            </Line>
            <Selector title="Pick Size" list={props.sizeList}
                selectFunction={
                    (item) => {
                        console.log(item)
                        setSizeId(item.sizeId);
                        if (props.gender == 1) {
                            setSizeDescription(item.menDescription);
                        } else {
                            setSizeDescription(item.description);
                        }

                    }}
                sizeId={sizeId} />
            {
                sizeId ?
                    <>
                        <Text className={styles.textBox}>
                            {sizeDescription}
                        </Text>
                        <Line type="top-line" className={styles.switcherContainer}>
                            <span className={styles.switcherButton + " " + (unit === 'IN' ? styles.onSelected : "")} onClick={() => { switcherFnc("IN") }}>Inches</span>
                            <span className={styles.switcherButton + " " + (unit === 'CM' ? styles.onSelected : "")} onClick={() => { switcherFnc("CM") }}>Centimeters</span>
                        </Line>
                        {
                            guides.map((item, index) => {
                                return (<SizeLine guide={item} key={index} unit={unit} />)
                            })
                        }
                        <Line type="bottom-line" className={styles.split}>
                            How to measure yourself – view below
                        </Line>
                        {
                            guides.map((item, index) => {
                                return (<GuideWithDescription guide={item} key={index} unit={unit} />)
                            })
                        }

                    </>
                    : null
            }
            <Line className={styles.split}>
                If you have any questions about how to pick your
                correct size, please email us at&nbsp;<a href="mailto:support@wonwonleywon.com" style={{ color: "black" }}>support@wonwonleywon.com</a>
            </Line>
        </div>
    )
}
