import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from '../../axios-backend';
import ReactPaginate from 'react-paginate';
import Line from '../main/Line';
import Button from '../main/Button';
import BackButton from '../main/BackButton';
import './css/ViewProducts.css';
import Columns from '../main/Columns';
const md5 = require('md5');

export default function Orders(props) {
    const [orders, setOrders] = useState([]);
    const [pageCount, setPageCount] = useState();
    const pageSize = 10;

    useEffect(() => {
        getData(0)
    }, []);

    const getData = (offset) => {
        axios.get('/orders', {
            params: { offset, pageSize }
        }).then(p => {
            setPageCount(p.data.pageCount);
            setOrders(p.data.list);
        });
    }

    const handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * pageSize);
        getData(offset);
    };

    const renderOrders = () => {
        if (orders.length > 0) {
            return orders.map(o => {
                return (
                    <Line type="bottom-line" key={o.id}>
                        <Columns width="15%">
                            {o.productName}
                        </Columns>
                        <Columns width="10%">
                            {o.cardNumber}
                        </Columns>
                        <Columns width="20%">
                            {o.checkoutCode}
                        </Columns>
                        <Columns width="5%">
                            {o.paymentType == '1' ? 'Stripe' : "Paypal"}
                        </Columns>
                        <Columns width="15%">
                            {o.status ? "paid" : "failed"}
                        </Columns>
                        <Columns width="10%">
                            {o.currency}{o.finalPrice}
                        </Columns>
                        <Columns width="25%">
                            <Link to={`/view-order/${o.id}`} style={{ marginRight: "1rem" }}>
                                <Button>View</Button>
                            </Link>
                            <Button onClick={() => handleDelete(o.id)}>
                                delete
                            </Button>
                        </Columns>
                    </Line>
                );
            });
        } else return <Line type="bottom-line" style={{ justifyContent: "center" }}>loading...</Line>;
    };

    const handleDelete = (id) => {
        const result = window.confirm("Are you sure you want to delete this order?");
        if (result) {
            axios.delete('/orders', { data: { id, token: md5('wonleywon') } }).then(() => {
                getData(0);
            });
        }
    }

    return (
        <div style={{ height: "100%" }}>
            <BackButton />
            <Line type="bottom-line">
                <Columns width="15%">
                    Name
                </Columns>
                <Columns width="15%">
                    Card number
                </Columns>
                <Columns width="20%">
                    Payment ID
                </Columns>
                <Columns width="5%">
                    Type
                </Columns>
                <Columns width="15%">
                    Status
                </Columns>
                <Columns width="10%">
                    Final Price
                </Columns>
                <Columns width="25%" >
                    command
                </Columns>
            </Line>
            {renderOrders()}
            <Line type="bottom-line">
                <div className="paginate-container">
                    <ReactPaginate
                        previousLabel={'←'}
                        nextLabel={'→'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={pageSize}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'bolder-a'}
                        disabledClass={'disable-a'}
                    />
                </div>
            </Line>
        </div>
    );
}
