import React from 'react';
import styles from './css/Spinner.module.css';

export default function Spinner(props) {
    return (
        <div className={styles.spinnerContainer} style={{ display: props.show ? "block" : "none" }}>
            <div className={styles.loader}>Loading...</div>
        </div>
    )
}
