import React, { Component } from 'react';
import Text from "../../main/Text";
import BackButton from '../../main/BackButton';



class ShippingMethods extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Text>we deliver our products all over the world via dhl, fedex, ups, or canada post. please make sure to fill out your correct shipping address when checking out, as once products are shipped, a change of delivery address is not possible.
        </Text>

      </>
    );
  }
}
export default ShippingMethods;

