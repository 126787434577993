import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import axios from '../../../axios-backend';
import BackButton from '../../main/BackButton';
import ColorPicker from './ColorPicker';


const ShopProduct = (props) => {
    const { type, areaAmount, gender, size } = props.location.state;

    const [colorCombination, setColorCombination] = useState([]);
    const [colorPicker, setColorPicker] = useState();

    
    Cookies.set("scrollPositionPD", 0);
    useEffect(() => {
        if (colorCombination.length === parseInt(areaAmount)) {
            const tempColor = `{${colorCombination.join(',')}}`;
            axios.get('/getProductIdByColor/', {
                params: { gender, typeId: type, color: tempColor, size }
            }).then(data => {
                props.history.push({ pathname: '/products/productdetails', state: { productId: data.data.id, type, gender, areaAmount, size } })
            });
        } else {
            setColorPicker(<ColorPicker type={type} gender={gender} size={size} {...{ colorCombination }} onChoseColor={setColorCombination} {...{ areaAmount }} {...props} />);
        }
    }, [colorCombination]);

    return (
        <>
            <BackButton />
            {colorPicker}
        </>
    );
}
export default ShopProduct;
