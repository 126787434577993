import React from 'react';
import BackButton from '../main/BackButton';
import Text from "../main/Text";

export default function Accessibility() {
    return (
        <>
            <BackButton />
            <Text >
                This is Accessibility page
            </Text>
        </>
    )
}
