import React, { Component } from 'react';
import BackButton from '../../main/BackButton';
import Text from '../../main/Text';
import Video from '../../main/Video';
import videos from '../../../videos/testvideo.mp4';


class Philanthropy extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Video url={videos} />

        <Text>
          <p>
            The most exclusive luxury brand in the world...with a philanthropic mission.
          </p>
          <p>
            In connection with our mission, we have a strict company policy to donate a portion of each years profits, and 100% of profits for our events, to a charity that will help support people to be the you. 
          </p>
          <p>
            Some examples of events will be: sport tournaments, concerts and auctions.
          </p>
          <p> 
            For the year 2020, the category specific charity we will donate towards is – mental health. The specific charity groups, within the mental health space, will be decided upon near the end of 2020.
          </p>
          <p>
            For all future years, we will decide upon the category charity at the beginning of the year, and donate to the charities within that grouping. Some example charity groupings, to “support people to be the best you” are charities that specialize in – mind, body, knowledge or joy.
          </p>
          <p>
            So please, join us on our journey: one product at a time, merging luxury and philanthropy, to support people to be the best version of themselves.
          </p>
        </Text>

      </>
    );
  }
}
export default Philanthropy;

