import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CreateRoutes from "./components/Routes";
import Home from "./components/home/Home";
import ErrorBoundary from "./components/main/ErrorBoundary";
import Head from "./components/main/Head";
import Landing from "./components/main/Landing";
import NotFound from "./components/main/NotFound";

import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/swiper-bundle.min.css";
import "./App.css";
import classes from "./components/home/css/Home.module.css";

class App extends Component {
  componentDidMount() {
    const sUserAgent = navigator.userAgent.toLowerCase();
    const bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    const bIsIphone = sUserAgent.match(/iphone os/i) == "iphone os";
    const bIsMidp = sUserAgent.match(/midp/i) == "midp";
    const bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    const bIsUc = sUserAgent.match(/ucweb/i) == "web";
    const bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    const bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    const bIsAndroid = sUserAgent.match(/android/i) == "android";
    if (
      bIsIpad ||
      bIsIphone ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsCE ||
      bIsWM ||
      bIsAndroid
    ) {
      document.querySelector("body").style.minHeight =
        document.body.clientHeight + "px";
    }
  }
  render() {
    return (
      <PayPalScriptProvider
        options={{
          "client-id":"Aa8xc7DZtOlfHq6SwyxZTXD0BimX68CxFwEHRsheqaWk7sesi1hiFgzZCFm20fAW7YbedRiXL-SUWqF-"
        }}
      >
        <BrowserRouter>
          <Head />
          <div className={classes.links}>
            <ErrorBoundary>
              <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/home" component={Home} />
                <Route component={CreateRoutes} />
                <Route path="*" component={NotFound} />
              </Switch>
            </ErrorBoundary>
          </div>
        </BrowserRouter>
      </PayPalScriptProvider>
    );
  }
}
export default App;
