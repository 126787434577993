import React, { useState, useEffect } from 'react';
import Line from '../../../main/Line';
import styles from './css/InsertValue.module.css';
import ButtonsLine from './ButtonsLine';

export default function InsertInchCM(props) {
    const [unit, setUnit] = useState("IN");
    const [measurement, setMeasurement] = useState('');
    const [ft, setFt] = useState('');
    const { shortName, sIndex } = props.step;

    const changeAnswer = (val) => {
        const temp = JSON.parse(JSON.stringify(props.answer));
        temp[sIndex] = { shortName, val };
        props.setAnswer(temp);
    }
    const clearData = () => {
        setFt("");
        setMeasurement("")
    }
    useEffect(() => {
        setUnit('IN')
        clearData();
    }, [sIndex])
    return (
        <>
            <div style={{ display: unit === 'CM' ? "none" : "block" }}>
                <p style={{ paddingTop: "1rem", textAlign: "center" }}>Insert measurement here</p>
                <div className={styles.ftDiv}>
                    <div className={styles.ftSubDiv}>
                        <input type="text" className={styles.ftInput} onChange={(e) => { setFt(e.target.value) }} value={ft} />
                        <span className={styles.displayFt}>ft</span>
                    </div>
                    <div className={styles.ftSubDiv}>
                        <input type="text" className={styles.ftInput} onChange={(e) => { setMeasurement(e.target.value) }} value={measurement} />
                        <span className={styles.displayFt}>in</span>
                    </div>
                </div>
            </div>

            <div style={{ display: unit === 'IN' ? "none" : "block" }} className={styles.inputContainer}>
                Insert measurement here
                <br />
                <input type="text" className={styles.input} onChange={(e) => { setMeasurement(e.target.value) }} value={measurement} />
                <span className={styles.displayUnit}>cm</span>
            </div>
            <Line type="top-line" className={styles.switcherContainer}>
                <span className={styles.switcherButton + " " + (unit === 'IN' ? styles.onSelected : "")} onClick={() => { clearData(); setUnit("IN") }}>Metric</span>
                <span className={styles.switcherButton + " " + (unit === 'CM' ? styles.onSelected : "")} onClick={() => { clearData(); setUnit("CM") }}>Imperial</span>
            </Line>
            <ButtonsLine
                preStep={props.preStep}
                nextStep={() => {
                    if (unit === 'IN') {
                        changeAnswer(ft + ' ft ' + measurement + ' in');
                    } else {
                        changeAnswer(measurement + ' cm');
                    }
                    props.nextStep();
                }}
                skipStep={() => {
                    changeAnswer('skip');
                    props.nextStep();
                }}
            />
        </>
    )
}
