import React from "react";
import BackButton from "../../main/BackButton";
import Video from "../../main/Video";

const Collection1112Video = () => {
  return (
    <>
      <BackButton url="/allvideos" />
      <Video
        url={`https://yuriy-dmytrash19.wistia.com/medias/f894djv603`}
        maxHeight="80%"
      />
    </>
  );
};

export default Collection1112Video;
