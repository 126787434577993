import React, { Component } from "react";
import classes from "../home/css/Home.module.css";



class Text extends Component {
  render() {
    return (
      <div className={classes.text + " " + this.props.className} style={{ ...this.props.style }} onClick={this.props.onClick}>{this.props.children}</div>
    );
  }
}
export default Text;
