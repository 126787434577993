import React, { Component } from 'react';
import Text from "../../main/Text";
import BackButton from '../../main/BackButton';



class PriceCurrencies extends Component {
  render() {
    return (
      <>

        <BackButton />

        <Text>all prices are only displayed in usd and will be charged in usd on whichever payment method you use.</Text>

      </>
    );
  }
}
export default PriceCurrencies;

