import React from "react";
import BackButton from "../../main/BackButton";
import Video from "../../main/Video";

const Collection3Video = () => {
  return (
    <>
      <BackButton url="/allvideos" />
      <Video
        url={`https://yuriy-dmytrash19.wistia.com/medias/qvpu7txkk7`}
        maxHeight="80%"
      />
    </>
  );
};

export default Collection3Video;
