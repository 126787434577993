import React, { Component } from 'react';
import BackButton from '../../main/BackButton';
import videos from '../../../videos/testvideo.mp4';
import Video from "../../main/Video";

class Meaning extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Video url="https://yuriy-dmytrash19.wistia.com/medias/hqd4nn1gjb" maxHeight="80%" />
      </>
    );
  }
}



export default Meaning;

