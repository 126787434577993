import React from 'react'
import BackButton from '../../../main/BackButton'
import Video from "../../../main/Video";

export default function VideoPage() {
    return (
        <>
            <BackButton />
            <Video url="https://yuriy-dmytrash19.wistia.com/medias/zdsb2n2mi4" maxHeight="80%" />
        </>
    )
}
