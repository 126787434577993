import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios-backend";
import classes from "../home/css/Authentication.module.css";
import BackButton from "../main/BackButton";
import ClickHere from "../main/ClickHere";
import Line from "../main/Line";
import PolicyLine from "../main/PolicyLine";
import PrivacyPolicy from "../main/PrivacyPolicy";
import TermsAndConditions from "../main/TermsAndConditions";

const Authentication = (props) => {
  const md5 = require("md5");
  const [cardNumber, setCardNumber] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [showPolicy, setShowPolicy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const cardInput = useRef();

  const clickHandle = () => {
    const times = sessionStorage["loginTry"];
    const trueNumber = cardNumber.split(" ").join("");
    if (times > 10) {
      alert(
        `sorry, you have tried too many times today. please try tomorrow.`
      );
      return;
    }
    if (!trueNumber || trueNumber.length !== 16) {
      setMsg("* input a correct number.");
      return;
    }
    if (!password || password.length !== 16) {
      setMsg("* input a correct password.");
      return;
    }
    sessionStorage["loginTry"] = times ? parseInt(times) + 1 : 1;
    axios
      .get("/authenticationcard/", {
        params: { cardNumber: trueNumber, password: md5(password.trim()) },
      })
      .then((data) => {
        if (data.data.status === 200) {
          setMsg("");
          sessionStorage["isAuth"] = "true";
          sessionStorage["cardNumber"] = trueNumber;
          sessionStorage["password"] = md5(password.trim());
          props.history.push(`/authenticity/showingcard`);
        } else {
          setMsg("* please check your card number and password.");
        }
      })
      .catch((error) => {
        setMsg("* sorry. your network might have an error. please try again.");
      });
  };

  const handleTypeCard = (event) => {
    const val = event.target.value;
    // setCardNumber(val);
    // return
    // console.log(event.target.selectionStart, event.target.selectionEnd)
    // cardInput.current.setSelectionRange(0, 0)
    if (val.length > 19) {
      return;
    }
    const temp = val.split(" ").join("");
    if (temp.length <= 4) {
      setCardNumber(val);
    } else if (temp.length > 4 && temp.length < 9) {
      setCardNumber(temp.substring(0, 4) + " " + temp.substring(4));
    } else if (temp.length >= 9 && temp.length < 13) {
      setCardNumber(
        temp.substring(0, 4) +
          " " +
          temp.substring(4, 8) +
          " " +
          temp.substring(8)
      );
    } else {
      setCardNumber(
        temp.substring(0, 4) +
          " " +
          temp.substring(4, 8) +
          " " +
          temp.substring(8, 12) +
          " " +
          temp.substring(12)
      );
    }
  };

  const testOnKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <>
      <BackButton />
      <Line type="empty-line">
        <span className={classes.span}>Enter your card number here</span>
      </Line>
      <Line type="bottom-line">
        <input
          type="text"
          ref={cardInput}
          className={classes.textbox}
          value={cardNumber}
          onKeyPress={testOnKeyPress}
          // onKeyUp={test}
          onChange={handleTypeCard}
          maxLength={19}
        />
      </Line>
      <Line type="empty-line">
        <span className={classes.span}>Enter your password here</span>
      </Line>
      <Line type="bottom-line">
        <input
          className={classes.textbox}
          value={password}
          type="password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          maxLength={16}
        />
      </Line>
      <Line type="bottom-line">
        <span className={classes.span}>{msg}</span>
      </Line>
      <Line type="bottom-line">
        forgot password
        <Link to="/forgot-pwd">
          <ClickHere />
        </Link>
      </Line>
      <Line type="bottom-line" />
      <Line type="bottom-line">
        log in <ClickHere onClick={clickHandle} />
      </Line>
      <PolicyLine
        clickTerms={() => {
          setShowTerms(true);
        }}
        clickPolicy={() => {
          setShowPolicy(true);
        }}
      />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line />
      <PrivacyPolicy
        show={showPolicy}
        closeFun={() => {
          setShowPolicy(false);
        }}
      />
      <TermsAndConditions
        show={showTerms}
        closeFun={() => {
          setShowTerms(false);
        }}
      />
    </>
  );
};
export default Authentication;
