import React, { useEffect, useState } from "react";
import Line from "../../main/Line";
import BackButton from "../../main/BackButton";
import axios from "../../../axios-backend";
import Button from "../../main/Button";
import { Link } from "react-router-dom";
import Columns from "../../main/Columns";

const ViewAnswers = (props) => {
  const [answers, setAnswers] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios.get("/answers").then((data) => {
      setAnswers(data.data.data);
    });
  };

  const mapAndRenderAnswers = () => {
    return answers.map((item, index) => {
      return (
        <Line type="bottom-line" key={index}>
          <Columns width="5%">{index + 1}</Columns>
          <Columns width="55%">{item.name}</Columns>
          <Columns width="25%">
            {new Date(item.createdTime).toLocaleString("it-IT")}
          </Columns>
          <Columns width="15%">
            <Link
              style={{ marginLeft: "2rem" }}
              to={`/view-answer?id=${item.id}`}
            >
              <Button>View Details</Button>
            </Link>
          </Columns>
        </Line>
      );
    });
  };

  return (
    <>
      <BackButton />
      <Line type="bottom-line">All The Replies Of The Size Finder</Line>
      <Line type="bottom-line">
        <Columns width="5%">Index</Columns>
        <Columns width="55%">collection Name</Columns>
        <Columns width="25%">Created Time</Columns>
        <Columns width="15%">View</Columns>
      </Line>
      {mapAndRenderAnswers()}
    </>
  );
};

export default ViewAnswers;
