import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import classes from "./css/Home.module.css";
import Line from "../main/Line";
import BackButton from "../main/BackButton";

class More extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Line type="bottom-line">
          <NavLink to="/more/duties" className={classes.navLink}>
            Duties
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/more/taxes" className={classes.navLink}>
            Taxes
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/more/shipping_methods" className={classes.navLink}>
            Shipping Methods
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/more/shipping_cost" className={classes.navLink}>
            Shipping Costs
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/more/delivery_time" className={classes.navLink}>
            Delivery Time
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/more/returns" className={classes.navLink}>
            Returns
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/more/prices_currencies" className={classes.navLink}>
            Prices and Currencies
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/more/changecountrycurrency" className={classes.navLink}>
            Change Country and Currency
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/more/payment_methods" className={classes.navLink}>
            Payment Methods
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/more/int_transaction_fees" className={classes.navLink}>
            International Transaction Fees
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/more/contact_us" className={classes.navLink}>
            Contact Us
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/more/terms-and-conditions" className={classes.navLink}>
            terms of use
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/more/privacy-policy" className={classes.navLink}>
            privacy policy
          </NavLink>
        </Line>
        {/* <Line type="bottom-line"><NavLink to="/accessibility" className={classes.navLink}>
          accessibility
        </NavLink>
        </Line> */}
      </>
    );
  }
}
export default More;
