import React, { Component } from "react";
import BackButton from "../../main/BackButton";
import Text from "../../main/Text";
import { Link } from "react-router-dom";

class PurchasingProduct extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Text>
          {/* #1. all products are sold in collections. a collection equals a group
          of products that have an identical shape. however, each product is
          visually different due to a part of the product having a different
          color than all other products in the collection, making each product
          unique and living by the law known as{" "}
          <Link style={{ color: "black" }} to="/world/oneofoneluxury">
            one of one luxury
          </Link>
          . */}
          #1. all products are sold in collections or as singular products. a collection 
          equals a group of products that have an identical shape. however, each
          product is visually different due to a part of the product having a different color 
          than all other products in the collection, making each product unique
          and living by the law known as {" "}
          <Link style={{ color: "black" }} to="/world/oneofoneluxury">
            one of one luxury
          </Link>
          . a singular product is not 
          linked to any collection, and also lives by the law known as {" "}
          <Link style={{ color: "black" }} to="/world/oneofoneluxury">
            one of one luxury
          </Link>
          .
          <br />
          <br />
          #2. currently, we only sell products on this{" "}
          <Link style={{ color: "black" }} to="/home">
            website
          </Link>
          , which is our digital boutique.
          <br />
          <br />
          #3. the release date of new collections or new products
          will happen randomly. to know when we release a new
          collection or new products, become a {" "}
          <Link style={{ color: "black" }} to="/world/membership">
            member
          </Link>
          .
        </Text>
      </>
    );
  }
}
export default PurchasingProduct;
