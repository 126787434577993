import React from 'react'
import BackButton from '../../main/BackButton';
import Text from '../../main/Text';

export default function ProductQuestions() {
    return (
        <>
            <BackButton />
            <Text>
                if you have any questions about what size to purchase email us at <a href="mailto:support@wonwonleywon.com" style={{ color: "black" }}>support@wonwonleywon.com</a>
            </Text>
        </>
    )
}
