/**
 * Use the CSS tab above to style your Element's container.
 */
import React from "react";
import {
  CardElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import "./css/CardSectionStyles.css";

const cardElementOptions = {
  style: {
    base: {
      fontSize: "17px",
      fontFamily: "Encode Sans Condensed, sans-serif",
      // paddingTop: "2px",
      // borderRadius: "20px",
      // color: "green",
      // textTransform: "lower-case",
      // padding: "0",
      // margin: "0",
    },
  },
};

export function CardNumber() {
  return <CardNumberElement options={cardElementOptions} />;
}

export function CardSection() {
  return (
    <>
      {/* <CardElement options={cardElementOptions} /> */}

      <CardCvcElement options={cardElementOptions} />

      {/* <CardExpiryElement /> */}
    </>
  );
}

export function CardCvC() {
  return (
    <>
      <CardExpiryElement options={cardElementOptions} />
    </>
  );
}

// export default CardSection;
