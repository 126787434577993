import React from 'react';
import styles from './css/CheckoutLines.module.css';

export default function CheckoutLines(props) {
    return (
        <div className={styles.container} style={props.style}>
            <div className={styles.text}>
                {props.text}
            </div>
            {props.children}
        </div>
    )
}
