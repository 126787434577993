import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios-backend";
import BackButton from "../main/BackButton";
import Button from "../main/Button";
import Line from "../main/Line";
import "./css/ViewProduct.css";

const ViewProduct = (props) => {
  const [product, setProduct] = useState();
  const [imgs, setImgs] = useState([]);
  const [video, setVideo] = useState({ id: "0", url: "" });
  useEffect(() => {
    const id = props.match.params.id;
    axios.get(`/products/${id}`).then((p) => {
      if (p.data[0]) {
        setProduct({ ...p.data[0] });
        loadImgData();
      }
    });
  }, []);
  const loadImgData = () => {
    //type:6 -> all product images
    axios.get(`/get-imgs-by-pid`, { params: { id: props.match.params.id, type: 6 } }).then((data) => {
      if (data.data.status === 200) {
        setImgs(data.data.imgs);
      }
    });
    axios.get(`/get-video-by-pid`, { params: { id: props.match.params.id } }).then((data) => {
      if (data.data.status === 200) {
        setVideo(data.data.video);
      }
    });
  };
  const handleDelete = (id) => {
    const result = window.confirm("Are you sure you want to delete this product?");
    if (result) {
      axios.delete("/products", { data: { id } }).then(() => {
        props.history.push("/viewproducts");
      });
    }
  };
  const handleImgDelete = (id) => {
    const result = window.confirm("Are you sure you want to delete this picture?");
    if (result) {
      axios.delete("/pictures", { data: { id } }).then(() => {
        loadImgData();
      });
    }
  };
  const handleVideoDelete = () => {
    const result = window.confirm("Are you sure you want to delete this video?");
    if (result) {
      axios.delete("/videos", { data: { id: video.id } }).then((data) => {
        loadImgData();
      });
    }
  };
  return (
    <div style={{ height: "100%" }}>
      <BackButton />
      {product ? (
        <>
          <Line type="bottom-line">
            <span style={{ marginLeft: "1rem" }}>Name: {product.name}</span>
          </Line>
          <Line type="bottom-line">
            <span style={{ marginLeft: "1rem" }}>gender: {product.gender === 0 ? "female" : product.gender === 1 ? "male" : "unisex"}</span>
          </Line>
          <Line type="bottom-line">
            <span style={{ marginLeft: "1rem" }}>Size: {product.size}</span>
          </Line>
          <Line type="bottom-line">
            <span style={{ marginLeft: "1rem" }}>US Price: {product.priceUs}</span>
          </Line>
          <Line type="bottom-line">
            <span style={{ marginLeft: "1rem" }}>CardNumber: {product.cardNumber}</span>
          </Line>
          <Line type="bottom-line">
            <span style={{ marginLeft: "1rem" }}>Status: {product.status === 1 ? "Normal" : "Sold"}</span>
          </Line>
          <Line type="bottom-line">
            <span style={{ marginLeft: "1rem" }}>Type: {product.typeName}</span>
          </Line>
          <Line type="bottom-line">
            <span style={{ marginLeft: "1rem" }}>Color: {product.color.join(" ")}</span>
          </Line>
          <Line type="bottom-line">
            <span style={{ marginLeft: "1rem" }}>Remove Shipment Charge: {product.removeShipmentCharge ? "true" : "false"}</span>
          </Line>
          <Line type="bottom-line">
            <Link to={`/adpicture/${product.id}?type=product`} style={{ margin: "0 1rem" }}>
              <Button>Add Picture</Button>
            </Link>
            <Link to={`/advideo/${product.id}`} style={{ marginRight: "1rem", display: video.id === "0" ? "block" : "none" }}>
              <Button>Add Video</Button>
            </Link>
            <Button style={{ marginRight: "1rem" }} onClick={() => handleDelete(product.id)}>
              Delete
            </Button>
            <Link to={`/adproduct?id=${product.id}`} style={{ marginRight: "1rem" }}>
              <Button>Update</Button>
            </Link>
          </Line>
          <Line type="bottom-line">
            <span style={{ marginLeft: "1rem" }}>video:</span>
            <span style={{ marginLeft: "1rem" }}>{video.url}</span>
            <i
              className="fas fa-window-close"
              style={{ marginLeft: "1rem", display: video.id === "0" ? "none" : "block" }}
              onClick={handleVideoDelete}
            ></i>
          </Line>

          {/* product images when product is just for specific gender only (type = 1) */}
          {imgs.some((item) => item.type === 1) && (
            <>
              <div className="imgs-container">
                <div className="img-of-product">product images: </div>
                {imgs
                  .filter((item) => item.type === 1)
                  .map((item, index) => {
                    return (
                      <div className="img-of-product" key={index}>
                        <img
                          src={
                            item.url.startsWith("s3")
                            ? `https://wonwonleywon-images.s3.amazonaws.com/product_images/${item.url}`
                              : `${process.env.REACT_APP_Url}${item.url}`
                          }
                          width="150px"
                          alt="img-product"
                          height="150px"
                        />
                        <div
                          className="img-delete-button"
                          onClick={() => {
                            handleImgDelete(item.id);
                          }}
                        >
                          <i className="fas fa-window-close"></i>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <Line type="bottom-line"></Line>
            </>
          )}

          {/* men images */}
          {imgs.some((item) => item.type === 4) && (
            <>
              <div className="imgs-container">
                <div className="img-of-product">images for men: </div>
                {imgs
                  .filter((item) => item.type === 4)
                  .map((item, index) => {
                    return (
                      <div className="img-of-product" key={index}>
                        <img
                          src={
                            item.url.startsWith("s3")
                              ? `https://wonwonleywon-images.s3.amazonaws.com/product_images/${item.url}`
                              : `${process.env.REACT_APP_Url}${item.url}`
                          }
                          width="150px"
                          alt="img-product"
                          height="150px"
                        />
                        <div
                          className="img-delete-button"
                          onClick={() => {
                            handleImgDelete(item.id);
                          }}
                        >
                          <i className="fas fa-window-close"></i>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <Line type="bottom-line"></Line>
            </>
          )}

          {/* women images */}
          {imgs.some((item) => item.type === 5) && (
            <>
              <div className="imgs-container">
                <div className="img-of-product">images for women: </div>
                {imgs
                  .filter((item) => item.type === 5)
                  .map((item, index) => {
                    return (
                      <div className="img-of-product" key={index}>
                        <img
                          src={
                            item.url.startsWith("s3")
                              ? `https://wonwonleywon-images.s3.amazonaws.com/product_images/${item.url}`
                              : `${process.env.REACT_APP_Url}${item.url}`
                          }
                          width="150px"
                          alt="img-product"
                          height="150px"
                        />
                        <div
                          className="img-delete-button"
                          onClick={() => {
                            handleImgDelete(item.id);
                          }}
                        >
                          <i className="fas fa-window-close"></i>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <Line type="bottom-line"></Line>
            </>
          )}

          {/* authenticity images */}
          {imgs.some((item) => item.type === 2) && (
            <>
              <div className="imgs-container">
                <div className="img-of-product">authenticity images: </div>
                {imgs
                  .filter((item) => item.type === 2)
                  .map((item, index) => {
                    return (
                      <div className="img-of-product" key={index}>
                        <img
                          src={
                            item.url.startsWith("s3")
                              ? `https://wonwonleywon-images.s3.amazonaws.com/product_images/${item.url}`
                              : `${process.env.REACT_APP_Url}${item.url}`
                          }
                          width="150px"
                          alt="img-product"
                          height="150px"
                        />
                        <div
                          className="img-delete-button"
                          onClick={() => {
                            handleImgDelete(item.id);
                          }}
                        >
                          <i className="fas fa-window-close"></i>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <Line type="bottom-line"></Line>
            </>
          )}
        </>
      ) : null}
    </div>
  );
};

export default ViewProduct;
