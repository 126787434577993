import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ClickHere from '../../../main/ClickHere';
import Line from '../../../main/Line';
import VideoPlayer from '../../../main/VideoPlayer';
import ButtonsLine from './ButtonsLine';
import styles from './css/InsertValue.module.css';


export default function InsertValue(props) {
    const [unit, setUnit] = useState("IN");
    const [measurement, setMeasurement] = useState();
    const [showVideo, setShowVideo] = useState(false);
    const switcherFnc = (type) => {
        if (type === 'IN') {
            setUnit("IN");
        } else {
            setUnit("CM");
        }
    }
    const { imgUrl, videoUrl, shortName, sIndex } = props.step;

    const changeAnswer = (val) => {
        const temp = JSON.parse(JSON.stringify(props.answer));
        temp[sIndex] = { shortName, val };
        props.setAnswer(temp);
    }
    useEffect(() => {
        setUnit("IN");
        setMeasurement('')
    }, [sIndex])

    return (
        <>
            <Line type="bottom-line">
                Use the red support lines as guidance
            </Line>
            {
                videoUrl ?
                    <Line type="bottom-line">
                        Watch video of how to measure <ClickHere onClick={() => {
                            setShowVideo(true);
                        }} />
                    </Line> : null
            }
            <LazyLoadImage src={process.env.REACT_APP_Url + imgUrl} alt="how to measure" effect="blur" className={styles.imgArea}  style={{
                      minHeight: "300px",
                      maxHeight: "500px",
                      height: "100%",
                      minWidth: "300px",
                      maxWidth: "500px",
                      width: "100%",
                      padding: "10px 0",
                      objectFit: "contain",
                    }}/>
            <div className={styles.inputContainer}>
                Insert measurement here <br />
                <input className={styles.input} onChange={(e) => { setMeasurement(e.target.value) }} value={measurement} />
                <span className={styles.displayUnit}>{unit}</span>
            </div>
            <Line type="top-line" className={styles.switcherContainer}>
                <span className={styles.switcherButton + " " + (unit === 'IN' ? styles.onSelected : "")} onClick={() => { switcherFnc("IN") }}>Inches</span>
                <span className={styles.switcherButton + " " + (unit === 'CM' ? styles.onSelected : "")} onClick={() => { switcherFnc("CM") }}>Centimeters</span>
            </Line>
            <ButtonsLine
                preStep={props.preStep}
                nextStep={() => {
                    changeAnswer(measurement + ' ' + unit);
                    props.nextStep();
                }}
                skipStep={() => {
                    changeAnswer('skip');
                    props.nextStep();
                }}
            />
            <VideoPlayer show={showVideo} url={process.env.REACT_APP_Url + videoUrl} closeFun={() => {
                setShowVideo(false);
            }} />
        </>
    )
}
