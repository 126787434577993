import React from 'react';
import styles from './css/Button.module.css';

const Button = (props) => {
    const { className, children, ...rest } = props;
    return (
        <button className={styles.btn + " " + className} {...rest}>
            {children}
        </button>
    )
}

export default Button;