import React from "react";
import Text from "./Text";
import styles from "./css/TermsAndConditions.module.css";
import Line from "./Line";
import { GrClose } from "react-icons/gr";

export default function PrivacyPolicy(props) {
  return (
    <div
      className={props.type === 'page' ? styles.pageContainer : styles.container}
      style={{ display: props.show ? "block" : "none" }}
    >
      {
        props.type === 'page' ? null : <Line type="bottom-line" className={styles.titleContainer}>
          <GrClose
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.closeFun();
            }}
          />
        </Line>
      }

      <p className={styles.title}>
        <Text>wonwonleywon privacy policy</Text>
      </p>
      <p className={styles.bold}> last updated may 30, 2022.</p>
      <br />
      <p>
        this privacy policy (the "
        <span className={styles.bold}>privacy policy</span>") explains how
        <span className={styles.bold}>wonwonleywon products</span> inc. and its
        subsidiaries and affiliates ("
        <span className={styles.bold}>wonwonleywon,</span>" "
        <span className={styles.bold}>we,</span>" "
        <span className={styles.bold}>us,</span>" or “{" "}
        <span className={styles.bold}>data controller</span>”) collect, use,
        process, disclose, and retain your personal data when you access or use
        our website and domains (accessible at wonwonleywon.com) that display
        this privacy policy (collectively, the “
        <span className={styles.bold}>services</span>”), engage with us on
        social media, or otherwise interact with us. please review this privacy
        policy carefully.
      </p>
      <br />

      <p>
        also, we may provide additional "just-in-time" disclosures or
        information about our data processing practices. these notices may
        supplement or clarify our privacy practices or may provide you with
        additional choices about how we process your personal data.{" "}
      </p>

      <br />
      <p className={styles.bold}>Revisions</p>
      <br />
      <p>
        wonwonleywon reserves the right to change this privacy policy from time
        to time. if we make changes, we will notify you by revising the date at
        the top of this privacy policy.
      </p>
      <br />
      <p className={styles.bold}>Collection of personal data</p>
      <br />
      <p>
        in this privacy policy, "
        <span className={styles.bold}>personal data</span>" means any
        information that is related to an identified or reasonably identifiable
        natural person, or as otherwise defined under applicable law.
      </p>
      <br />
      <p className={styles.bold}>personal data we collect from you</p>
      <br />
      <p>
        we collect personal data directly from you when you interact with us or
        use our services. the types of personal data we collect depends on how
        you interact with us or use our services. we may collect the following
        categories of personal data.
      </p>
      <p>
        <ul className={styles.disc}>
          <li className={styles.disc}>
            {"  "}•{"  "}{" "}
            <span className={styles.underline}>profile data.</span> we collect
            any information or content you provide to us. examples of the
            information we collect include your name, address, and email.
          </li>

          <li>
            {"  "}•{"  "}{" "}
            <span className={styles.underline}>transaction data.</span> we
            collect transaction information related to the use of our services
            or your purchases. examples of information collected include the
            type of products or services requested or provided, order details,
            delivery information, amount charged, payment method, billing or
            shipping information, customization services, and information about
            any products or services that you may have purchased. we use stripe
            and paypal as our third-party payment processors thus we do not
            collect any banking or financial information. please refer to the
            stripe privacy policy and the paypal privacy policy for further
            details.
          </li>
          <li>
            {"  "}•{"  "}{" "}
            <span className={styles.underline}>communications data.</span> we
            collect information when you communicate with us or others on our
            services, such as when you request additional information about
            products or services, interact with our customer service team, or
            sign up to receive our e-mail newsletters or marketing messages. we
            also collect communications data when you interact with us on social
            media, such as by tagging us and/or our products or permitting us to
            follow your social media profile. examples of such data may include
            your contact information, the date and time of your communications,
            online identifiers, social media profile, and the content of your
            communications.
          </li>
          <li>
            {"  "}•{"  "}{" "}
            <span className={styles.underline}>user content data.</span> we
            collect any other information or content you provide to us, such as
            when you provide product feedback or make other submissions to us,
            participate in any of our experiential offerings, promotions, or use
            other features of our services that may be offered from time to
            time, which may require the collection of certain personal data to
            utilize the features.
          </li>
        </ul>
      </p>
      <br />
      <p className={styles.bold}>personal data that we actually collect</p>
      <br />
      <p>
        we automatically collect certain personal data when you access and use
        our services. the types of information we collect may include:
        <br />
        <ul>
          <li>
            {"  "}•{" "}
            <span className={styles.underline}>device and network data.</span>{" "}
            we collect certain information about the device you use to access
            our services. examples of such data include your device’s hardware
            model, browser type, ip address, operating system version, language
            settings, unique device identifiers, advertising identifiers, serial
            numbers, device motion data, and mobile network data.
          </li>
          <li>
            {"  "}• <span className={styles.underline}>usage data.</span> we
            collect information about your activity on our services via log
            files, cookies, web beacons, and other tracking technologies.
            examples of such data include your access times, pages viewed, the
            routes by which you access our services, site crashes and other
            system activity, your use of any hyperlinks available within our
            services, your internet service provider (isp), mobile advertising
            id, media access control (mac) address, and identifiers associated
            with browser cookies, web beacons, and similar technologies we
            deploy on our services (for more information about cookies and how
            to disable them, see the cookies section below).{" "}
          </li>
          <li>
            {"  "}• <span className={styles.underline}>location data.</span> we
            collect precise or approximate location information in accordance
            with your device permissions. you may use our services without
            enabling us to collect location data from your device, however, this
            may affect some functionality available in the services.
          </li>
        </ul>
      </p>

      <br />
      <p className={styles.bold}>personal data we collect from other sources</p>
      <br />
      <p>
        we may collect personal data about you from other sources. for example,
        we may collect personal data about you from:
      </p>
      <p>
        <ul>
          <li>
            {"  "}•{"  "}publicly available sources;
          </li>
          <li>
            {"  "}•{"  "}carriers or other third parties when they share your
            updated delivery and address information, which we use to correct
            our records and deliver your next purchase or communication;
          </li>
          <li>
            {"  "}•{"  "}our subsidiaries and affiliates;
          </li>
          <li>
            {"  "}•{"  "}third-party social media and communication services,
            such as facebook, twitter, google, tiktok and instagram, that you
            use to interact with our services (e.g., to create an account) or
            that allow you to share information (e.g., via plugins, widgets, or
            other tools), but always in accordance with the authorization
            procedures and privacy settings you establish with such services;
            and
          </li>
          <li>
            {"  "}•{"  "}unaffiliated parties, such as service providers that we
            use, analytics companies, marketing or advertising service
            providers, fraud prevention service providers, consumer data
            resellers, and other third parties that provide us with information,
            so we can better understand you and provide you with information and
            offers that may be of interest to you.
          </li>
        </ul>
      </p>
      <br />
      <p className={styles.bold}>personal information we derive</p>
      <br />
      <p>
        <ul>
          <li>
            {"  "}•{"  "}we may derive information or draw inferences about you
            based on the other types of personal data we collect. for example,
            we may infer your location based on your ip address, or your
            purchasing habits based on your browsing behavior on our services
          </li>
        </ul>
      </p>
      <br />
      <p className={styles.bold}>how we use your personal data</p>
      <br />
      <p>
        we may collect and use personal data for various purposes, including to:
      </p>
      <br />
      <p>
        <ul>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>
              provide products and services.
            </span>{" "}
            provide, maintain, and improve our data, products, promotional
            events, and services;
          </li>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>
              complete transactions.
            </span>{" "}
            complete the transactions you request, perform our contractual
            obligations, and as otherwise anticipated within the context of our
            ongoing business relationship;
          </li>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>manage accounts.</span> create
            and manage any account, profile, authenticity portal and membership
            account;
          </li>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>
              service communications.
            </span>{" "}
            send notifications related to your account, purchases, exchanges,
            and returns if applicable;
          </li>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>respond to you.</span> respond to
            your requests and any other communications from you, including to
            provide customer service;
          </li>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>advertising and marketing.</span>
            send advertising or marketing communications about products,
            services, offers, promotions, rewards, and promotional events
            offered by wonwonleywon and others, and provide news and information
            that we believe may be of interest to you. our marketing and
            advertising will be conducted in accordance with your advertising
            marketing preferences and as permitted by applicable law. we are
            compliant with canadian anti-spam legislation. for further details
            about canadian anti-spam legislation, please go here;
          </li>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>events and offers.</span> offer,
            conduct and administer promotional events, contests, prize draws,
            sweepstakes, and other promotions;
          </li>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>
              research and development.
            </span>{" "}
            conduct research and development;
          </li>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>
              personalize your experience.
            </span>{" "}
            monitor, analyze and audit your engagement with our brand and your
            interactions with our services and online ads to better understand
            your interests and behaviors and customize your experience;
          </li>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>safety and security.</span>{" "}
            detect and protect against malicious, deceptive, or illegal
            activity, including fraudulent transactions, error, negligence, and
            breach of contract, security incidents, and harm to the rights,
            property or safety of wonwonleywon and our users, customers,
            employees or others;
          </li>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>troubleshooting.</span> debug,
            identify and repair errors that impair existing intended
            functionality of our services;
          </li>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>corporate transactions.</span> we
            may process personal data in the context of corporate acquisitions,
            mergers, or other corporate transactions;
          </li>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>your consent.</span> we may
            process your personal data in accordance with your consent or
            instructions;
          </li>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>
              comply with legal obligations.
            </span>{" "}
            comply with our legal or regulatory obligations, including our tax
            obligations and those related to the prevention of fraud and money
            laundering, and those required for you to benefit from rights
            recognized by law, or any regulatory requirements or provisions; and
          </li>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>
              reasonable other purposes.
            </span>{" "}
            as permitted by applicable law, carry out certain short-term
            activities and other reasonable purposes related to the products or
            services you purchase from us or your ongoing relationship with us.
            where required by applicable law, wonwonleywon will provide a notice
            of such data processing prior to using your personal data for such
            purposes.
          </li>
        </ul>
      </p>
      <br />
      <p className={styles.bold}>disclosure of personal data</p>
      <br />
      <p>we may share personal data for the purposes described below:</p>
      <br />
      <p>
        <ul>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>
              with our affiliates and subsidiaries.
            </span>{" "}
            we may share your personal data with our subsidiaries and affiliates
            for the purposes described in the “use of personal data” section
            above.
          </li>
          <li>
            {"  "}•{"  "}{" "}
            <span className={styles.underline}>
              with our service providers.
            </span>{" "}
            we may share personal data with unaffiliated companies or
            individuals we hire or work with that perform services on our
            behalf, including customer support, web hosting, information
            technology, payment processing, product fulfilment, fraud control,
            direct mail and email distribution, events, contest, sweepstakes and
            promotion administration, and advertising and analytics services.
            these third-party service providers have access to personal data
            needed to perform their services but may not use it for other
            purposes. since our service providers are located around the world,
            please note that these disclosures involve cross-border transfers of
            your personal data as described in the “data transfers” section
            below.{" "}
          </li>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>
              in connection with a corporate transaction.
            </span>{" "}
            personal data may be disclosed or transferred as part of, or during
            negotiations of any purchase, sale, lease, merger, amalgamation, or
            any other type of acquisition, disposal, securitisation, or
            financing involving wonwonleywon.
          </li>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>
              with our professional advisors.
            </span>{" "}
            we share personal data with our legal, financial, insurance, and
            other advisors in connection with the kinds of corporate
            transactions described above or in connection with the management of
            all or part of wonwonleywon’s business or operations.
          </li>
          <li>
            {"  "}•{"  "}
            <span className={styles.underline}>
              with law enforcement authorities and individuals involved in legal
              proceedings.
            </span>{" "}
            we disclose personal data when we believe doing so is reasonably
            necessary to comply with applicable law or legal process (including
            an enforceable request from authorities), to respond to claims
            (including inquiries by you in connection with your purchases from
            wonwonleywon), enforce or apply our terms of use, or to protect the
            rights, property, or personal safety of wonwonleywon, our users,
            employees, or others.
          </li>
          <li>
            {"  "}•{"  "}{" "}
            <span className={styles.underline}>
              with your consent or at your direction.
            </span>{" "}
            we share personal data with third parties when we have your consent
            to do so.{" "}
          </li>
          <li>
            {"  "}•{"  "}{" "}
            <span className={styles.underline}>
              aggregated, anonymized or de-identified data.
            </span>{" "}
            we may also share aggregated, anonymized, or de-identified
            information, which cannot reasonably be used to identify you.
          </li>
        </ul>
      </p>
      <br />
      <p className={styles.bold}>
        advertising and analytics services provided by others
      </p>
      <br />
      <p>
        we may allow others to provide analytics services and serve
        advertisements on our behalf across the web. these entities may use
        cookies, web beacons, device identifiers, and other tracking
        technologies which collect information about your use of the services
        and other websites. this information may be used by wonwonleywon and
        others to, among other things, analyze and track data, determine the
        popularity of certain content, deliver advertising and content targeted
        to your interest on our services and other websites, and better
        understand your online activity.
      </p>
      <br />
      <p className={styles.bold}>data transfers</p>
      <br />
      <p>
        for the reasons and purposes set forth in this privacy policy, the
        personal data that we collect may be transferred to and stored or
        otherwise processed in the united states, canada, and other locations.
        we also transfer personal data to service providers that process
        personal data for us in the united states, canada and other locations.
        while in another jurisdiction for processing, your personal data may be
        accessed by the courts, law enforcement, and national security
        authorities of that jurisdiction. these jurisdictions may not provide
        the same level of data protection as your home jurisdiction.
      </p>
      <br />
      <p className={styles.bold}>retention of personal data</p>
      <br />
      <p>
        we retain personal data in accordance with applicable law. unless
        otherwise required by applicable law, wonwonleywon will take reasonable
        steps to destroy or permanently de-identify personal data we hold if
        such personal data is no longer needed for the purpose for which it was
        collected.
      </p>
      <br />
      <p className={styles.bold}>personal data of children</p>
      <br />
      <p>
        the services are not intended for children under the age of 18.
        wonwonleywon does not target our services to children under 18. if we
        discover that we have inadvertently collected personal data from users
        who are under the age of 18, we will take necessary steps to remove the
        personal data from our servers. if we do use, process or store data from
        users under the age of 18, it is with the express consent of their legal
        guardian.
      </p>
      <br />
      <p className={styles.bold}>third-party sites</p>
      <br />
      <p>
        please note that our websites contain links to third-party websites that
        are not controlled or operated by wonwonleywon. if you follow a link to
        any of these websites, please note that these websites have their own
        privacy policies and that wonwonleywon does not accept any
        responsibility or liability for these policies. please review these
        policies before you disclose any personal data when visiting such
        third-party websites.
      </p>
      <br />
      <p className={styles.bold}>your choices</p>
      <br />
      <p>
        consistent with applicable law, you may exercise any of the choices
        described in this section. as further explained below, some of your
        choices may be submitted via our privacy portal. please note that we may
        ask you to verify your identity and request before taking further action
        on your request.
      </p>
      <br />
      <p className={styles.bold}>access and data portability</p>
      <br />
      <p>
        in certain jurisdictions, applicable law may entitle you to request
        access to or copies of your personal data stored by wonwonleywon. you
        may also be entitled to request copies of personal data that you have
        provided to us in a structured, commonly used, and machine-readable
        format and/or request us to transmit this information to another service
        provider (where technically feasible).
      </p>
      <br />
      <p className={styles.bold}>correction</p>
      <br />
      <p>
        you may request that we correct and update your account or profile
        information.
      </p>
      <br />
      <p className={styles.bold}>deletion</p>
      <br />
      <p>
        in certain jurisdictions, applicable law may entitle you to request
        deletion of your personal data stored by wonwonleywon via the privacy
        portal. please note that if you request the erasure of your personal
        data, we may retain and/or use your personal data to:
      </p>
      <p>
        <ul>
          <li>
            {"  "}•{"  "}exercise our legitimate business interests, such as
            fraud detection and prevention and enhancing safety against
            malicious, deceptive, fraudulent or illegal activity, and/or to
            prosecute those responsible for such activity;{" "}
          </li>
          <li>
            {"  "}•{"  "}establish, exercise or defend legal claims, or comply
            with applicable law;
          </li>
          <li>
            {"  "}•{"  "}perform our contract to which you are a party or in
            order to take steps at your request prior to entering into a
            contract;
          </li>
          <li>
            {"  "}•{"  "} perform a task carried out in the public interest or
            in the exercise of official authority vested in wonwonleywon;
          </li>
          <li>
            {"  "}•{"  "}
            {"  "}identify, debug and/or repair errors that impair intended
            functionality;
          </li>
          <li>
            {"  "}•{"  "}exercise free speech, and ensure the right of others to
            exercise their free speech or another right provided by law;
          </li>
          <li>
            {"  "}•{"  "}complete a transaction and/or provide a good or service
            requested by you or reasonably anticipated by you within the context
            of the business relationship, or to otherwise perform the contract;
          </li>
          <li>
            {"  "}•{"  "}protect your vital interests, or the vital interests of
            others; and,
          </li>
          <li>
            {"  "}•{"  "}as otherwise permitted under applicable law.
          </li>
        </ul>
      </p>
      <br />
      <p className={styles.bold}>marketing communications</p>
      <br />
      <p>
        you may opt out of receiving promotional communications from us by
        following the instructions in those communications. if you opt out, we
        may still send you non-promotional communications, such as those about
        your account or our ongoing business relations.
      </p>
      <br />
      <p className={styles.bold}>cookies and other tracking technology</p>
      <br />
      <p>
        wonwonleywon uses strictly necessary cookies that are essential for you
        to browse our website and use its features, such as accessing secure
        areas of our website. most web browsers are set to accept cookies by
        default. if you prefer, you can usually set your browser to remove or
        reject cookies. please follow your browser’s process for doing so.
        please note that if you choose to remove or reject cookies, this could
        affect the availability and functionality of our websites.
      </p>
      <br />
      <p className={styles.bold}>contact</p>
      <br />
      <p>
        if you have any questions or comments about this privacy policy, you may
        email us at{" "}
        <a href="mailto:support@wonwonleywon.com" style={{ color: "black" }}>
          support@wonwonleywon.com.
        </a>
      </p>
    </div>
  );
}
