import React, { Component } from "react";
import Text from "../../main/Text";
import BackButton from "../../main/BackButton";

class ShippingCost extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Text>
          we do not provide free shipping. to know the exact shipping cost, you
          must proceed to checkout and provide your shipping address.
        </Text>
      </>
    );
  }
}
export default ShippingCost;
