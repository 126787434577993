import React, { Component } from "react";
import Text from "../../main/Text";
import BackButton from "../../main/BackButton";

class DutiesTaxes extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Text>
          there are no duties payable if we ship our products to canada or the
          usa. if we ship our products outside of canada or the usa, you may be
          charged duties, customs charges, and other import fees. the shipping
          provider will collect these charges at the time of delivery. we do not
          know the amount of these costs, but you will know the specific amount
          at the time of delivery. you may contact your local customs office to
          ask what these additional costs could be prior to purchase. any
          duties, customs charges, and other import fees are your responsibility
          to pay.
        </Text>
      </>
    );
  }
}
export default DutiesTaxes;
