import React, { useEffect, useState } from "react";
import Line from "../../main/Line";
import BackButton from "../../main/BackButton";
import { NavLink } from "react-router-dom";
import classes from "../css/Home.module.css";
import axios from "../../../axios-backend";

const SingleProductsList = (props) => {
  const { type, areaAmount, gender } = props.location.state;
  const [loading, setLoading] = useState(true);
  const [singleProducts, setSingleProducts] = useState([]);
  useEffect(() => {
    axios
      .get(`/has-single-product`, {
        params: {
          id: type,
        },
      })
      .then((data) => {
        if (data.data.status === 200 && data.data.products.length) {

          setSingleProducts(data.data.products);
        }
        setLoading(false);
      });
  }, []);
  return !loading ? (
    <>
      <BackButton />
      {singleProducts.map((each) => {
        if(each.gender===gender || each.gender===2){
        return (
          <Line type="bottom-line" key={each.id}>
            <NavLink
              to={{
                pathname: "/products/productdetails",
                state: { productId: each.id, type, areaAmount, gender,singleProduct:true },
              }}
              className={classes.navLink}
            >
              {each.name}
            </NavLink>
          </Line>
        );
        }
      })}
      {singleProducts.length===0 && <Line type="bottom-line">No Products Available</Line>}
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />

      <Line />
    </>
  ) : null;
};

export default SingleProductsList;
