import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import classes from "./css/AdminHome.module.css";
import Line from "../main/Line";

class AdminHome extends Component {
  render() {
    return (
      <>
        <Line type="bottom-line" className={classes.navLink}>
          Admin Page
        </Line>
        <Line type="bottom-line" />
        <Line type="bottom-line">
          <NavLink to="/viewproducts/" className={classes.navLink}>
            Product List
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/orders/" className={classes.navLink}>
            order List
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/view-types/" className={classes.navLink}>
            collection List
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/view-sizes/" className={classes.navLink}>
            Size List
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/view-answers/" className={classes.navLink}>
            Size Finder Replies
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/view-members/" className={classes.navLink}>
            Members
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/view-products-auth/" className={classes.navLink}>
            Products Authentication
          </NavLink>
        </Line>
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="empty-line" />
      </>
    );
  }
}

export default AdminHome;
