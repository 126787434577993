import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import classes from "../../../../components/home/css/Home.module.css";
import Line from "../../../main/Line";
import BackButton from '../../../main/BackButton';





class ViewEpisodes extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Line type="bottom-line">
          <NavLink
            to="/world/episodes/viewepisodes/mind"
            className={classes.navLink}
          >
            Mind
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink
            to="/world/episodes/viewepisodes/body"
            className={classes.navLink}
          >
            {" "}
            Body
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink
            to="/world/episodes/viewepisodes/knowledge"
            className={classes.navLink}
          >
            Knowledge{" "}
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink
            to="/world/episodes/viewepisodes/joy"
            className={classes.navLink}
          >
            entertainment{" "}
          </NavLink>
        </Line>
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
      </>
    );
  }
}
export default ViewEpisodes;

