import React, { useState } from "react";
import axios from "../../axios-backend";
import classes from "../home/css/Authentication.module.css";
import BackButton from "../main/BackButton";
import ClickHere from "../main/ClickHere";
import Line from "../main/Line";

export default function ForgotPwd(props) {
  const [email, setEmail] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [msg, setMsg] = useState("");
  const clickHandle = () => {
    if (sessionStorage["resetTry"]) {
      alert(`sorry, you already tried to reset the password today, please try tomorrow.`);
      return;
    }
    const result = window.confirm("are you sure you want to reset the password?");
    if (result) {
      axios.get("/reset-pwd", { params: { email, cardNumber } }).then((data) => {
        // update-to-production
        if (data.data.status === 200) {
          setMsg("");
          sessionStorage["resetTry"] = 1;
          alert(`password associated with the card number provided, will be sent to the owner's email address within the next 7 business days.`);
          props.history.goBack();
        } else {
          setMsg("* please check your email or card number.");
        }
      });
    }
  };
  return (
    <>
      <BackButton />
      <Line type="empty-line">
        <span className={classes.span}>Enter owner's/your email address here</span>
      </Line>
      <Line type="bottom-line">
        <input
          type="text"
          className={classes.textbox}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </Line>
      <Line type="empty-line">
        <span className={classes.span}>enter your card number here</span>
      </Line>
      <Line type="bottom-line">
        <input
          type="text"
          className={classes.textbox}
          value={cardNumber}
          onChange={(e) => {
            setCardNumber(e.target.value);
          }}
        />
      </Line>
      <span className={classes.span}>{msg}</span>
      <Line type="bottom-line" />
      <Line type="bottom-line">
        confirm <ClickHere onClick={clickHandle} />
      </Line>
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
    </>
  );
}
