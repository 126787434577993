import React, { useState, useEffect, useRef } from 'react';
import BackButton from '../main/BackButton';
import Line from '../main/Line';
import axios from '../../axios-backend';
import Button from '../main/Button';
import { Link } from 'react-router-dom';

export default function ViewDimensions(props) {
    const [imgs, setImgs] = useState([]);
    const tidRef = useRef(props.match.params.id);
    useEffect(() => {
        loadImgData()
    }, []);
    const loadImgData = () => {
        axios.get(`/get-imgs-by-pid`, { params: { id: tidRef.current, type: 3 } }).then(data => {
            if (data.data.status === 200) {
                setImgs(data.data.imgs);
            }
        });
    }
    const handleImgDelete = (id) => {
        const result = window.confirm("Are you sure you want to delete this picture?");
        if (result) {
            axios.delete('/pictures', { data: { id } }).then(() => {
                loadImgData();
            });
        }
    }
    return (
        <>
            <BackButton />
            <Line type="bottom-line">
                <Link to={`/adpicture/${tidRef.current}?type=dimension`} style={{ margin: "0 1rem" }}>
                    <Button>Add Picture</Button>
                </Link>
            </Line>
            <div className="imgs-container">
                <div className="img-of-product">All the images: </div>  {imgs.map((item, index) => {
                    return (
                        <div className="img-of-product" key={index}>
                            <img src={process.env.REACT_APP_Url + item.url} width="150px" alt='img-product' height="150px" />
                            <div className="img-delete-button" onClick={() => { handleImgDelete(item.id) }}><i className="fas fa-window-close"></i></div>
                        </div>
                    )
                })}
            </div>
        </>
    );
}
