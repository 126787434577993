import React from 'react';
import Video from "../../main/Video";
import BackButton from '../../main/BackButton';
import axios from '../../../axios-backend'


const Measure = (props) => {

    const { type, gender } = props.location.state;
    const [videos, setVideos] = React.useState('')

    const getData = () => {

        axios.get('/get-pro-related-video', {
            params: {
                id: type, gender: `${gender}${gender}`
            }
        }).then((data) => {
            if (data.data.video) {
                setVideos(data.data.video)
            }
        })
    }

    React.useEffect(() => {
        getData()
    }, [])


    return (
        <>
            <BackButton />
            <Video url={`${videos}`} maxHeight="80%" />

        </>
    );
}


export default Measure;

