import React, { useEffect, useState, useRef } from "react";
import Line from "../../main/Line";
import BackButton from "../../main/BackButton";
import axios from "../../../axios-backend";
import Button from "../../main/Button";
import { Link } from "react-router-dom";

const ViewSizeFinders = (props) => {
  const paramsString = props.location.search.substring(1);
  const searchParams = new URLSearchParams(paramsString);
  const tidRef = useRef(searchParams.get("tid"));

  const [numberOfSteps, setNumberofSteps] = useState(0);
  const [sizeFinders, setSizeFinders] = useState([]);
  const [gender, setGender] = useState(2);
  const [id, setId] = useState();
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get("/size-finder", {
        params: { typeId: tidRef.current },
      })
      .then((data) => {
        if (data.data.data) {
          setSizeFinders(data.data.data);
        }
      });
  };

  const getUpdatingData = (id) => {
    axios
      .get("/size-finder-id", {
        params: { id },
      })
      .then((data) => {
        console.log(data);
        if (data.data.status === 200) {
          setGender(data.data.data[0].gender);
          setNumberofSteps(data.data.data[0].numberOfSteps);
          setId(id);
        }
      });
  }
  const deleteSizeFinder = (id) => {
    const result = window.confirm("Are you sure you want to delete this?");
    if (result) {
      axios.delete("/size-finder", { data: { id } }).then((data) => {
        if (data.data.status === 200) getData();
        else alert("Error");
      });
    }
  }

  const mapAndRenderSizeFinders = () => {
    return sizeFinders.map((item, index) => {
      return (
        <Line type="bottom-line" key={index}>
          Number of Steps: {item.numberOfSteps} Gender: {item.gender == 2 ? "unisex" : (item.gender == 1 ? "male" : "female")}
          <Button style={{ marginLeft: "2rem" }} onClick={() => { getUpdatingData(item.id) }}>Edit</Button>

          <Link style={{ marginLeft: "2rem" }} to={`/view-steps?id=${item.id}`}>
            <Button>View Steps</Button>
          </Link>
          <Button style={{ marginLeft: "2rem" }} onClick={() => { deleteSizeFinder(item.id) }}>Delete</Button>
        </Line>
      );
    });
  };

  const handleSubmit = () => {
    const params = {
      typeId: tidRef.current,
      numberOfSteps,
      gender
    }
    if (!id) {
      axios
        .post("/size-finder", params)
        .then((data) => {
          getData();
          setGender(2);
          setNumberofSteps('');
          setId(null);
        });
    } else {
      params.id = id;
      axios
        .patch("/size-finder", params)
        .then((data) => {
          getData();
          setGender(2);
          setNumberofSteps('');
          getData();
        });
    }
  };

  return (
    <>
      <BackButton />
      <Line type="bottom-line">
        if you add size finder for unisex, it will display on both women and men sides
      </Line>
      <>{mapAndRenderSizeFinders()}</>
      <Line type="bottom-line">
        <label htmlFor="gender">
          Gender:
        </label>
        <select
          id="gender"
          name="gender"
          value={gender}
          onChange={(e) => {
            setGender(e.target.value);
          }}
        >
          <option value="2">unisex</option>
          <option value="0">female</option>
          <option value="1">male</option>
        </select>
      </Line>
      <Line type="bottom-line">
        <label htmlFor="gender">
          Steps:
        </label>
        <input
          value={numberOfSteps}
          onChange={(e) => {
            setNumberofSteps(e.target.value);
          }}
        ></input>

      </Line>
      <Line type="bottom-line">
        <Button onClick={() => handleSubmit()}>
          Submit
        </Button>
      </Line>
    </>
  )
};

export default ViewSizeFinders;
