import React, { Component } from 'react';
import BackButton from '../../../../main/BackButton';
import Line from "../../../../main/Line";


class Joy extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Line type="bottom-line" style={{ paddingLeft: "1.5rem" }}>
          we currently have no content available
        </Line>
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
      </>
    );
  }
}
export default Joy;

