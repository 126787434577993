import React, { useState, useEffect, useRef } from "react";
import axios from "../../axios-backend";
import BackButton from "../main/BackButton";
import Button from "../main/Button";
import Line from "../main/Line";

const EditSize = (props) => {
  const [name, setName] = useState("");
  const [typeId, setTypeId] = useState(1);
  const [types, setTypes] = useState([]);
  const [gender, setGender] = useState(2);
  const [description, setDescription] = useState("");
  const [menDescription, setMenDescription] = useState("");
  const paramsString = props.location.search.substring(1);
  const searchParams = new URLSearchParams(paramsString);
  const idRef = useRef(searchParams.get("id"));

  useEffect(() => {
    axios.get("typelist").then((data) => {
      if (data.data.status === 200) {
        setTypes(data.data.types);
        setTypeId(data.data.types[0].val);
        if (idRef.current) {
          axios
            .get("/size", {
              params: {
                id: idRef.current,
              },
            })
            .then((data) => {
              if (data.data.size) {
                setName(data.data.size.name);
                setTypeId(data.data.size.typeId);
                setDescription(data.data.size.description)
                setMenDescription(data.data.size.menDescription)
              }
            });
        }
      }
    });

  }, []);

  const handleSubmit = () => {
    const params = { name, typeId, gender, description, menDescription };
    if (idRef.current) {
      params.id = idRef.current;
      axios.patch("/sizes", params).then(props.history.goBack());
    } else {
      axios.post("/sizes", params).then((data) => {
        props.history.goBack();
      });
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <BackButton />
      <Line type="bottom-line">
        <h4 className="labelFor">This is a panel for editing the size</h4>
      </Line>
      <Line type="bottom-line">
        <label htmlFor="name" className="labelFor">
          Size Name:{" "}
        </label>
        <input
          type="text"
          id="sizeName"
          name="sizeName"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label className="labelFor">Type: </label>
        <select
          value={typeId}
          onChange={(e) => {
            console.log(e.target.value);
            setTypeId(e.target.value);
          }}
        >
          {types.map((e, index) => {
            return (
              <option key={index} value={e.val}>
                {e.text}
              </option>
            );
          })}
        </select>
      </Line>
      <Line type="bottom-line">
        <label htmlFor="gender" className="labelFor">
          Gender:{" "}
        </label>
        <select
          value={gender}
          onChange={(e) => {
            setGender(e.target.value);
            console.log(e.target.value);
          }}
        >
          <option value="1">Male</option>
          <option value="0">Female</option>
          <option value="2">Unisex</option>
        </select>
      </Line>
      <Line type="bottom-line" className="text-area-admin">
        <label className="labelFor">
          Women Description:
        </label>
        <textarea
          value={description}
          style={{ width: "300px", marginLeft: "2rem" }}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line" className="text-area-admin">
        <label className="labelFor">
          Men Description:
        </label>
        <textarea
          value={menDescription}
          style={{ width: "300px", marginLeft: "2rem" }}
          onChange={(e) => {
            setMenDescription(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <Button className="labelFor" onClick={handleSubmit}>
          Add
        </Button>
      </Line>
      <Line type="bottom-line"></Line>
      <Line type="bottom-line"></Line>
      <Line type="bottom-line"></Line>
      <Line type="bottom-line"></Line>
    </div>
  );
};

export default EditSize;
