import React from 'react';
import ContentDiv from '../../../main/ContentDiv';
import Line from '../../../main/Line';
import ClickHere from '../../../main/ClickHere';

export default function StartOrEnd(props) {
    if (props.currentStep === 0) {
        return (
            <>
                <ContentDiv height="84.7%">
                    If you answer these {props.total - 1} questions, we will do our best
                    to advise you on the size to order. <br /><br />
                    *note - try to answer all questions, as the
                    more you answer, the better advice we provide. specifically, try to answer the questions that require
                    measuring your body, as those are the most
                    important.
                </ContentDiv>
                <Line type="top-line">
                    To begin/start <ClickHere onClick={() => {
                        props.nextStep();
                    }} />
                </Line>
            </>
        )
    } else {
        return (
            <ContentDiv height="90.4%">
                thank you. We analyze your results and will
                tell you what size to order within 7 business days.
            </ContentDiv>
        )
    }
}
