import React from 'react'
import Text from '../../main/Text';
import BackButton from '../../main/BackButton';

export default function Fundraiser() {
    return (
        <>
            <BackButton />
            <Text>
                we will host events such as fashion shows, sporting events, concerts, and auctions, in which we will donate one hundred percent of proceeds to charities that support people to be a better version of themselves. the charities will be associated with one of the four pillars that we believe are vital to enable people to become the best version of themselves: the mind, body, knowledge, or entertainment. for example, a charity that focuses on the mind could be a charity that helps people’s mental health. the specific charities we donate towards will be decided upon closer to the date of each fundraiser event.

            </Text>

        </>
    )
}