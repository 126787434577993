import React from 'react';
import BackButton from '../../../main/BackButton'
import Text from '../../../main/Text'

export default function Written() {
    return (
        <>
            <BackButton />
            <Text >
                our mission: support people to, be the best version of themselves
            </Text>

        </>
    )
}
