import React, { Component } from 'react';
import BackButton from '../../main/BackButton';
import Text from '../../main/Text';
import Video from '../../main/Video';
import videos from '../../../videos/testvideo.mp4';

class Club extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Video url={videos} />

        <Text>
          <p>
            Even though we are the most exclusive luxury brand in the world, Club wonwonleywon (The wonwonleywon Family), is where
            exclusivity meets inclusivity. Where exclusivity emerges inclusivity.
          </p>
          <p>
            The exclusivity is owning a product that no one else in the world owns.
          </p>
          <p>
            The inclusivity is becoming a member of our Club.
          </p>
          <p>
            Once you purchase one of our products, you will officially become a member of our Club.
          </p>
          <p>
            This Club, periodically provides complementary luxury experiences and services free of charge, to a random group of Club members.
          </p>
        </Text>
      </>
    );
  }
}
export default Club;

