import { PayPalButtons } from "@paypal/react-paypal-js";
import React from "react";
import axios from "../../../../axios-backend";

const PayPalCheckoutButton = (props) => {
  const {
    price,
    description,
    checkValidation,
    handleCreateOrder,
    setPending,
    pid,
    setErrormessage,
  } = props;
  return (
    <PayPalButtons
      style={{
        color: "black",
        layout: "horizontal",
        height: 40,
        tagline: false,
        shape: "pill",
      }}
      forceReRender={[checkValidation, handleCreateOrder]}
      onClick={async (data, actions) => {
        const result = await axios.get("/is-sold", { params: { id: pid } });
        console.log(result);
        if (result?.data.status != 200) {
          alert("sorry this product is already sold.");
          return actions.reject();
        }
        if (checkValidation() && result?.data.status === 200) {
          return actions.resolve();
        } else {
          return actions.reject();
        }
      }}
      createOrder={(data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              description,
              amount: {
                value: parseFloat(price).toFixed(2),
              },
            },
          ],
        });
      }}
      onApprove={async (data, actions) => {
        const order = await actions.order.capture();
        setPending(true);
        handleCreateOrder(order.id);
      }}
      onError={(err) => {
        setPending(false);
        alert("something wrong");
        console.error(err, "error");
      }}
      onCancel={(data, actions) => {
        console.log(data, actions);
      }}
    />
  );
};

export default PayPalCheckoutButton;
