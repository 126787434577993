import React, { useEffect, useState, useRef } from "react";
import Line from "../main/Line";
import BackButton from "../main/BackButton";
import axios from "../../axios-backend";
import Button from "../main/Button";

const AddEditSizeGuide = (props) => {
  const paramsString = props.location.search.substring(1);
  const searchParams = new URLSearchParams(paramsString);
  const idRef = useRef(searchParams.get("id"));
  const sidRef = useRef(searchParams.get("sid"));

  const [name, setName] = useState("");
  const [gender, setGender] = useState(0);
  const [index, setIndex] = useState("");
  const [fromInches, setFromInches] = useState("");
  const [toInches, setToInches] = useState("");
  const [fromCm, setFromCm] = useState("");
  const [toCm, setToCm] = useState("");
  const [description, setDescription] = useState("");
  const [sizeGuideId, setSizeGuideId] = useState(null);
  const [type, setType] = useState(1);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (!idRef.current) {
      return;
    } else {
      axios
        .get("/size-guide", {
          params: { id: idRef.current },
        })
        .then((data) => {
          if (data.data.status === 400) {
            return;
          } else {
            setSizeGuideId(data.data.data[0].id);
            setName(data.data.data[0].name);
            setIndex(data.data.data[0].index);
            setFromInches(data.data.data[0].fromInches);
            setToInches(data.data.data[0].toInches);
            setFromCm(data.data.data[0].fromCm);
            setToCm(data.data.data[0].toCm);
            setDescription(data.data.data[0].description);
            setType(data.data.data[0].type);
            setGender(data.data.data[0].gender);
          }
        });
    }
  };

  const handleSubmit = () => {
    if (sizeGuideId === null) {
      axios
        .post("/size-guide", {
          sizeId: sidRef.current,
          name,
          index,
          fromInches,
          toInches,
          fromCm,
          toCm,
          description,
          type,
          gender
        })
        .then((data) => {
          if (data.status === 200) {
            props.history.goBack();
          }
        });
    } else {
      axios
        .patch("/size-guide", {
          id: sizeGuideId,
          name,
          index,
          fromInches,
          toInches,
          fromCm,
          toCm,
          description,
          type,
          gender
        })
        .then((data) => {
          if (data.status === 200) {
            props.history.goBack();
          }
        });
    }
  };

  const handleDelete = () => {
    if (window.confirm("Do you really want to delete this size guide?")) {
      axios
        .delete("/size-guide", {
          data: {
            id: idRef.current,
          },
        })
        .then((data) => {
          if (data.status === 200) {
            props.history.goBack();
          }
        });
    } else {
      return;
    }
  };

  return (
    <>
      <BackButton />
      <Line type="bottom-line">
        <label style={divStyle} htmlFor="name">
          Name:
        </label>
        <input
          type="text"
          style={{ width: "300px", marginLeft: "2rem" }}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label htmlFor="gender" className="labelFor">
          Gender:{" "}
        </label>
        <select
          value={gender}
          style={{ width: "300px", marginLeft: "2rem" }}
          onChange={(e) => {
            setGender(e.target.value);
          }}
        >
          <option value="1">Male</option>
          <option value="0">Female</option>
        </select>
      </Line>
      <Line type="bottom-line">
        <label style={divStyle} htmlFor="index">
          {" "}
          Index:
        </label>
        <input
          type="text"
          value={index}
          style={{ width: "300px", marginLeft: "2rem" }}
          onChange={(e) => {
            setIndex(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label style={divStyle}>Type:</label>
        <select
          value={type}
          style={{ width: "300px", marginLeft: "2rem" }}
          onChange={(e) => {
            setType(e.target.value);
          }}
        >
          <option value={1}> Normal</option>
          <option value={2}> Less than</option>
          <option value={3}> Greater than</option>
          <option value={4}> Length</option>
        </select>
      </Line>
      <Line type="bottom-line">
        {type == 1 ? (
          <label style={divStyle}>From (inches)</label>
        ) : (
          <label style={divStyle}>Inches</label>
        )}
        <input
          type="text"
          value={fromInches}
          style={{ width: "300px", marginLeft: "2rem" }}
          onChange={(e) => {
            setFromInches(e.target.value);
          }}
        />
      </Line>
      {type == 1 ? (
        <Line type="bottom-line">
          <label style={divStyle}>To (inches):</label>
          <input
            type="text"
            value={toInches}
            style={{ width: "300px", marginLeft: "2rem" }}
            onChange={(e) => {
              setToInches(e.target.value);
            }}
          />
        </Line>
      ) : null}

      <Line type="bottom-line">
        {type == 1 ? (
          <label style={divStyle}>From (cm): </label>
        ) : (
          <label style={divStyle}>cm:</label>
        )}
        <input
          type="text"
          value={fromCm}
          style={{ width: "300px", marginLeft: "2rem" }}
          onChange={(e) => {
            setFromCm(e.target.value);
          }}
        />
      </Line>
      {type == 1 ? (
        <Line type="bottom-line">
          <label style={divStyle}>To (cm): </label>
          <input
            type="text"
            value={toCm}
            style={{ width: "300px", marginLeft: "2rem" }}
            onChange={(e) => {
              setToCm(e.target.value);
            }}
          />
        </Line>
      ) : null}

      <Line type="bottom-line" className="text-area-admin">
        <label style={divStyle}> Description: </label>
        <textarea
          value={description}
          style={{ width: "300px", marginLeft: "2rem" }}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </Line>

      <Line>
        <Button
          style={{ marginRight: "1rem" }}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
        {idRef.current === null ? null : (
          <Button onClick={() => handleDelete()}>Delete</Button>
        )}
      </Line>
    </>
  );
};

const divStyle = {

};

export default AddEditSizeGuide;
