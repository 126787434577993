import React, { Component } from 'react';
import Text from '../../main/Text';
import BackButton from '../../main/BackButton';
import { Link } from 'react-router-dom';
import styles from './css/Authentification.module.css';


class Authentification extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Text>
          to ensure people are not creating or selling fake wonwonleywon products, we have developed an <Link className={styles.underlineLink} to="/authenticity">authenticity portal</Link> displayed on the <Link className={styles.underlineLink} to="/home">homepage</Link> of this website. to prove you own a real wonwonleywon and not a fake product, you must be able to log in to the <Link className={styles.underlineLink} to="/authenticity">authenticity portal</Link>. if you cannot <Link className={styles.underlineLink} to="/authenticity">log in</Link>, you own a fake product. if you can <Link className={styles.underlineLink} to="/authenticity">log in</Link>, you will see photographs of the product with the matching authenticity card. you must own that product with the matching authenticity card to ensure you own a real wonwonleywon.
        </Text>
      </>
    );
  }
}
export default Authentification;

