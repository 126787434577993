import React, { useState, useEffect } from "react";
import BackButton from "../main/BackButton";
import Line from "../main/Line";
import axios from "../../axios-backend";
import Button from "../main/Button";
import Columns from "../main/Columns";
import ReactPaginate from "react-paginate";
import "./css/ViewProduct.css";

const ViewMembers = (props) => {
  const [members, setMembers] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    getData(0);
  }, []);

  const getData = (offset) => {
    axios
      .get("/members", {
        params: { offset, pageSize },
      })
      .then((p) => {
        setPageCount(p.data.pageCount);
        setMembers(p.data.list);
      });
  };

  const handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * pageSize);
    getData(offset);
  };

  const handleDelete = (id) => {
    const result = window.confirm(
      "Are you sure you want to delete this member?"
    );
    if (result) {
      axios.delete("/member", { data: { id } }).then(() => {
        getData(0);
      });
    }
  };

  const renderMembers = () => {
    if (members.length > 0) {
      return members.map((m) => {
        return (
          <Line type="bottom-line" key={m.id}>
            <Columns width="25%">{m.id}</Columns>
            <Columns width="25%">{m.email}</Columns>
            <Columns width="50%">
              <Button onClick={() => handleDelete(m.id)}>Delete</Button>
            </Columns>
          </Line>
        );
      });
    } else
      return (
        <Line type="bottom-line" style={{ justifyContent: "center" }}>
          loading...
        </Line>
      );
  };

  return (
    <div style={{ height: "100%" }}>
      <BackButton />
      <Line type="bottom-line">
        <Columns width="25%">id</Columns>
        <Columns width="25%">Email </Columns>
        <Columns width="50%">command</Columns>
      </Line>
      {renderMembers()}
      <Line type="bottom-line">
        <div className="paginate-container">
          <ReactPaginate
            previousLabel={"←"}
            nextLabel={"→"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={pageSize}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"bolder-a"}
            disabledClass={"disable-a"}
          />
        </div>
      </Line>
    </div>
  );
};

export default ViewMembers;
