import React, { Component } from 'react';
import Text from '../../main/Text';
import BackButton from '../../main/BackButton';
import { Link } from 'react-router-dom';


class Products extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Text>
          #1. the products we produce and sell are: clothing, bags, shoes, jewelry, watches, eyewear, home products, transportation products, and miscellaneous products.
          <br />
          <br />
          #2. some products will have one, two, or more colors on the product. however, most products will have one main color with additional colors on the product.
          <br />
          <br />
          #3. every product is 1 of 1. lives by the law known as <Link style={{ color: "black" }} to="/world/oneofoneluxury">one of one luxury</Link>.
          <br />
          <br />
          #4. we use color to create products that are 1 of 1. the colors will only and always be picked from the following: black, blue, brown, green, grey, orange, pink, purple, red, white, and yellow. these colors shall be known as the wonwonleywon brand colors.
          <br />
          <br />
          #5. we also create products that are 1 of 1 not linked to any collection. these are singular 1 of 1 products.
        </Text>

      </>
    );
  }
}
export default Products;

