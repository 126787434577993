import React, { Component } from 'react';
import Line from "../../../../main/Line";
import BackButton from '../../../../main/BackButton';


class Mind extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Line type="bottom-line" style={{ paddingLeft: "1.5rem" }}>
          we currently have no content available
        </Line>
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
      </>
    );
  }
}
export default Mind;

