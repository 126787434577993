import React from "react";
import Video from "../../main/Video";
import BackButton from "../../main/BackButton";

const HowToChangeColors = () => {
  return (
    <>
      <BackButton url="/allvideos" />
      <Video
        url={`https://yuriy-dmytrash19.wistia.com/medias/yafptkj0hf`}
        maxHeight="80%"
      />
    </>
  );
};

export default HowToChangeColors;
