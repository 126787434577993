import React, { useState } from 'react';
import styles from './css/VideoPlayer.module.css';
import { MdClose } from "react-icons/md";
import ReactPlayer from 'react-player';
import "./css/video.css";

export default function VideoPlayer(props) {
    const [playing, setPlaying] = useState(true);

    return (
        <div className={styles.container} style={{ display: props.show ? "flex" : "none" }}>
            <MdClose className={styles.closeBtn} onClick={() => {
                props.closeFun();
                setPlaying(false);
            }} />
            <div className="video-wrapper">
                <ReactPlayer
                    playing={props.show}
                    url={props.url}
                    controls
                    width="100%"
                    height="100%" />
            </div>
        </div>
    )
}
