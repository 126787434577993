import React, { useEffect, useState, useRef } from "react";
import Line from "../../main/Line";
import BackButton from "../../main/BackButton";
import axios from "../../../axios-backend";
import Button from "../../main/Button";
import { Link } from "react-router-dom";

const AdOption = (props) => {
  const paramsString = props.location.search.substring(1);
  const searchParams = new URLSearchParams(paramsString);
  const idRef = useRef(searchParams.get("id"));
  const sidRef = useRef(searchParams.get("sid"));

  const [content, setContent] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (!idRef.current) {
      return;
    } else {
      axios
        .get("/option", {
          params: { id: idRef.current },
        })
        .then((data) => {
          setContent(data.data.data[0].content);
          setIndex(data.data.data[0].index);
        });
    }
  };

  const handleSubmit = () => {
    if (idRef.current === null) {
      axios
        .post("/option", {
          stepsId: sidRef.current,
          content,
          index,
        })
        .then((data) => {
          if (data.status === 200) {
            props.history.goBack();
          }
        });
    } else {
      axios
        .patch("/option", {
          id: idRef.current,
          content,
          index,
        })
        .then((data) => {
          if (data.status === 200) {
            props.history.goBack();
          }
        });
    }
  };

  const handleDelete = () => {
    if (window.confirm("Do you really want to delete this option?")) {
      axios
        .delete("/option", {
          data: {
            id: idRef.current,
          },
        })
        .then((data) => {
          if (data.status === 200) {
            props.history.goBack();
          }
        });
    } else {
      return;
    }
  };

  return (
    <>
      <BackButton />
      <Line type="bottom-line">if you are adding options for "three options" or "multiple options", please set the index to 0, 1, 2, 3...</Line>
      <Line type="bottom-line">
        <label>Content:</label>
        <input
          id="name"
          style={{ width: "300px", marginLeft: "2rem" }}
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        Index:
        <input
          type="text"
          value={index}
          style={{ width: "300px", marginLeft: "2rem" }}
          onChange={(e) => {
            setIndex(e.target.value);
          }}
        />
      </Line>
      <Line>
        <Button style={{ marginRight: "2rem" }} onClick={() => handleSubmit()}>
          Submit
        </Button>
        {idRef.current === null ? null : (
          <Button onClick={() => handleDelete()}>Delete</Button>
        )}
      </Line>
    </>
  );
};

export default AdOption;
