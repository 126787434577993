import React, { Component } from 'react';
import Text from "../../main/Text";
import BackButton from '../../main/BackButton';



class ContactUs extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Text>for any questions, email us at <a href="mailto:support@wonwonleywon.com" style={{ color: "black" }}>support@wonwonleywon.com</a></Text>
      </>
    );
  }
}
export default ContactUs;

