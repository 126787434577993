import React, { useEffect, useState, useRef } from "react";
import Line from "../main/Line";
import BackButton from "../main/BackButton";
import axios from "../../axios-backend";
import Button from "../main/Button";
import { Link } from "react-router-dom";
import Columns from "../main/Columns";

const ViewSizeGuides = (props) => {
  const paramsString = props.location.search.substring(1);
  const searchParams = new URLSearchParams(paramsString);
  const idRef = useRef(searchParams.get("id"));
  const [sizeGuides, setSizeGuides] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (idRef.current !== null) {
      axios
        .get("/size-guides", {
          params: {
            sizeId: idRef.current,
          },
        })
        .then((data) => {
          console.log(data.data.data);
          setSizeGuides(data.data.data);
        });
    } else {
      return;
    }
  };

  const mapAndRenderSizeGuides = () => {
    return sizeGuides.map((item, index) => {
      return (
        <Line type="bottom-line" key={index}>
          <Columns width="15%">index: {item.index}</Columns>
          <Columns width="20%">{item.name}</Columns>
          <Columns width="20%">{item.gender ? "Men" : "Women"}</Columns>
          <Columns width="45%">
            <Link
              style={{ marginLeft: "2rem" }}
              to={`/edit-size-guide?id=${item.id}&sid=${idRef.current}`}
            >
              <Button>Edit</Button>
            </Link>
            <Link
              style={{ marginLeft: "2rem" }}
              to={`/add-size-guide-img?id=${item.id}&type=imageUrl`}
            >
              <Button>Edit small Image</Button>
            </Link>
            <Link
              style={{ marginLeft: "2rem" }}
              to={`/add-size-guide-img?id=${item.id}&type=largeImgUrl`}
            >
              <Button>Edit large Image</Button>
            </Link> </Columns>
        </Line>
      );
    });
  };

  return (
    <>
      <BackButton />
      <Line type="bottom-line" style={{ justifyContent: "space-around" }}>
        <Columns width="15%">Size Guide List</Columns>
        <Columns width="20%">Name</Columns>
        <Columns width="20%">Gender</Columns>
        <Columns width="45%">
          <Link to={`/edit-size-guide?sid=${idRef.current}`}>
            <Button> Add New Size Guide </Button>
          </Link></Columns>

      </Line>
      {mapAndRenderSizeGuides()}
    </>
  );
};

export default ViewSizeGuides;
