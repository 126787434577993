import React, { useEffect, useState } from 'react';
import classes from './css/ColorPicker.module.css';
import BackButton from '../../main/BackButton';
import axios from '../../../axios-backend';
import Line from '../../main/Line';
import { NavLink } from "react-router-dom";


export default function SizePicker(props) {
    const { type, areaAmount, gender } = props.location.state;

    const [list, setList] = useState([])
    useEffect(() => {
        axios.get(`/sizes-by-type`, { params: { 'tid': type, gender } }).then(data => {
            if (data.data.status === 200) {
                if (data.data.list.length === 0) {
                    alert('sorry, no product');
                    props.history.goBack();
                }
                else
                    setList(data.data.list)
            }
        });
    }, []);
    const addLines = () => {
        const temp = [];
        for (let i = 0; i < 10 - list.length; i++) {
            temp.push(
                <Line key={i} type="bottom-line" />
            )
        }
        return temp;
    }
    return (
        <>
            <BackButton />
            <Line className={classes.centerText} type="bottom-line">
                pick your size
            </Line>
            {list.map((e, index) => {
                return (
                    <Line key={index} className={classes.centerText} type="bottom-line">
                        <NavLink to={{ pathname: "/products/shopproduct", state: { areaAmount, type, gender, size: e.size } }}
                            className={classes.sizeLink}>
                            {e.size}
                        </NavLink>
                    </Line>
                )
            })}
            <Line type="bottom-line">
                <NavLink to={{ pathname: "/products/productsizeguide", state: { areaAmount, type, gender } }}
                    className={classes.sizeLink}>
                    Find your size
                </NavLink>
            </Line>
            {
                list.length < 10 ?
                    addLines() : null
            }
        </>
    )
}

