import React, { Component } from "react";
import BackButton from "../../main/BackButton";
import Text from "../../main/Text";

class Episodes extends Component {
  render() {
    return (
      <>
        <BackButton />
        <div>
          <Text>
            follow our{" "}
            <a href="https://www.instagram.com/wonwonleywon/" target="_blank" style={{ color: "black", textDecoration: "underline" }}>
              {" "}
              instagram
            </a>{" "}
            account of wonwonleywon to view all recent content.
          </Text>
        </div>
      </>
    );
  }
}
export default Episodes;
