import React, { Component } from 'react';
import BackButton from '../../main/BackButton';
import Text from '../../main/Text';


class Support extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Text>

        to a select group of individuals, we will provide support through connections, mentorship, capital, public exposure, and other methods to get them closer to being the best version of themselves. suppose you have a talent, musically or artistically, or have a business plan concept in its early stage or late stage. in that case, we want to support your vision. all you must do is send us a one-minute video showing your talent or explaining your business plan with your request. your request can be any of the following items we provide: connections with a specific person or entity, mentorship, connections with mentors, capital (money), connections with capital (connections to money), business plan support, and or public exposure. note, we will only respond to people we believe we can help and to people that we can get them closer to being the best version of themselves. send any video applications to support@wonwonleywon.com. note, our current focus is to support individuals in the music industry.
        </Text>

      </>
    );
  }
}
export default Support;

