import React, { useEffect, useRef, useState } from "react";
import axios from "../../axios-backend";
import BackButton from "../main/BackButton";
import Button from "../main/Button";
import Line from "../main/Line";
import "./css/AdProducts.css";

const AdProducts = (props) => {
  const [name, setName] = useState("");
  const [priceCanada, setPriceCanada] = useState("");
  const [priceUs, setPriceUs] = useState("");
  const [sizes, setSizes] = useState([]);
  const [productSize, setProductSize] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [status, setStatus] = useState(1);
  const [typeId, setTypeId] = useState("");
  const [gender, setGender] = useState(2);
  const [color, setColor] = useState("");
  const [types, setTypeList] = useState([]);

  const [password, setPassword] = useState("");
  const [sizeId, setSizeId] = useState(0);
  const [removeShipmentCharge, setRemoveShipmentCharge] = useState(false);
  const [singleproduct, setSingleProduct] = useState(false);

  const paramsString = props.location.search.substring(1);
  const searchParams = new URLSearchParams(paramsString);
  const idRef = useRef(searchParams.get("id"));

  useEffect(() => {
    axios.get("/typelist").then((data) => {
      if (data.data.status === 200) {
        setTypeList(data.data.types);
        setTypeId(data.data.types[0].val);
        if (!idRef.current) {
          getSizeList(data.data.types[0].val);
          getTypeIdGender(data.data.types[0].val);
        }
      }
    });
    if (idRef.current) {
      axios
        .get(`/products/${idRef.current}`, {
          data: {
            id: idRef.current,
          },
        })
        .then((data) => {
          setName(data.data[0].name);
          setPriceCanada(data.data[0].priceCanada);
          setPriceUs(data.data[0].priceUs);
          setProductSize(data.data[0].size);
          setCardNumber(data.data[0].cardNumber);
          setStatus(data.data[0].status);
          setTypeId(data.data[0].typeId);
          setGender(data.data[0].gender);
          setSizeId(data.data[0].sizeId);
          setPassword(data.data[0].password);
          setRemoveShipmentCharge(data.data[0]?.removeShipmentCharge || false);
          setSingleProduct(data.data[0]?.singleproduct || false);
          const colorArr = [];
          for (let item in data.data[0].color) {
            colorArr.push(data.data[0].color[item]);
          }
          setColor(colorArr.join("-"));
          getSizeList(
            data.data[0].typeId,
            data.data[0].sizeId,
            data.data[0].size
          );
        });
    }
  }, []);

  const handleSubmit = () => {
    const params = {
      name,
      priceCanada,
      priceUs,
      productSize,
      cardNumber,
      password,
      status,
      typeId,
      gender,
      color: color.split("-"),
      sizeId,
      removeShipmentCharge,
      singleproduct,
    };
    if (
      (!singleproduct &&
        !(name && priceUs && cardNumber && typeId && color.length !== 0)) ||
      (singleproduct &&
        !(name && priceUs && cardNumber && typeId))
    ) {
      alert("please fill all the blanks");
      return;
    }
    if (idRef.current) {
      params.productId = idRef.current;
      axios.patch("/products", params).then((data) => {
        props.history.goBack();
      });
    } else {
      axios.post("/products", params).then(() => {
        props.history.goBack();
      });
    }
  };

  const handleChangeType = (e) => {
    if (e.target.value) {
      getSizeList(e.target.value);
      getTypeIdGender(e.target.value)
    }
  };

  const getTypeIdGender = (typeId) => {
    axios.get("/type-id-gender", {params: {tid: typeId}}).then((data)=>{
      setGender(data.data.list[0].gender)
    })
  }

  const getSizeList = (typeId, val, name) => {
    axios.get("/sizelist-by-tid", { params: { tid: typeId } }).then((sizes) => {
      if (sizes.data.status === 200 && sizes.data.list.length !== 0) {
        setSizes(sizes.data.list);
        if (val) {
          setSizeId(val);
          setProductSize(name);
        } else {
          setProductSize(sizes.data.list[0].text);
          setSizeId(sizes.data.list[0].val);
        }
      } else {
        setSizes([]);
        setProductSize("");
        setSizeId(0);
      }
    });
  };

  useEffect(()=>{
    if(typeId!==""){
      axios.get(`/type?id=${typeId}`).then((data) => {
        if(data.data.type.singlecollection){
          setSingleProduct(true);
        }else{
          setSingleProduct(false)
        };
      });
    }
  },[typeId])
  
  return (
    <div style={{ height: "100%" }}>
      <BackButton />
      <Line type="bottom-line">
        <h4 className="labelFor">This is a panel for adding the products</h4>
      </Line>
      <Line type="bottom-line">
        <label htmlFor="name" className="labelFor">
          Product Name:
        </label>
        <input
          type="text"
          id="productName"
          name="productName"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          style={{ width: "200px" }}
        />
      </Line>

      <Line type="bottom-line">
        <label htmlFor="priceUs" className="labelFor">
          Product Price(US):
        </label>
        <input
          type="text"
          id="priceUs"
          name="priceUs"
          value={priceUs}
          onChange={(e) => {
            setPriceUs(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label htmlFor="typeId" className="labelFor">
          collection :
        </label>
        <select
          id="typeId"
          name="typeId"
          value={typeId}
          onChange={(e) => {
            setTypeId(e.target.value);
            handleChangeType(e);
          }}
        >
          {types.map((e, index) => {
            return (
              <option key={index} value={e.val}>
                {e.text}
              </option>
            );
          })}
        </select>
      </Line>
      {/* <Line type="bottom-line">
        <label htmlFor="singleproduct" className="labelFor">
          is single product:
        </label>
        <input
          type="checkbox"
          id="singleproduct"
          style={{
            appearance: "auto",
            "-moz-appearance": "auto",
            "-webkit-appearance": "auto",
            marginTop: "8px",
            width: "15px",
            height: "15px",
          }}
          name="singleproduct"
          value={singleproduct}
          checked={singleproduct}
          onChange={(e) => {
            setSingleProduct(e.target.checked);
          }}
        />
      </Line> */}
      {!singleproduct && <Line type="bottom-line">
        <label htmlFor="productSize" className="labelFor">
          Product Size:
        </label>
        <select
          id="productSize"
          name="productSize"
          value={sizeId + "@" + productSize}
          onChange={(e) => {
            const arr = e.target.value.split("@");
            setProductSize(arr[1]);
            setSizeId(arr[0]);
          }}
        >
          {sizes.map((e, index) => {
            return (
              <option key={index} value={e.val + "@" + e.text}>
                {e.text}
              </option>
            );
          })}
        </select>
      </Line>}
      <Line type="bottom-line">
        <label htmlFor="cardNumber" className="labelFor">
          Card Number:
        </label>
        <input
          type="text"
          id="cardNumber"
          name="cardNumber"
          maxLength="16"
          value={cardNumber}
          onChange={(e) => {
            setCardNumber(e.target.value);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <label htmlFor="status" className="labelFor">
          Status :
        </label>
        <select
          id="status"
          name="status"
          value={status}
          onChange={(e) => {
            setStatus(e.target.value);
          }}
        >
          <option value="1">available</option>
          <option value="0">sold</option>
        </select>
      </Line>

      <Line type="bottom-line">
        <label htmlFor="gender" className="labelFor">
          Gender :
        </label>
        <select
        disabled= "true"
          id="gender"
          name="gender"
          value={gender}
          onChange={(e) => {
            setGender(e.target.value);
          }}
        >
          <option value="2">unisex</option>
          <option value="0">female</option>
          <option value="1">male</option>
        </select>
      </Line>
      {!singleproduct && <Line type="bottom-line">
        <label htmlFor="color" className="labelFor">
          Colors (please enter all the colors of this product, seperated with a
          dash without space):
        </label>
        <input
          type="text"
          id="color"
          name="color"
          placeholder="For example, red-blue-yellow-black"
          style={{ width: "220px" }}
          value={color}
          onChange={(e) => {
            setColor(e.target.value);
          }}
        />
      </Line>}
      {/* <Line type="bottom-line">
        <label htmlFor="priceCanada" className="labelFor">
          Product Price For displaying on details page:
        </label>
        <input
          type="text"
          id="priceCanada"
          name="priceCanada"
          value={priceCanada}
          onChange={(e) => {
            setPriceCanada(e.target.value);
          }}
        />
      </Line> */}
      {/* <Line type="bottom-line">
        <label className="labelFor">tracking:</label>
        <input type="text" style={{ width: "220px" }} value={tracking} onChange={e => { setTracking(e.target.value) }} />
      </Line> */}
      <Line type="bottom-line">
        <label htmlFor="removeshipmentcharge" className="labelFor">
          Remove Shipment Charges:
        </label>
        <input
          type="checkbox"
          id="removeshipmentcharge"
          style={{
            appearance: "auto",
            "-moz-appearance": "auto",
            "-webkit-appearance": "auto",
            marginTop: "8px",
            width: "15px",
            height: "15px",
          }}
          name="removeshipmentcharge"
          value={removeShipmentCharge}
          checked={removeShipmentCharge}
          onChange={(e) => {
            setRemoveShipmentCharge(e.target.checked);
          }}
        />
      </Line>
      <Line type="bottom-line">
        <Button type="submit" className="labelFor" onClick={handleSubmit}>
          {idRef.current ? "Update" : "Add"}
        </Button>
      </Line>
    </div>
  );
};

export default AdProducts;
