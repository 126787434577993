import React, { useState } from "react";
import Button from "../main/Button";
import BackButton from "../main/BackButton";
import Line from "../main/Line";
import axios from "../../axios-backend";

const AdVideo = (props) => {
  const [video, setVideo] = useState();

  const handleSave = () => {
    let formData = new FormData();
    formData.append("productId", props.match.params.id);
    formData.append("video", video);

    axios({
      method: "post",
      url: "/upload-video",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((data) => {
        console.log(data);
        if (data.data.status === 200) {
          props.history.goBack();
        } else {
          alert(data.data.msg);
        }
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const handleChangeVideo = (e) => {
    const file = e.target.files[0];
    if (file) {
      setVideo(file);
    } else {
      alert("please select a file");
      return;
    }
  };
  return (
    <div style={{ height: "100%" }}>
      <BackButton />
      <Line type="bottom-line">Page for adding video to product</Line>
      <Line type="bottom-line">
        <input
          type="file"
          accept="video/*"
          onChange={handleChangeVideo}
          name="file"
          multiple
        />
      </Line>
      <Line type="bottom-line">
        <Button onClick={handleSave}>Upload</Button>
      </Line>
      <Line type="bottom-line"></Line>
      <Line type="bottom-line"></Line>
      <Line type="bottom-line"></Line>
      <Line type="bottom-line"></Line>
      <Line type="bottom-line"></Line>
      <Line type="bottom-line"></Line>
      <Line type="bottom-line"></Line>
      <Line type="bottom-line"></Line>
    </div>
  );
};

export default AdVideo;
