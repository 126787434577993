import React from 'react';
import styles from './css/ClickHere.module.css'

export default function ClickHere(props) {
    const { onClick, className, ...rest } = props;
    return (
        <button onClick={onClick} className={styles.clickHere + " " + (className ? className : "")} {...rest}>
            [click here]
        </button>
    )
}
