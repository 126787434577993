import React, { useEffect } from 'react';
import styles from './css/Landing.module.css';
import { Link } from "react-router-dom";


export default function Landing() {
    useEffect(() => {
        const inteval = setInterval(() => {
            window.location.href = "/home";
        }, 5000);
        return () => {
            clearInterval(inteval)
        }
    }, [])
    return (
        <Link className={styles.landingDiv} to="/home" >
            <div className={styles.mobileDiv}>
                <p>the most exclusive</p>
                <p>luxury brand in the world,</p>
                <p>with a philanthropic mission</p>
            </div>
            <div className={styles.desktopDiv}>
                <p>the most exclusive luxury brand in the world, with a philanthropic mission</p>
            </div>
        </Link>
    )
}
