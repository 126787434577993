import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../../axios-backend";
import Line from "../main/Line";
import Button from "../main/Button";
import BackButton from "../main/BackButton";
import Columns from "../main/Columns";

const Sizes = (props) => {
  const [sizes, setSizes] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios.get("/sizes").then((s) => {
      setSizes(s.data.list);
      console.log(s);
    });
  };

  const handleRedirect = () => {
    props.history.push("/edit-size");
  };

  const handleDelete = (id) => {
    const result = window.confirm("Are you sure you want to delete this size?");
    if (result) {
      axios.delete("/sizes", { data: { id } }).then((data) => {
        if (data.data.status === 200) getData();
        else alert("Error");
      });
    }
  };

  const renderTypes = () => {
    if (sizes.length > 0) {
      return sizes.map((s) => {
        return (
          <Line type="bottom-line" key={s.id}>
            <Columns width="25%">{s.name}</Columns>
            <Columns width="25%">{s.typeName}</Columns>
            <Columns width="25%">
              {s.gender === "0"
                ? "female"
                : s.gender === "1"
                ? "male"
                : "unisex"}
            </Columns>
            <Columns width="25%">
              <Link
                to={`/edit-size?id=${s.id}`}
                style={{ marginRight: "1rem" }}
              >
                <Button>Update</Button>
              </Link>
              <Link
                to={`/view-size-guides?id=${s.id}`}
                style={{ marginRight: "1rem" }}
              >
                <Button>Size guides</Button>
              </Link>
              <Button onClick={() => handleDelete(s.id)}>Delete</Button>
            </Columns>
          </Line>
        );
      });
    } else
      return (
        <Line type="bottom-line" style={{ justifyContent: "center" }}>
          loading...
        </Line>
      );
  };

  return (
    <div style={{ height: "100%" }}>
      <BackButton />
      <Line type="bottom-line">
        <Columns width="25%">size name</Columns>
        <Columns width="25%">belongs to</Columns>
        <Columns width="25%">gender</Columns>
        <Columns width="25%">
          <Button onClick={handleRedirect}>Add new size</Button>
        </Columns>
      </Line>
      {renderTypes()}
    </div>
  );
};

export default Sizes;
