import React, { Component } from 'react';
import Text from "../../main/Text";
import BackButton from '../../main/BackButton';



class InternationalTransactionFees extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Text>because all of our prices are in usd, if your bank does not normally transact in usd, you may be charged an additional fee from your bank. we do not know the amount. however, we assume it should be minimal. </Text>
      </>
    );
  }
}
export default InternationalTransactionFees;

