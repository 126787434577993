import React from 'react';
import Video from '../../../main/Video';
import BackButton from '../../../main/BackButton';
import axios from '../../../../axios-backend'

export default function ProductRelatedVideo(props) {
    const paramsString = props.location.search.substring(1);
    const searchParams = new URLSearchParams(paramsString);
    const productId = searchParams.get('pid');
    const [videos, setVideos] = React.useState('')

    React.useEffect(() => {
        axios.get('get-vid-by-pid', {
            params: {
                id: productId
            }
        }).then((data) => {
            console.log(data)
            if (data.data.status === 200 && data.data.vids[0]) {
                setVideos(data.data.vids[0].url)
            }
        })
    }, [])


    return (
        <>
            <BackButton />
            <Video url={`${process.env.REACT_APP_Url}${videos}`} maxHeight="80%" />
        </>
    );
}
