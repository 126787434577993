import React, { Component } from 'react';
import Text from "../../main/Text";
import BackButton from '../../main/BackButton';



class Refund extends Component {
  render() {
    return (
      <>

        <BackButton />

        <Text>Refund </Text>

      </>
    );
  }
}
export default Refund;

