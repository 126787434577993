import React, { useState } from 'react';
import styles from './css/EmailBox.module.css';
import ButtonsLine from './ButtonsLine';
import axios from '../../../../axios-backend';
import PolicyLine from '../../../main/PolicyLine'
import Line from '../../../main/Line';
import PrivacyPolicy from '../../../main/PrivacyPolicy'
import TermsAndConditions from '../../../main/TermsAndConditions'

export default function EmailBox(props) {
    const [email, setEmail] = useState('');
    const [showPolicy, setShowPolicy] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const { shortName, sIndex } = props.step;
    const changeAnswer = (val) => {
        const temp = JSON.parse(JSON.stringify(props.answer));
        temp[sIndex] = { shortName, val };
        props.setAnswer(temp);
        return temp;
    }
    const submitAnswer = (answer) => {
        if (email) {
            axios
                .post("/answers", { typeId: props.tid, email, result: answer })
                .then((data) => {
                    if (data.data.status === 200) {
                        props.nextStep();
                    } else {
                        alert('sorry, please try again later');
                    }
                });
        } else {
            alert('insert your email address')
        }

    }
    return (
        <>
            <div className={styles.emailBoxContainer}>
                Insert email here
                <input type="text" value={email} onChange={(e) => { setEmail(e.target.value) }} className={styles.emailBox} />
            </div>
            <ButtonsLine
                preStep={props.preStep}
                nextStep={() => {
                    const temp = changeAnswer(email);
                    submitAnswer(temp);
                }}
                hideSkip
                skipStep={() => {
                    const temp = changeAnswer('skip');
                    submitAnswer(temp);
                }}
            />
            <PolicyLine type="top-line" clickTerms={() => { setShowTerms(true) }} clickPolicy={() => { setShowPolicy(true) }} />
            <Line type="top-line" />
            <PrivacyPolicy show={showPolicy} closeFun={() => { setShowPolicy(false) }} />
            <TermsAndConditions show={showTerms} closeFun={() => { setShowTerms(false) }} />
        </>
    )
}
