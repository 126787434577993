import React, { useState } from "react";
import BackButton from "../../main/BackButton";
import ClickHere from "../../main/ClickHere";
import Line from "../../main/Line";
import Text from "../../main/Text";
import styles from "./css/Member.module.css";
import axios from "../../../axios-backend";
import PolicyLine from "../../main/PolicyLine";
import PrivacyPolicy from "../../main/PrivacyPolicy";
import TermsAndConditions from "../../main/TermsAndConditions";

export default function Membership(props) {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [showPolicy, setShowPolicy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const validate = (email) => {
    const expression =
      /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

    return expression.test(String(email).toLowerCase());
  };
  const handleSubmit = () => {
    if (!email || !validate(email)) {
      alert("please input a correct email.");
      return;
    }
    const isMember = parseInt(sessionStorage["isMember"]);
    if (isMember > 5) {
      alert("sorry. please try again in a few minutes.");
    } else {
      sessionStorage["isMember"] = isMember + 1;
      axios
        .post("/member", { email })
        .then((data) => {
          if (data.data.status === 200) {
            alert("received.");
            props.history.goBack();
          } else {
            alert("sorry. please try again in a few minutes.");
          }
        })
        .catch((error) => {
          alert("sorry. please try again in a few minutes.");
        });
    }
  };
  return (
    <>
      <BackButton />
      <Text>
        to know when we release a new collection or new products, become a member, and enter your email address
        <span
          style={{
            textDecoration: "underline",
            display: show ? "none" : "unset",
            cursor: "pointer",
            marginLeft: "3px",
          }}
          onClick={() => {
            setShow(true);
          }}
        >
          here
        </span>
        .
        <div style={{ display: show ? "block" : "none", marginTop: "1rem" }}>
          <input
            type="email"
            value={email}
            className={styles.inputBox}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
      </Text>
      {show ? (
        <>
          <Line type="top-line" style={{ display: show ? "flex" : "none" }}>
            confirm
            <ClickHere
              onClick={() => {
                handleSubmit();
              }}
            />
          </Line>
          <PolicyLine
            type="top-line"
            clickTerms={() => {
              setShowTerms(true);
            }}
            clickPolicy={() => {
              setShowPolicy(true);
            }}
          />
          <Line type="top-line" />
          <PrivacyPolicy
            show={showPolicy}
            closeFun={() => {
              setShowPolicy(false);
            }}
          />
          <TermsAndConditions
            show={showTerms}
            closeFun={() => {
              setShowTerms(false);
            }}
          />
        </>
      ) : null}
    </>
  );
}
