import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from "../../components/home/css/Home.module.css";
import Line from "../main/Line";
import BackButton from '../main/BackButton';

export default function Products() {
  return (
    <>
      <BackButton />
      <Line type="bottom-line">
        <NavLink to="/women" className={classes.navLink}>
          women
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/men" className={classes.navLink}>
          Men
        </NavLink>
      </Line>
      <Line type="bottom-line">
      </Line>
      <Line type="bottom-line">
      </Line>
      <Line type="bottom-line">
      </Line>
      <Line type="bottom-line">
      </Line>
      <Line type="bottom-line">
      </Line>
      <Line type="bottom-line">
      </Line>
      <Line type="bottom-line">
      </Line>
      <Line type="bottom-line">
      </Line>
      <Line type="bottom-line">
      </Line>
    </>
  )
}




