import React from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

export default function SwiperSlider({ children, header, className = "swiper-product-details" }) {
    return (
        <Swiper
            id='main'
            modules={[Navigation, Pagination]}
            navigation
            pagination={{
                "type": "fraction"
            }}
            className={className}
        >
            {children?.map((i, index) => {
                return (
                    <SwiperSlide key={index}>
                        {i}
                    </SwiperSlide>

                );
            })}
        </Swiper>
    )
}
