import React, { useState, useEffect } from 'react';
import styles from './css/SizeFinder.module.css';
import Line from '../../../main/Line';
import { GrClose } from "react-icons/gr";
import QuestionContainer from './QuestionContainer';
import StartOrEnd from './StartOrEnd';
import axios from '../../../../axios-backend';

export default function SizeFinder(props) {
    const [total, setTotal] = useState(0);
    const [list, setList] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    useEffect(() => {
        axios.get('/all-steps-by-tid', { params: { tid: props.tid, gender: props.gender } }).then(data => {
            if (data.data.status === 200 && data.data.list.length) {
                setTotal(data.data.list.length + 1);
                setList(data.data.list);
            } else {
                setTotal(1);
            }
        }).catch(r => {
            console.log(r)
        })
    }, [])
    return (
        <div className={styles.container} style={{ display: props.show ? "block" : "none" }}>
            <Line type="bottom-line" className={styles.titleContainer}>
                <GrClose style={{ cursor: "pointer" }} onClick={() => {
                    props.closeFun();
                }} />
                <div className={styles.title} style={{ display: (currentStep === 0 || currentStep === total) ? "none" : "block" }}>
                    Step {currentStep} of {total - 1}
                </div>
                <div></div>
            </Line>
            {
                (currentStep === 0 || currentStep === total) ?
                    <StartOrEnd nextStep={() => { setCurrentStep(currentStep + 1) }} preStep={() => { setCurrentStep(currentStep - 1) }} currentStep={currentStep} total={total} />
                    :
                    <QuestionContainer tid={props.tid} list={list} nextStep={() => { setCurrentStep(currentStep + 1) }} preStep={() => { setCurrentStep(currentStep - 1) }} currentStep={currentStep} total={total} />
            }
        </div>
    )
}
