import React, { useEffect, useState, useRef } from "react";
import Line from "../../main/Line";
import BackButton from "../../main/BackButton";
import axios from "../../../axios-backend";
import Button from "../../main/Button";
import { Link } from "react-router-dom";

const ViewSteps = (props) => {
  const paramsString = props.location.search.substring(1);
  const searchParams = new URLSearchParams(paramsString);
  const idRef = useRef(searchParams.get("id"));
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get("/all-steps", {
        params: { sizeFinderId: idRef.current },
      })
      .then((data) => {
        setSteps(data.data.data);
      });
  };

  const mapAndRenderSteps = () => {
    return steps.map((item, index) => {
      return (
        <Line type="bottom-line" key={index}>
          Step: {item.index} &nbsp;&nbsp; Short Name: {item.shortName}
          <Link style={{ marginLeft: "2rem" }} to={`/edit-step?id=${item.id}`}>
            <Button>Edit</Button>
          </Link>
          <Link
            style={{ marginLeft: "2rem" }}
            to={`/view-options?id=${item.id}`}
          >
            <Button>View Options</Button>
          </Link>
        </Line>
      );
    });
  };

  return (
    <>
      <BackButton />
      <Line type="bottom-line">
        <Link to={`/edit-step?sid=${idRef.current}`}>
          <Button> Add New Step </Button>
        </Link>
      </Line>
      {steps == null ? null : mapAndRenderSteps()}
    </>
  );
};

export default ViewSteps;
