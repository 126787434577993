import React from "react";
import Text from "./Text";
import styles from "./css/TermsAndConditions.module.css";
import Line from "./Line";
import { GrClose } from "react-icons/gr";

export default function TermsAndConditions(props) {
  return (
    <div
      className={
        props.type === "page" ? styles.pageContainer : styles.container
      }
      style={{ display: props.show ? "block" : "none" }}
    >
      {props.type === "page" ? null : (
        <Line type="bottom-line" className={styles.titleContainer}>
          <GrClose
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.closeFun();
            }}
          />
        </Line>
      )}
      <p className={styles.title}>
        <Text>wonwonleywon terms of use </Text>
      </p>
      <p className={styles.bold}> last updated may 30, 2022.</p>
      <br />
      <p>
        wonwonleywon products inc. (“
        <span className={styles.bold}>wonwonleywon</span>”) is a luxury brand
        that operates an online store accessible at wonwonleywon.com and its
        subdomains (collectively, the “
        <span className={styles.bold}>sites</span>”).
      </p>
      <p>
        wonwonleywon grants you access to the sites contingent on your
        acceptance of the terms, conditions and notices contained herein (the "
        <span className={styles.bold}>terms of use</span>”) (together with our
        privacy policy, store policies and any other documents referred to
        herein). for the purposes of these terms of use, "
        <span className={styles.bold}>content</span>" means, collectively, any
        text, images, graphics, software, source code, specifications, audio
        files, videos, articles, trademarks, logos and other information or
        content available through the sites including but not limited to the
        design, structure, selection, coordination, expression, "
        <span className={styles.bold}>look and feel</span>” and arrangement of
        such content.
      </p>
      <br />
      <p className={styles.bold}>1. acceptance of terms</p>
      <br />
      <p>
        please read these terms of use and wonwonleywon’s privacy policy
        carefully. by using the sites, on any computer, mobile phone, tablet,
        console, or other devices, you agree to these terms of use, and you
        consent to the collection, use and disclosure of information as
        described in these terms of use and our privacy policy (accessible at
        wonwonleywon.com/privacy-policy). if you do not agree to these terms of
        use and the terms and conditions of our privacy policy, you must exit
        the site immediately and discontinue any use of the sites and any of
        their content. your use of the sites shall be deemed to be your
        agreement to abide by each of the terms set forth below. wonwonleywon
        can change these terms at any time by posting updated terms of use on
        the sites or by sending registered users an email notice of the changes.
        if any modification is unacceptable to you, you shall cease using the
        sites.
      </p>

      <br />
      <p className={styles.bold}> 2. use of restrictions</p>
      <br />
      <p>
        the sites may be accessed and used only by individuals who can form
        legally binding contracts under applicable laws, who are 18 years of age
        or older, and who are not barred from using the sites under applicable
        laws. users under the age of 18 may only use this site and purchase
        products with the express consent of their legal guardians. Write to
      </p>
      <p>
        the sites are provided solely as a convenience to you for non-commercial
        use. you may access and use the sites only in accordance with all
        applicable laws and regulations and with these terms of use. you
        acknowledge and agree that wonwonleywon, in its sole discretion and
        without notice or any further obligation to you, may temporarily suspend
        or permanently discontinue and refuse any and all current and future,
        access to or use of the sites. you further agree that you will not: (a)
        resell for commercial purposes products purchased through use of the
        sites; (b); modify, distribute, transmit, display, perform, reproduce,
        publish, license, create derivative works of, transfer, or sell any
        content; and (c); other than for your use of the sites as expressly
        permitted in these terms of use, access or attempt to access any systems
        or servers on which the sites are hosted or modify or alter the sites in
        any way.
      </p>
      <p>
        any unauthorized use of the sites will terminate the permission or
        license granted by these terms of use, will result in you being barred
        from use of the sites, and may violate applicable law including
        copyright laws, trademark laws (including trade dress), and
        communications regulations and statutes.
      </p>

      <br />
      <p className={styles.bold}> 3. copyright</p>
      <br />
      <p>
        the content, sites, as well as the selection and arrangement thereof,
        are the sole property of wonwonleywon and/or its licensors and are
        protected by patent, copyright, trademark, and other intellectual
        property laws and may not be used except in accordance with these terms
        of use or with wonwonleywon's express written consent. other than as
        necessary for your use of the sites in accordance with these terms of
        use, wonwonleywon grants no other privileges or rights in the content to
        you, and you must keep intact all patent, copyright, trademark, and
        other proprietary notices on the content. any content owned by
        wonwonleywon's licensors may be subject to additional restrictions.
        except as expressly provided in these terms of use, sites and no content
        may be copied, reproduced, republished, uploaded, posted, publicly
        displayed, encoded, translated, transmitted, or distributed in any way
        to any other computer, server, web site, smart phone, tablet or other
        medium for publication or distribution or for any commercial enterprise,
        without wonwonleywon’s express prior written consent.
      </p>

      <br />
      <p className={styles.bold}> 4. copyright</p>
      <br />
      <p>
        all trademarks, service marks, trade names, logos and trade dress,
        whether registered or unregistered (collectively the "
        <span className={styles.bold}>marks</span>") that appear on the sites
        are proprietary to wonwonleywon or such marks' respective owners. you
        may not display or reproduce the marks other than with the prior written
        consent of wonwonleywon, and you may not remove or otherwise modify any
        trademark notices from any content. the marks are protected by
        trademark, trade dress, copyright, and various other intellectual
        property rights and unfair competition laws.
      </p>

      <br />
      <p className={styles.bold}> 5. copyright and trademark infrigments</p>
      <br />
      <p>
        wonwonleywon respects the intellectual property rights of others, and we
        ask you to do the same. wonwonleywon may, in appropriate circumstances
        and at our discretion, terminate service and/or access to the sites for
        users who infringe the intellectual property rights of others. if you
        believe that your work is the subject of copyright infringement and/or
        trademark infringement and appears on our sites, please provide
        wonwonleywon 's designated agent the following information required by
        the online copyright infringement liability limitation act of the
        digital millennium copyright act, 17 u.s.c. §512 ( "
        <span className={styles.bold}>dmca</span>"):{" "}
        <a href="mailto:support@wonwonleywon.com" style={{ color: "black" }}>
          support@wonwonleywon.com{" "}
        </a>
      </p>

      <br />
      <p className={styles.bold}> 6. authenticator</p>
      <br />
      <p>
        when you purchase a product, you will receive a stainless steel
        authenticity card that has a 16 digit number on the card, and you will
        also receive a password via email associated with that card. you may
        input that 16 digit number and password to access the authenticity
        portal. you are solely responsible for maintaining the security and
        confidentiality of your card number and password.
      </p>

      <br />
      <p className={styles.bold}> 7. store policy</p>
      <br />
      <p>
        all product sales from the sites are governed by any store policies that
        may be posted on the sites. you should refer to our store policies to
        learn more about applicable product warranties, our return policy, and
        shipping terms. by ordering and/or accepting delivery of the products,
        you agree to be bound by these store policies. the terms of sale are
        subject to change without prior notice at any time, in wonwonleywon’s
        sole discretion so you should review the terms of sale each time you
        make a purchase.
      </p>

      <br />
      <p className={styles.bold}> 8. electronic communications</p>
      <br />
      <p>
        you consent to receive electronic communications from wonwonleywon in
        the form of email sent to you at the email address listed on your
        account or by communications posted on the sites for any purpose. you
        acknowledge and agree that any electronic communication in the form of
        such email or posting on the sites and shall satisfy any legal
        requirement that such communication be in writing.
      </p>

      <br />
      <p className={styles.bold}> 9. modifications</p>
      <br />
      <p>
        any content offered or posted on the sites is subject to modification or
        discontinuation at any time and from time to time without notice or
        obligation to you. without limiting the foregoing, wonwonleywon reserves
        the right, in its sole discretion, to charge fees for the use or
        purchase of, or access to, any products, services or content offered
        through the sites, at any time and from time to time.
      </p>

      <br />

      <br />
      <p className={styles.bold}> 10. indemnifications</p>
      <br />
      <p>
        you agree to defend, indemnify and hold harmless wonwonleywon, its
        affiliates and their respective directors, officers, employees and
        agents against any losses, liabilities, claims, expenses (including
        lawyer’s fees) as a result of (i) your access to or use of the sites;
        (ii) your breach or alleged breach of these terms of use; (iii) your
        violation of any third-party right, including without limitation, any
        intellectual property right, publicity, confidentiality, property or
        privacy right; (iv) your violation of any laws, rules, regulations,
        codes, statutes, ordinances or orders of any governmental and
        quasi-governmental authorities, including, without limitation, all
        regulatory, administrative and legislative authorities; or (v) any
        misrepresentation made by you. this means that you will be responsible
        for any loss of damage we suffer because of your breach of these terms
        of use.
      </p>

      <br />
      <p className={styles.bold}> 11. links to third-party sites</p>
      <br />
      <p>
        the sites may contain links to websites and other resources operated by
        third parties other than wonwonleywon. such links are provided solely as
        a convenience to you. wonwonleywon does not control such websites, and
        is not responsible for the content, products, services, or information
        offered by any third parties. the inclusion of links to such websites on
        the sites does not imply any endorsement of any website or the content,
        products or services offered, advertised, endorsed, or promoted by any
        third party, or of any company or person. if you decide to access any
        third-party websites or acquire any third-party products or services,
        you do so entirely at your own risk, and you may be subject to the terms
        and conditions and the privacy policies imposed by such third parties.
      </p>

      <br />
      <p className={styles.bold}> 12. linking to sites</p>
      <br />
      <p>
        Yuriy Dmytrash you may link to our sites, provided you do so in a way
        that is fair and legal and does not damage our reputation or take
        advantage of it, but you must not establish a link in such a way as to
        suggest any form of association, approval, or endorsement on our part
        where none exists. you must not establish a link from any website that
        is not owned by you. the sites must not be framed on any other site, nor
        may you create a link to any part of the sites other than the home page.
        we reserve the right to withdraw linking permission without notice, for
        any or no reason. the website from which you are linking must comply in
        all respects with these terms of use.
      </p>

      <br />
      <p className={styles.bold}> 13. viruses, hacking and other offenses</p>
      <br />
      <p>
        you must not misuse our sites by knowingly introducing viruses, trojans,
        worms, logic bombs or other material which is malicious or
        technologically harmful. you must not attempt to gain unauthorized
        access to our sites, the server on which our site is stored, or any
        server, computer or database connected to our sites. you must not attack
        our sites via a denial or service attack or distributed denial or
        service attack. we will report any such breach to the relevant law
        enforcements authorities, and we will co-operate with those authorities
        by disclosing your identity to them. in the event of such a breach, your
        right to use our sites will cease immediately. we will not be liable for
        any loss or damage caused by a distributed denial-of-service attack,
        viruses or other technologically harmful material that may infect your
        computer equipment, computer programs, data, or other proprietary
        material due to your use of our sites or to your downloading of any
        material posted on it, or on any website linked to it.
      </p>

      <br />
      <p className={styles.bold}> 14. warranties and disclaimer </p>
      <br />
      <p>
        the site, submissions and any content provided via the site, including
        links, are provided on an "<span className={styles.bold}>as is</span>"
        and "as available" basis with no warranty of any kind. to the maximum
        extent permitted by law, wonwonleywon disclaims all representations,
        warranties and conditions, express or implied, with respect to the site,
        submissions, the content and any products or services, including,
        without limitation, warranties or conditions of merchantable quality,
        merchantability, durability, fitness for a particular purpose, title,
        non-infringement, freedom from errors, omissions, computer viruses or
        other malicious or unauthorized code or programs, and implied warranties
        or conditions arising from course of dealing or course of performance.
        in addition, wonwonleywon does not represent or warrant that the site,
        submissions, content or any products, services, or any other information
        accessible via the site or is secure, accurate, complete, or current or
        that any particular products or inventory will be available at our
        online store. as a condition of your use of the site, you warrant to
        wonwonleywon that you will not use the sites and/or for any purpose that
        is unlawful or prohibited by these terms of use. nothing in these terms
        of use shall affect your legal rights under applicable consumer laws.
      </p>
      <p>
        in addition, the sites may contain typographical errors or inaccuracies
        and may not be complete or current. wonwonleywon therefore reserves the
        right to correct any errors, inaccuracies, or omissions (including after
        an order has been submitted) and to change or update information at any
        time without prior notice. please note that such errors, inaccuracies,
        or omissions may relate to pricing and availability, and to the extent
        permitted by applicable law, wonwonleywon reserves the right to cancel
        or refuse to accept any order placed based on incorrect pricing or
        availability information.
      </p>

      <br />
      <p className={styles.bold}> 15. limitation of liability </p>
      <br />
      <p>
        wonwonleywon and its parent, affiliates, subsidiaries, officers,
        directors, employees, agents, suppliers, licensors and third party
        partners will not be liable for any indirect, special, punitive,
        incidental, exemplary, multiplied or consequential damages, lost profits
        or revenues or failure to realize expected savings, or any damages
        whatsoever, whether or not such party has been previously advised of the
        possibility of such damages, whether under contract, tort (including
        negligence) or any other theory, including, without limitation, arising
        out of or in connection with any loss or other damages in connection
        with any unavailability or non-performance of the site, errors,
        omissions, viruses and malicious code. you agree that any claim you may
        have arising out of or related to your relationship with wonwonleywon
        must be filed within one year after such claim arose; otherwise, your
        claim is permanently barred.
      </p>

      <br />
      <p className={styles.bold}> 16. arbitration and disclaimer </p>
      <br />
      <p>
        to the maximum extent permitted by law, you and we agree that any and
        all disputes will be resolved exclusively and finally using binding
        arbitration and will only be conducted on an individual basis and not in
        a class, consolidated, representative or similar action, except as
        provided below. there is no judge or jury in arbitration, the discovery
        and other procedures are more limited and less formal, and court review
        of an arbitration award is limited. the arbitrator must follow these
        terms of use and can award the same damages and relief as a court in an
        individual proceeding (including attorney’s fees).
      </p>
      <p>
        acceptance of these terms of use constitutes a waiver of your right to
        litigate the dispute in any court, the opportunity to be heard by a
        judge or jury, and the ability to proceed in a class, consolidated,
        representative or similar action, except as provided below.
      </p>
      <p>
        unless we mutually agree on a different location, arbitration shall
        occur in vancouver, british columbia. you waive any right to claim that
        such location is an inconvenient forum, and you agree not to sue us or
        bring arbitration in any other forum.
      </p>

      <br />
      <p className={styles.bold}> 17. Currency </p>
      <br />
      <p>
        all prices are listed in usd and all purchases will be made in usd.
        wonwonleywon will not be responsible for foreign currency conversion
        rates, fees, surcharges in connection with purchases made in usd. please
        contact us at{" "}
        <a href="mailto:support@wonwonleywon.com" style={{ color: "black" }}>
          support@wonwonleywon.com{" "}
        </a>{" "}
        for the cost in non-usd currencies.
      </p>

      <br />
      <p className={styles.bold}> 18. force majure </p>
      <br />
      <p>
        wonwonleywon shall not be responsible for failure to fulfill contractual
        obligations for reasons beyond their control.
      </p>

      <br />
      <p className={styles.bold}> 19. governing law </p>
      <br />
      <p>
        all litigation, court proceedings, arbitration proceedings, mediation
        proceedings, lawsuits, court hearings and other hearings or actions
        initiated in connection with the sites, and/or the content must and will
        be venued exclusively in vancouver, british columbia, canada. these
        terms of use and the relationship between you and wonwonleywon will be
        governed by the laws of the province of british columbia, canada, and
        the federal laws of canada applicable therein, without giving effect to
        principles of conflict of laws of any jurisdiction. the international
        sale of goods act of british columbia and the united nations convention
        on contracts for the international sale of goods will not apply in any
        way to these terms of use or to the transactions contemplated by these
        terms of use.
      </p>

      <br />
      <p className={styles.bold}> 20. miscellaneous </p>
      <br />
      <p>
        if any provision of these terms of use is held to be invalid, illegal,
        or unenforceable in any respect under any applicable law or rule in any
        jurisdiction, such invalidity, illegality or unenforceability will not
        affect the effectiveness or validity of any provision in any other
        jurisdiction, and these terms of use will be reformed, construed and
        enforced in such jurisdiction as if such provision had never been
        contained herein. any headings or titles herein are for convenience
        only.
      </p>

      <br />
      <p className={styles.bold}> 21. language </p>
      <br />
      <p>
        the official language of these terms of use exclusively shall be, and
        all communications and agreements between wonwonleywon and you and any
        proceedings in connection with these terms of use and/or your use of the
        sites, exclusively shall be made in, the english language. wonwonleywon
        and you waive any rights we may have under any other law to have these
        terms of use written in another language, and any translation of this
        terms of use will be for convenience only.
      </p>

      <br />
      <p className={styles.bold}> 22. no partnership </p>
      <br />
      <p>
        you agree that no joint venture, partnership, employment, or agency
        relationship exists between you and wonwonleywon because of these terms
        of use, our privacy policy, or any use of the sites. wonwonleywon's
        performance of these terms of use is subject to existing laws and legal
        process, and nothing contained in these terms of use, or our privacy
        policy is in derogation of wonwonleywon's right to comply with law
        enforcement requests or requirements relating to your use of the sites
        or information provided to or gathered by wonwonleywon with respect to
        such use.
      </p>

      <br />
      <p className={styles.bold}> 23. entire agreement </p>
      <br />
      <p>
        these terms of use, cookie policy and our privacy policy constitute the
        entire agreement between you and wonwonleywon with respect to the sites,
        and they supersede all prior or contemporaneous communications and
        proposals, whether electronic, oral, or written, between you and
        wonwonleywon with respect to the sites.
      </p>

      <br />
      <p className={styles.bold}> 24. questions or concerns </p>
      <br />
      <p>
        if you have any concerns about material which appears on this website,
        please contact us by emailing us at:{" "}
        <a href="mailto:support@wonwonleywon.com" style={{ color: "black" }}>
          support@wonwonleywon.com{" "}
        </a>
      </p>

      <br />
      <p className={styles.bold}>wonwonleywon store policies </p>

      <br />
      <p className={styles.bold}> 1. duties </p>
      <br />
      <p>
        there are no duties payable if we ship our products to canada or the
        usa. if we ship our products outside of canada or the usa, you may be
        charged duties, customs charges, and other import fees. the shipping
        provider will collect these charges at the time of delivery. we do not
        know the amount of these costs, but you will know the specific amount at
        the time of delivery. you may contact your local customs office to ask
        what these additional costs could be prior to purchase. any duties,
        customs charges, and other import fees are your responsibility to pay.
      </p>

      <br />
      <p className={styles.bold}> 2. taxes </p>
      <br />
      <p>
        taxes will be calculated at checkout if we ship our products to canada
        or the usa. if we ship our products outside of canada or the usa, you
        may be charged taxes. the shipping provider will collect these taxes at
        the time of delivery. we do not know the tax amount, but you will know
        the specific amount at the time of delivery. you may contact your local
        customs office to ask what these additional costs could be prior to
        purchase. any taxes payable will be your responsibility.
      </p>

      <br />
      <p className={styles.bold}> 3. international orders </p>
      <br />
      <p>
        customers who access and use the wonwonleywon.com website from outside
        canada are responsible for complying with their local laws and
        regulations. when you place an order and select a shipping address
        outside of canada you or your designated consignee are the importer of
        record and as such are authorizing wonwonleywon to import the goods to
        your destination country on your behalf. furthermore, you agree that
        wonwonleywon may delegate the obligation to import the goods on your
        behalf to a subcontractor (e.g. customs broker). ultimately you or your
        designated consignee as importer of record are responsible to pay the
        applicable duties, taxes, customs charges and any other import fees to
        your local customs authorities. you may contact your local customs
        office to ask what these additional costs could be prior to purchase.
      </p>

      <br />
      <p className={styles.bold}> 4. shipping methods </p>
      <br />
      <p>
        we deliver our products all over the world via dhl, fedex, ups, or
        canada post. please make sure to fill out your correct shipping address
        when checking out, as once products are shipped, a change of delivery
        address is not possible.
      </p>
      <p className={styles.bold}> 5. shipping cost </p>
      <br />
      <p>
        we do not provide free shipping. to know the exact shipping cost, you
        must proceed to checkout and provide your shipping address.
      </p>

      <br />
      <p className={styles.bold}> 6. delivery time </p>
      <br />
      <p>
        delivery time for all products will take approximately 3-7 business days
        if shipping to canada or the usa. 5-10 business days if shipping outside
        of canada or the usa. if you need rush shipping, email us at
        support@wonwonleywon.com, and we can potentially get you our products in
        approximately 2 business days.
      </p>

      <br />
      <p className={styles.bold}> 7. returns </p>
      <br />
      <p>all sales final. no returns. no refunds. no exchanges.</p>

      <br />
      <p className={styles.bold}> 8. prices and currencies </p>
      <br />
      <p>
        all prices are only displayed in usd and will be charged in usd on
        whichever payment method you use.
      </p>

      <br />
      <p className={styles.bold}> 9. change country and currecy </p>
      <br />
      <p>
        you cannot change the country to see prices in different currencies, and
        all prices are only displayed and billed in usd. if you want to know the
        price of our products in a different currency, email us at{" "}
        <a href="mailto:support@wonwonleywon.com" style={{ color: "black" }}>
          support@wonwonleywon.com
        </a>
      </p>

      <br />
      <p className={styles.bold}> 10. payment methods </p>
      <br />
      <p>
        you can purchase our products using any of the following debit or credit
        cards: visa, mastercard, american express, china unionpay, japan credit
        bureau, discover, diners club, maestro. you can also purchase our
        products using paypal.
      </p>

      <br />
      <p className={styles.bold}> 11. international transaction fees </p>
      <br />
      <p>
        because all of our prices are in usd, if your bank does not normally
        transact in usd, you may be charged an additional fee from your bank. we
        do not know the amount. however, we assume it should be minimal.
      </p>

      <br />
      <p className={styles.bold}> 12. payment </p>
      <br />
      <p>
        we use stripe and paypal as our third-party payment processors thus we
        do not collect any banking or financial information.
      </p>

      <br />
      <p className={styles.bold}> 13. currency </p>
      <br />
      <p>
        all prices are listed in usd and all purchases will be made in usd.
        wonwonleywon will not be responsible for foreign currency conversion
        rates, fees, surcharges in connection with purchases made in usd. please
        contact us at{" "}
        <a href="mailto:support@wonwonleywon.com" style={{ color: "black" }}>
          support@wonwonleywon.com{" "}
        </a>{" "}
        for the cost in non-usd currencies.
      </p>

      <br />
      <p className={styles.bold}> 14. authenticity portal </p>
      <br />
      <p>
        to ensure people are not creating or selling fake wonwonleywon products
        we have developed an authenticity portal displayed on the homepage of
        our website. to prove you own a real wonwonleywon and not a fake product
        you must be able to login to the authenticity portal. if you cannot log
        in you own a fake product. if you can log in, you will see photographs
        of the product with the matching authenticity card. you must own that
        product with the matching authenticity card to ensure you own a real
        wonwonleywon. all data collected will not be shared with any third party
        and will only be used to track which customer currently owns a real
        wonwonleywon product with their email and password linked to that
        specific product.
      </p>

      <br />
      <p className={styles.bold}> 15. sizes </p>
      <br />
      <p>
        some products come in one size as shown in the product dimensions page
        on the website. some products are sold in multiple different sizes. the
        products sold in multiple different sizes may have a size finder and
        size guide to help the customer determine which size to order. for the
        size finder we will ask the customer for their email address to analyze
        their answers. all data collected will not be shared with any third
        party and will only be used to provide the customer with an answer on
        which product size to purchase.
      </p>

      <br />
      <p className={styles.bold}> 16. membership </p>
      <br />
      <p>
        to know when we release a new collection or new products within a
        collection, a person can become a member by providing wonwonleywon with
        their email address. all emails collected will not be shared with any
        third party.
      </p>
    </div>
  );
}
