import React, { Component } from 'react';
import axios from '../../axios-backend';
import Line from '../main/Line';
import Button from '../main/Button';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';

const md5 = require('md5');

class Admin extends Component {
  state = {
    username: '',
    pwd: ''
  }

  handleSubmit = e => {
    e.preventDefault();
    const user = md5(this.state.username);
    const pass = md5(this.state.pwd);
    axios.post('/admin', {
      user, pass
    }).then(data => {
      if (data.data.status === "success") {
        this.props.onUserLogin(data.data.userName);
        sessionStorage.setItem("user", data.data.userName);
        this.props.history.push('/admin-home')
      } else {
        alert('get out of here!')
      }
    });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} style={{ height: "100%" }}>
        <Line type="bottom-line" style={{ justifyContent: "center" }}>Wonwonleywon Admin Panel</Line>
        <Line type="bottom-line" style={{ placeContent: "center" }}>
          <label for="username" style={{ marginRight: "1rem" }}>username:</label>
          <input type="text" name="username" value={this.state.username} onChange={e => this.setState({ username: e.target.value })} /> <br />
        </Line>
        <Line type="bottom-line" style={{ placeContent: "center" }}>
          <label for="password" style={{ marginRight: "1rem" }}>password:</label>
          <input type="text" name="password" value={this.state.pwd} onChange={e => this.setState({ pwd: e.target.value })} />
        </Line>
        <Line type="bottom-line" style={{ placeContent: "center" }}>
          <Button type="submit" >
            Login
          </Button>
        </Line>
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
      </form>
    );
  }

}

const mapDispatchToProps = dispatch => {
  return {
    onUserLogin: (username) => dispatch({ type: actionTypes.USERLOGIN, username })
  };
}
export default connect(null, mapDispatchToProps)(Admin);
