import React from 'react';
import Line from "./Line";
import styles from './css/PolicyLine.module.css';


export default function PolicyLine(props) {
    return (
        <Line type={props.type ? props.type : "bottom-line"} className={styles.container}>
            <div>
                by clicking the {props.content ? props.content : "click here "} button you agree to our&nbsp;
                <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={props.clickTerms}>terms of use</span>&nbsp;and understand our&nbsp;<span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={props.clickPolicy}>privacy policy</span>
            </div>
        </Line>
    )
}

