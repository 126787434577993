import React, { Component } from "react";
import Line from "../../main/Line";
import BackButton from "../../main/BackButton";
import { NavLink } from "react-router-dom";
import classes from "../css/Home.module.css";

class Mission extends Component {
  render() {
    return (
      <>
        <BackButton />
        <Line type="bottom-line">
          <NavLink to="/world/mission/written" className={classes.navLink}>
            written statement
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/world/mission/video" className={classes.navLink}>
            video
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/world/mission/music" className={classes.navLink}>
            music video
          </NavLink>
        </Line>
        <Line type="bottom-line">
          <NavLink to="/world/mission/lyrics" className={classes.navLink}>
            lyrics to song - bethebestyou
          </NavLink>
        </Line>
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
        <Line type="bottom-line" />
      </>
    );
  }
}

export default Mission;
