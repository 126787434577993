import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NavLink } from "react-router-dom";
import axios from '../../../axios-backend';
import BackButton from '../../main/BackButton';
import Line from "../../main/Line";
import SwiperSlider from '../../main/SwiperSlider';
import classes from "../css/Home.module.css";
export default function ProductDimensions(props) {
    const { type } = props.location.state;

    const [images, setImages] = useState([]);
    useEffect(() => {
        axios.get(`/get-imgs-by-pid`, {
            params: {
                id: type,
                type: 3
            }
        }).then(data => {
            setImages(data.data.imgs)
        });
    }, []);

    return <>
        <BackButton />
        <div className="singleContainer">

            <SwiperSlider>
                {images.map((i, index) => {
                    return (
                        <LazyLoadImage
                            key={index}
                            src={`${process.env.REACT_APP_Url}${i.url}`}
                            alt={index}
                            effect="blur"
                            style={{
                                minHeight: "300px",
                                maxHeight: "500px",
                                height: "100%",
                                minWidth: "300px",
                                maxWidth: "500px",
                                width: "100%",
                                padding: "10px 0",
                                objectFit: "contain",
                              }}
                        />
                    );
                })}
            </SwiperSlider>
        </div>
    </>;
}
